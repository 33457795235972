import styled, { css } from 'styled-components';
import { Bell } from 'styled-icons/evaicons-solid';

export const BellCustomNotify = styled(Bell)`
  transform: rotate(37deg);
  color: #cacccf;
`;

export const ButtonBell = styled.button`
  width: 3rem;
  height: 4rem;
  max-width: 30px;
  position: relative;
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;
  height: 5rem;
  outline: none;
`;

type NumberNotificationProps = {
  exceeded?: boolean;
};

export const NumberNotification = styled.span<NumberNotificationProps>`
  ${({ theme, exceeded }) => css`
    background: ${theme.colors.primary};
    position: absolute;
    width: 3rem;
    top: 0;
    left: 19px;
    border: 4px solid ${theme.colors.white};
    border-radius: 100%;
    height: 3rem;
    line-height: 22px;
    color: ${theme.colors.white};
    font-weight: 700;

    ${exceeded &&
    css`
      padding-left: 0.2rem;
    `}
  `}
`;
