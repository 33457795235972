import { getNameByPixeonId } from '../../hooks/auth';
import { Consent } from '../../models/consent';

import * as S from './styles';

export type TermProps = {
  item: Consent;
};

const TableItemOrganizationDisplay = ({
  item: { pixeonId },
}: TermProps): JSX.Element => {
  return <S.Row>{getNameByPixeonId(pixeonId) || pixeonId}</S.Row>;
};

export default TableItemOrganizationDisplay;
