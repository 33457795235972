import { useEffect, useRef } from 'react';

const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      effect();
    }
    didMount.current = true;
  }, [effect, deps]);
};

export default useUpdateEffect;
