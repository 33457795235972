import * as Yup from 'yup';

import { Validations } from '../../utils/validations';

const messageDate = 'Data de nascimento inválida';

export const schema = Yup.object().shape({
  q: Yup.string().required('Valor de busca não informado'),
  birthDate: Yup.string().test(
    'birthDate',
    messageDate,
    value => Validations.date(messageDate, value) === '',
  ),
});
