import { SelectTypingItems } from '.';
import { ItemWithSelectedState } from '../../hooks/use-selected-items/types';
import { regularString } from '../../utils/string-utils';

import * as S from './styles';

type SelectCheckboxProps = {
  item: ItemWithSelectedState<SelectTypingItems>;
  htmlFor: string;
  handlerClickItem: (item: SelectTypingItems) => void;
};

const SelectCheckbox = ({
  item,
  htmlFor,
  handlerClickItem,
}: SelectCheckboxProps): JSX.Element => {
  const id = regularString(`${htmlFor}_${item.text}`);

  return (
    <S.SelectInputCheckboxWrapper>
      <S.SelectInputCheckbox
        type="checkbox"
        id={id}
        onChange={() => handlerClickItem(item)}
        checked={item.isSelected}
        role="option"
      />
      <S.SelectLabelCheckbox htmlFor={id}>{item.text}</S.SelectLabelCheckbox>
    </S.SelectInputCheckboxWrapper>
  );
};

export default SelectCheckbox;
