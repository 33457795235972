import { createContext, Dispatch, SetStateAction, useState } from 'react';

import usePagination from '../../hooks/use-pagination';
import { UsePagination } from '../../hooks/use-pagination/types';
import { ReportRequest } from '../../models/report-request';
import AlertEmittedReport from './AlertEmittedReport';
import FormEmittedReports, { FormData } from './FormEmittedReports';
import TableEmittedReports from './TableEmittedReports';

import * as S from './styles';

type EmittedReportsContextType = {
  alertMessage?: string;
  setAlertMessage?: Dispatch<SetStateAction<string | undefined>>;
} & Partial<UsePagination<ReportRequest, FormData>>;

export const EmittedReportsContext = createContext<EmittedReportsContextType>(
  {},
);

const EmittedReports = (): JSX.Element => {
  const [alertDisplay, setError] = useState<string | undefined>(undefined);
  const pagination = usePagination<ReportRequest, FormData>({
    endpoint: '/report-requests',
    autoLoading: {},
    serializationParams: true,
  });

  return (
    <EmittedReportsContext.Provider
      value={{
        ...pagination,
        alertMessage: alertDisplay,
        setAlertMessage: setError,
      }}
    >
      <S.H1>Pesquisar relatórios emitidos</S.H1>

      <FormEmittedReports />

      <AlertEmittedReport />

      <TableEmittedReports />
    </EmittedReportsContext.Provider>
  );
};

export default EmittedReports;
