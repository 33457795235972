import { useContext, useEffect } from 'react';

import { EmittedReportsContext } from '.';

import * as S from './styles';

export const errorMessagesEmittedReport = {
  notFound: 'Relatório não encontrado.',
  failure: 'Falha ao baixar o relatório.',
};

const AlertEmittedReport = (): JSX.Element => {
  const { alertMessage, setAlertMessage } = useContext(EmittedReportsContext);

  const handlerClose = (): void => {
    setAlertMessage && setAlertMessage(undefined);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      setAlertMessage && setAlertMessage(undefined);
    }, 10000);

    return () => {
      clearTimeout(time);
    };
  }, [setAlertMessage]);

  return (
    <S.AlertEmittedReport severity="error" closeable onClose={handlerClose}>
      {alertMessage}
    </S.AlertEmittedReport>
  );
};

export default AlertEmittedReport;
