import download from 'downloadjs';

import { getPixeonId } from '../../../hooks/auth';
import paramsSerializer from '../../../utils/params-serializer';
import api from '../../api';

const downloadFullReport = async (id: string | number): Promise<void> => {
  const { data, headers } = await api.get(
    `/report-requests/${String(id)}/report`,
    {
      responseType: 'blob',
      params: {
        pixeonId: getPixeonId(),
      },
      paramsSerializer,
    },
  );

  !!data && download(data, `relatorio_completo.pdf`, headers['content-type']);
};

const FullReport = {
  download: downloadFullReport,
};

export default FullReport;
