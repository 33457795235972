import { HTMLAttributes, useState } from 'react';

import useOutsideClick from '../../hooks/use-outside-click';

import * as S from './styles';

export type DropdownProps = {
  rootElement: React.ReactNode;
  useOverlay?: boolean;
  showShadowBackground?: boolean;
  backgroundColor?: string;
  keepOpenOnRootClick?: boolean;
  children: React.ReactNode;
  onRootClick?: (isOpen: boolean) => void;
} & HTMLAttributes<HTMLDivElement>;

const Dropdown = ({
  rootElement,
  backgroundColor,
  useOverlay = true,
  showShadowBackground = true,
  children,
  keepOpenOnRootClick = false,
  onRootClick,
  ...props
}: DropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const { ref } = useOutsideClick(() => {
    !useOverlay && setIsOpen(false);
  });

  return (
    <S.Wrapper ref={ref} isOpen={isOpen} {...props}>
      <S.RootWrapper
        onClick={() => {
          keepOpenOnRootClick ? !isOpen && setIsOpen(true) : setIsOpen(!isOpen);
          !!onRootClick && onRootClick(isOpen);
        }}
      >
        {rootElement}
      </S.RootWrapper>

      <S.Content aria-hidden={!isOpen} backgroundColor={backgroundColor}>
        {children}
      </S.Content>
      {useOverlay && (
        <S.Overlay
          aria-hidden={!isOpen}
          showShadowBackground={showShadowBackground}
          onClick={() => setIsOpen(!isOpen)}
        />
      )}
    </S.Wrapper>
  );
};

export default Dropdown;
