import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';

import { MAX_DATE, MIN_DATE } from '../../utils/date_utils';
import { capitalizeFirstChar } from '../../utils/string-utils';

export type DateFormat = 'DD/MM/yyyy';
export type Locale = 'pt-br' | 'en';

export const formatDate = (
  date: Moment,
  format: string,
  locale: Locale,
): string => capitalizeFirstChar(date.locale(locale).format(format));

export const dateIsValid = (date: Moment): boolean =>
  date.isSameOrAfter(MIN_DATE, 'month') &&
  date.isSameOrBefore(MAX_DATE, 'month');

export const buildDate = (value: string, format: string): Moment | null => {
  const withoutUnderline = value.replace(/_/g, '');
  if (withoutUnderline.length !== format.length) {
    return null;
  }

  const date = moment(value, format);
  return dateIsValid(date) ? date : null;
};

export const toMoment = (initialValue?: Date): Moment =>
  initialValue ? moment(initialValue) : moment();

export const toDateValue = (
  dateFormat: DateFormat,
  initialValue?: Date,
): string => (initialValue ? moment(initialValue).format(dateFormat) : '');

export const validateInitialValue = (initialValue?: Date): void => {
  if (!!initialValue && !initialValue.getDate()) {
    throw new Error('The initial value property is invalid');
  }

  if (
    !!initialValue &&
    (initialValue.getFullYear() > MAX_DATE.year() ||
      initialValue.getFullYear() < MIN_DATE.year())
  ) {
    throw new Error(
      `The year of initial value property must be greater or equals to ${MIN_DATE.year()}` +
        ` and less than or equals to ${MAX_DATE.year()}`,
    );
  }
};
