import { ChangeEvent, useMemo, useState } from 'react';

import moment from 'moment';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import Form from '../../components/Form';
import Radio from '../../components/Radio';
import { MinTableItem } from '../../components/Table';
import { getNameByPixeonId } from '../../hooks/auth';
import Dialog from './Dialog';
import { Period, validateForm } from './utils';

import * as S from './styles';

type FullReportProps = {
  location: RouteComponentProps['location'];
};

const FullReport = ({
  location: { state: patients },
}: FullReportProps): JSX.Element => {
  const history = useHistory();
  const [to, setTo] = useState<Date>();
  const [from, setFrom] = useState<Date>();
  const [period, setPeriod] = useState<Period>('allRecords');

  const [showDialog, setShowDialog] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleSubmit = async (): Promise<void> => {
    const validationErrors = await validateForm({ from, to, period });
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setShowDialog(true);
    }
  };

  const changePeriod = (e: ChangeEvent<HTMLInputElement>): void => {
    setErrors({});
    setPeriod(e.target.id as Period);
  };

  const cols = useMemo(
    () => [
      { title: 'ID', fieldName: 'originalId' },
      { title: 'Nome', fieldName: 'name' },
      { title: 'Nome Social', fieldName: 'preferredName' },
      {
        title: 'Data de Nascimento',
        fieldName: 'birthDate',
        customRender: ({ birthDate }: MinTableItem) => {
          const date = moment(new Date(birthDate));

          return date.isValid() && date.format('DD/MM/yyyy');
        },
      },
      { title: 'Documento', fieldName: 'document' },
      { title: 'Sistema', fieldName: 'application' },
      { title: 'Nome da Mãe', fieldName: 'motherName' },
      {
        title: 'Nome da Instituição',
        fieldName: 'pixeonId',
        customRender: ({ pixeonId }: MinTableItem) =>
          getNameByPixeonId(pixeonId),
      },
    ],
    [],
  );

  if (!patients?.length) {
    history.push('/emit-report');
    return <></>;
  }

  return (
    <S.Wrapper>
      <h1>Emitir Relatório de Rastreabilidade</h1>

      <S.SelectedPatients>
        <h3>Pacientes selecionados</h3>
        <S.Table cols={cols} items={patients} />
      </S.SelectedPatients>

      <Form onSubmit={handleSubmit}>
        <S.ChoosePeriod>
          <strong>Escolha o período:</strong>

          <S.Radios>
            <Radio
              name="period"
              labelFor="allRecords"
              label="Todos os registros"
              onChange={changePeriod}
              defaultChecked
            />
            <Radio
              name="period"
              labelFor="oneYearAgo"
              label="1 ano atrás"
              onChange={changePeriod}
            />
            <Radio
              name="period"
              labelFor="sixMonthsAgo"
              label="6 meses atrás"
              onChange={changePeriod}
            />
            <Radio
              name="period"
              labelFor="oneMonthAgo"
              label="1 mês atrás"
              onChange={changePeriod}
            />
            <Radio
              name="period"
              labelFor="customPeriod"
              label="Período"
              onChange={changePeriod}
            />
          </S.Radios>

          <S.CustomPeriod>
            <DatePicker
              labelFor="from"
              label="De:"
              disabled={period !== 'customPeriod'}
              onChange={date => setFrom(date)}
              error={errors.from}
            />
            <DatePicker
              labelFor="to"
              label="Até:"
              disabled={period !== 'customPeriod'}
              onChange={date => setTo(date)}
              error={errors.to}
            />
          </S.CustomPeriod>
        </S.ChoosePeriod>
        <Button type="submit" size="large">
          Solicitar Relatório
        </Button>
      </Form>

      <Dialog
        data={{ period, from, to }}
        patients={patients}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </S.Wrapper>
  );
};

export default FullReport;
