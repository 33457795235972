import { useRef, useState } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import moment from 'moment';
import { FilterAlt } from 'styled-icons/boxicons-regular';

import { SearchPatientConsent } from '.';
import DatePicker from '../../components/DatePicker';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import { Loading, Pageable } from '../../hooks/use-pagination/types';
import { PatientConsent } from '../../models/patient-consent';
import { schema } from './schema';

import * as S from './styles';

type PatientConsentFormProps = {
  page: Pageable<PatientConsent, SearchPatientConsent>;
  loading: Loading;
};

const PatientConsentForm = ({
  page,
  loading,
}: PatientConsentFormProps): JSX.Element => {
  const [showFilters, setShowFilters] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const handleSubmit: SubmitHandler<SearchPatientConsent> = async filters => {
    const data = filters.data
      ? moment(filters.data, 'DD/MM/yyyy').format('yyyy-MM-DD')
      : undefined;

    page.fetch({ ...filters, data });
  };

  return (
    <Form
      formRef={formRef}
      onSubmit={handleSubmit}
      schema={schema}
      cleanErrors={false}
    >
      <S.SearchGroup>
        <TextField placeholder="Nome" labelFor="nome" maxLength={80} />
        <TextField
          placeholder="Documento"
          labelFor="documento"
          maxLength={80}
        />
        <S.Button
          size="large"
          icon={<FilterAlt size={50} />}
          outlined
          onClick={() => setShowFilters(prev => !prev)}
          aria-label="filtros"
        />
        <S.ButtonPatientConsent
          size="large"
          type="submit"
          aria-label="buscar"
          disabled={!!loading}
          loading={loading === 'submit'}
        >
          Buscar
        </S.ButtonPatientConsent>
      </S.SearchGroup>
      <S.FilterGroup show={showFilters}>
        <DatePicker label="Data" labelFor="data" />
      </S.FilterGroup>
    </Form>
  );
};

export default PatientConsentForm;
