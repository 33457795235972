import styled, { css } from 'styled-components';

import Alert from '../../components/Alert';
import DefaultButton from '../../components/Button';
import Table from '../../components/Table';
import Upload from '../../components/Upload';

import { Form } from '../../components/Form/styles';

export const ButtonPatientConsent = styled(DefaultButton)`
  min-width: 10rem;
`;

export const ButtonDownload = styled(DefaultButton)`
  min-width: 13.1rem;
`;

export const Button = styled(DefaultButton)`
  ${({ theme }) => css`
    padding: ${`${theme.spacings.xxsmall} ${theme.spacings.small}`};

    & svg {
      width: 1.65rem;
    }
  `}
`;

export const SearchGroup = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export type FilterGroupProps = {
  show?: boolean;
};

export const FilterGroup = styled.div<FilterGroupProps>`
  ${({ show }) => css`
    display: ${show ? 'flex' : 'none'};
    gap: 1.5rem;
    margin: 1.5rem 0rem;
  `}
`;

export const EmitGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NoDataMessage = styled.p`
  ${({ theme }) => css`
    line-height: ${theme.spacings.small};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${SearchGroup}, ${EmitGroup} {
      margin-top: ${theme.spacings.medium};
    }

    ${Form} {
      margin-bottom: ${theme.spacings.large};
    }
  `}
`;

export const PatientConsentAlert = styled(Alert)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const BodyModalConsent = styled.div`
  padding-top: 2rem;
  gap: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const ActionPatientConsent = styled.div`
  gap: 1rem;
  display: flex;
`;

export const TablePatientConsent = styled(Table)`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};

    td,
    th {
      padding: 1.4rem 1rem;
    }
  `}
`;

export const UploadPatientConsent = styled(Upload)`
  width: 10rem;
`;
