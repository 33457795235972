import Button from '../../../../components/Button';
import Spinner from '../../../../components/Spinner';
import { Response } from '../../../../models/response';

import * as S from '../styles';

export type ConfirmationProps = {
  state: { loading: boolean; response?: Response };
  toggle: () => void;
  onConfirm: () => Promise<void>;
};

const Confirmation = ({
  state: { loading, response },
  toggle,
  onConfirm,
}: ConfirmationProps): JSX.Element =>
  response === undefined ? (
    <S.Wrapper>
      <S.Overlay visible={loading} role="status" aria-label="Loading">
        <Spinner />
      </S.Overlay>

      <S.Content visible={!loading}>
        <h1>Relatório Completo</h1>

        <S.Alert severity="warning">
          Antes de prosseguir, certifique-se de que o período esteja correto e
          que os pacientes selecionados se referem à mesma pessoa.
        </S.Alert>

        <S.Alert>A geração deste relatório pode demorar até 7 dias.</S.Alert>

        <S.Footer>
          <Button size="large" onClick={onConfirm}>
            CONFIRMAR
          </Button>
          <Button size="large" color="error" onClick={toggle}>
            CANCELAR
          </Button>
        </S.Footer>
      </S.Content>
    </S.Wrapper>
  ) : (
    <></>
  );

export default Confirmation;
