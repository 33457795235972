import Spinner from '../Spinner';

import * as S from './styles';

export type ColumnProps<T extends MinTableItem> = {
  title: string;
  fieldName: string;
  width?: string;
  customRender?: (item: T) => React.ReactNode;
};
export interface MinTableItem {
  id: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type TableProps<T extends MinTableItem> = {
  cols: ColumnProps<T>[];
  items: T[];
  loading?: boolean;
};

const Table = <T extends MinTableItem>({
  cols,
  items,
  loading = false,
  ...props
}: TableProps<T>): JSX.Element => {
  const renderRow = (item: T): JSX.Element => (
    <tr role="row" key={item.id}>
      {cols.map(col => (
        <td role="gridcell" key={col.fieldName}>
          {col.customRender ? col.customRender(item) : item[col.fieldName]}
        </td>
      ))}
    </tr>
  );

  return (
    <S.Wrapper>
      <S.Overlay show={loading} role="status" aria-label="Loading">
        <Spinner />
      </S.Overlay>

      <S.Table role="grid" {...props}>
        <thead>
          <tr>
            {cols.map(col => (
              <S.Th key={col.fieldName} role="columnheader" width={col.width}>
                {col.title}
              </S.Th>
            ))}
          </tr>
        </thead>
        <tbody>{items.map(item => renderRow(item))}</tbody>
      </S.Table>
    </S.Wrapper>
  );
};

export default Table;
