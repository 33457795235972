import { useEffect, useRef, useState } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { AxiosResponse } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import CkEditor from '../../../components/CkEditor';
import Select, { SelectTypingItems } from '../../../components/Select';
import TextField from '../../../components/TextField';
import { getPixeonId, UserOrganization } from '../../../hooks/auth';
import { Consent } from '../../../models/consent';
import { apiHubLGPD, handleErrorApiHub } from '../../../services/api';
import { ConsentFormRoute } from '../index';
import ModalConfirmOut from './ModalConfirmOut';
import ModalConfirmRemove from './ModalConfirmRemove';

import * as S from './styles';

export type SearchConsent = {
  id?: string;
  nome: string;
  texto: string;
  exigeAssinatura: boolean;
  tipoTermo?: string;
};

const actionTranslate = {
  new: 'Adicionar',
  view: 'Visualizar',
  edit: 'Editar',
  newScienceTerm: 'Adicionar',
};

const ConsentFormEdit = (): JSX.Element => {
  const { state: stateLocation } = useLocation<ConsentFormRoute>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const isNewTermOperation = ['new', 'newScienceTerm'].includes(
    stateLocation?.operation,
  );

  const [loading, setLoading] = useState(false);
  const [hasPatientConsent, setHasPatientConsent] = useState(true);
  const [typeModal, setTypeModal] = useState<'save' | 'back' | undefined>();
  const [contentMessage, setMessage] = useState<string | undefined>();

  useEffect(() => {
    if (stateLocation?.data?.id) {
      apiHubLGPD
        .get<boolean>(
          `/TermoConsentimento/${stateLocation?.data?.id}/possuiConsentimento`,
        )
        .then(response => {
          setHasPatientConsent(response.data);
        })
        .catch(handleError => setMessage(handleErrorApiHub(handleError)));
    }
  }, [stateLocation?.data?.id]);

  const schema = Yup.object().shape({
    nome: Yup.string().required('Nome não informado'),
    texto: Yup.string().required('Conteúdo não informado'),
    pixeonId: isNewTermOperation
      ? Yup.array().min(1, 'Selecione ao menos uma instituição').required()
      : Yup.array(),
  });

  const onCloseAlert = (): void => {
    setMessage(undefined);
  };

  const getPixeonIdFromContext = (): string[] => {
    if (isNewTermOperation) {
      const data = formRef.current?.getData();
      return data?.pixeonId;
    }

    return getPixeonId();
  };

  const methodCall = async (
    form: SearchConsent,
  ): Promise<AxiosResponse<Consent>> => {
    const method = stateLocation?.data?.id ? 'put' : 'post';
    const url = `/TermoConsentimento${
      stateLocation?.data?.id ? `/${stateLocation.data.id}` : ''
    }`;

    return apiHubLGPD[method](url, {
      ...form,
      pixeonId: getPixeonIdFromContext(),
    });
  };

  const handleSubmit: SubmitHandler<SearchConsent> = async form => {
    try {
      setLoading(true);
      await methodCall(form);

      setTypeModal('save');
    } catch (err) {
      setMessage(handleErrorApiHub(err));
    } finally {
      setLoading(false);
    }
  };

  const handleClickBack = (): void => {
    if (stateLocation?.operation !== 'view') {
      setTypeModal('back');
    } else {
      history.push('/terms-form');
    }
  };

  const requestSubscription =
    stateLocation?.data?.solicitaAssinatura === undefined
      ? true
      : stateLocation?.data?.solicitaAssinatura;

  const termTypeOrOperationResult = (): string => {
    if (stateLocation.data?.tipoTermo !== undefined)
      return stateLocation.data?.tipoTermo;

    if (stateLocation?.operation === 'newScienceTerm') return 'science';

    return 'consent';
  };

  const termLabel = (t: string): string =>
    t.toLowerCase() === 'science' ? 'Ciência' : 'Consentimento';

  const fillOrganizations = (): SelectTypingItems[] => {
    const organizationFromLocalStorage = localStorage.getItem(
      '@PixeonLGPD:organization',
    );

    if (organizationFromLocalStorage) {
      const institutions: UserOrganization[] = JSON.parse(
        organizationFromLocalStorage,
      );

      return institutions?.map(o => {
        return {
          value: o.pixeonId,
          text: o.organizationName,
        };
      });
    }

    return [];
  };

  const organizations = fillOrganizations();

  const organizationDefault =
    organizations.length === 1
      ? String(organizations[0].value).split(' ')
      : undefined;

  return (
    <>
      <S.H1>
        {stateLocation?.operation && actionTranslate[stateLocation.operation]}{' '}
        Termo de {termLabel(termTypeOrOperationResult())}
      </S.H1>

      <S.ConsentForm formRef={formRef} onSubmit={handleSubmit} schema={schema}>
        <TextField
          label="Nome do Termo"
          labelFor="nome"
          initialValue={stateLocation?.data?.nome}
          disabled={stateLocation?.operation === 'view'}
        />

        <CkEditor
          labelFor="texto"
          label="Conteúdo do termo"
          initialData={stateLocation?.data?.texto}
          placeholder="Conteúdo do termo"
          disabled={stateLocation?.operation === 'view'}
        />

        <Checkbox
          label="Exige Assinatura"
          labelFor="exigeAssinatura"
          isChecked={requestSubscription}
          disabled={['view', 'new'].includes(stateLocation?.operation)}
        />

        {isNewTermOperation && (
          <S.HalfScreen>
            <Select
              label="Selecione uma ou mais instituições para replicar o termo"
              labelFor="pixeonId"
              initialValues={organizations}
              multiple
              checkUncheckAll
              defaultValue={organizationDefault}
            />
          </S.HalfScreen>
        )}

        <S.ConsentAlert closeable severity="error" onClose={onCloseAlert}>
          {contentMessage}
        </S.ConsentAlert>

        <TextField
          labelFor="tipoTermo"
          initialValue={termTypeOrOperationResult()}
          disabled
          visibled={false}
        />

        <S.ContentButtonActions>
          {stateLocation?.operation !== 'view' && (
            <S.ButtonSaveConsent
              loading={loading}
              type="submit"
              color="secondary"
            >
              Salvar
            </S.ButtonSaveConsent>
          )}

          <Button onClick={handleClickBack}>Voltar</Button>

          <ModalConfirmOut
            setTypeModal={setTypeModal}
            typeModal={typeModal}
            operation={stateLocation?.operation}
          />
          {stateLocation?.data && (
            <ModalConfirmRemove
              item={stateLocation.data}
              setMessage={setMessage}
              visible={!hasPatientConsent && stateLocation.data?.ultimaVersao}
              operation={stateLocation?.operation}
              termType={termLabel(termTypeOrOperationResult())}
            />
          )}
        </S.ContentButtonActions>
      </S.ConsentForm>
    </>
  );
};

export default ConsentFormEdit;
