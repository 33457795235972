import { rgba } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';
import { NavigateBefore, NavigateNext } from 'styled-icons/material';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    user-select: none;
    font-size: ${theme.font.paragraphs.sizes.large};
  `}
`;

export const Span = styled.span`
  ${({ theme }) => css`
    margin-left: 0.5rem;
    font-size: ${theme.font.paragraphs.sizes.medium};
    color: ${theme.colors.black};
  `}
`;

export const Navigation = styled.nav`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${theme.spacings.xxsmall};

    > * {
      margin-top: ${theme.spacings.xxsmall};
      margin-bottom: ${theme.spacings.xxsmall};
      margin-left: ${theme.spacings.xxsmall};
    }
  `}
`;

const navModifiers = {
  disabled: (theme: DefaultTheme) => css`
    color: ${rgba(theme.colors.black, 0.5)};
  `,
  withHover: (theme: DefaultTheme) => css`
    color: ${theme.colors.black};
    cursor: pointer;

    &:hover {
      color: ${theme.colors.primary};
    }
  `,
};

export type NavProps = {
  disabled?: boolean;
};

export const Next = styled(NavigateNext)<NavProps>`
  ${({ theme, disabled }) => css`
    width: 3rem;

    ${disabled && navModifiers.disabled(theme)};
    ${!disabled && navModifiers.withHover(theme)};
  `}
`;

export const Previous = styled(NavigateBefore)<NavProps>`
  ${({ theme, disabled }) => css`
    width: 3rem;

    ${disabled && navModifiers.disabled(theme)};
    ${!disabled && navModifiers.withHover(theme)};
  `}
`;

export const Content = styled.span`
  ${({ theme }) => css`
    margin-top: 0.2rem;
    color: ${theme.colors.black};
  `}
`;
