import { CloseCircle as CloseIcon } from 'styled-icons/ionicons-outline/';

import Button from '../../../../components/Button';
import { Response } from '../../../../models/response';

import * as S from '../styles';

type ErrorProps = {
  response?: Response;
  reset: () => void;
};

const Error = ({ response, reset }: ErrorProps): JSX.Element =>
  response?.error ? (
    <S.Wrapper>
      <S.Title color="error">
        <CloseIcon size={32} />
        <h2>Solicitação Falhou</h2>
      </S.Title>

      <S.Paragraph>{response.error.message}</S.Paragraph>

      <S.Footer>
        <Button size="large" color="error" onClick={reset}>
          OK
        </Button>
      </S.Footer>
    </S.Wrapper>
  ) : (
    <></>
  );

export default Error;
