import Button from '../../../components/Button';
import { Patient } from '../../../models/patient';

import * as S from './styles';

type OptionsProps = {
  error?: boolean;
  selectedPatients: Patient[];
  downloadSimpleReport: () => void;
};

const Options = ({
  error,
  selectedPatients,
  downloadSimpleReport,
}: OptionsProps): JSX.Element => {
  return !error ? (
    <>
      <h1>Qual relatório você deseja emitir?</h1>

      <S.Alert severity="warning">
        Antes de solicitar a geração do relatório, certifique-se de que os
        pacientes selecionados se referem à mesma pessoa.
      </S.Alert>

      <S.Content>
        <S.Section>
          <h2>Simplificado</h2>
          <p>
            Este relatório mostra que sua instituição possui dados sensíveis do
            paciente e quais sistemas detêm estes dados, mas não mostra detalhes
            de acesso.
          </p>
        </S.Section>

        <S.Section>
          <h2>Completo</h2>
          <p>
            Este relatório mostra detalhes de acesso aos dados sensíveis do
            paciente. <strong>Sua geração pode demorar até 7 dias.</strong>
          </p>
        </S.Section>
      </S.Content>

      <S.ButtonGroup>
        <Button size="large" outlined onClick={downloadSimpleReport}>
          SIMPLIFICADO
        </Button>
        <Button
          size="large"
          to={{
            pathname: '/full-report',
            state: selectedPatients,
          }}
        >
          COMPLETO
        </Button>
      </S.ButtonGroup>
    </>
  ) : (
    <></>
  );
};

export default Options;
