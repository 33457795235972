import { ChangeEvent, InputHTMLAttributes, useRef, useState } from 'react';

import InputUpload from './InputUpload';

import * as S from './styles';

type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'size' | 'type' | 'multiple' | 'onChange' | 'role'
>;

export type UploadProps = {
  name: string;
  title: string;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'error';
  outlined?: boolean;
  disabled?: boolean;
  loading?: boolean;
  showNameFile?: boolean;
  labelFor?: string;
  label?: string;
  placeholder?: string;
  onChange?: (formData: FormData) => void;
} & InputProps;

const Upload = ({
  title,
  name,
  onChange,
  className,
  labelFor,
  label,
  placeholder,
  showNameFile = false,
  size = 'medium',
  outlined = false,
  color = 'primary',
  disabled = false,
  loading = false,
  ...props
}: UploadProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File | null>(null);
  const [formState, setFormState] = useState<FormData>();

  const handlerChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const [selectedFile] = Array.from(event.target.files || []);

    if (selectedFile) {
      const formData = new FormData();

      formData.append(name, selectedFile, selectedFile.name);

      setFile(selectedFile);
      setFormState(formData);

      !!onChange && onChange(formData);
    }
  };

  const handleClickButton = (): void => {
    ref.current?.click();
  };

  return (
    <S.Wrapper className={className}>
      <S.FileInput ref={ref} $size={size} onChange={handlerChange} {...props} />

      {showNameFile && labelFor ? (
        <InputUpload
          handleClickButton={handleClickButton}
          labelFor={labelFor}
          file={file}
          label={label}
          placeholder={placeholder}
          formState={formState}
        />
      ) : (
        <S.ButtonUpload
          size={size}
          color={color}
          disabled={disabled || !!loading}
          outlined={outlined}
          loading={loading}
          onClick={handleClickButton}
        >
          {title}
        </S.ButtonUpload>
      )}
    </S.Wrapper>
  );
};

export default Upload;
