// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DefaultItem = Record<string, any>;

export type ItemIdentifierKey<Item> = keyof Item;

export type UseSelectedItems<Item> = {
  items: ItemWithSelectedState<Item>[];
  selectedItems: Item[];
  toggleSingleItem: (item: Item) => void;
  toggleAllItem: (selectAll?: boolean) => void;
};

export type ItemWithSelectedState<T> = T & {
  isSelected: boolean;
  [key: string]: unknown;
};

export type Arguments<Item> = {
  initialItems: Array<Item>;
  itemIdentifierKey: ItemIdentifierKey<Item>;
  initialSelectedItems?: Array<Item>;
};

export type State<Item> = {
  items: Array<ItemWithSelectedState<Item>>;
  itemIdentifierKey: ItemIdentifierKey<Item>;
  selectAll?: boolean;
};

export const ActionType = {
  INITIALIZE_ITEMS: 'INITIALIZE_ITEMS',
  TOGGLE_SINGLE_ITEM: 'TOGGLE_SINGLE_ITEM',
  TOGGLE_ALL_ITEMS: 'TOGGLE_ALL_ITEMS',
} as const;

type ActionPayloads<Item extends DefaultItem> = {
  [ActionType.TOGGLE_SINGLE_ITEM]: {
    itemIdentifierValue?: Item[ItemIdentifierKey<Item>];
  };
  [ActionType.INITIALIZE_ITEMS]: {
    initialSelectedItems: Array<Item>;
    initialItems: Array<Item>;
  };
  [ActionType.TOGGLE_ALL_ITEMS]: {
    selectAll?: boolean;
  };
};

type ActionMap<M extends Record<string, unknown>> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type Action<Item> = ActionMap<ActionPayloads<Item>>[keyof ActionMap<
  ActionPayloads<Item>
>];
