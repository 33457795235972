import { HTMLAttributes } from 'react';

import { useAuth } from '../../hooks/auth';
import useToggle from '../../hooks/use-toggle';
import BellNotify, { BellProps } from '../Bell';
import OrganizationsModal from '../OrganizationsModal';
import ProfileMenu, { ProfileMenuProps } from '../ProfileMenu';
import ListOrganizationsTitle from './ListOrganizationsTitle';

import * as S from './styles';

export type HeaderProps = {
  bell?: BellProps;
  profileMenu: ProfileMenuProps;
} & HTMLAttributes<HTMLHeadElement>;

const Header = ({ bell, profileMenu, ...props }: HeaderProps): JSX.Element => {
  const { signOut, chosenOrganization, user } = useAuth();

  const { isShow, toggle } = useToggle(false);

  const isOnlyOneOrganization = user?.organizations.length <= 1;

  const handleToggle = (): void => {
    !isOnlyOneOrganization && toggle();
  };

  return (
    <>
      <OrganizationsModal openModal={isShow} setOpenModal={toggle} />

      <S.NavHeader {...props}>
        <S.Nav>
          <ListOrganizationsTitle
            handleToggle={handleToggle}
            isOnlyOneOrganization={isOnlyOneOrganization}
            chosenOrganization={chosenOrganization}
          />

          <S.Profile>
            <BellNotify {...bell} />
            <ProfileMenu {...profileMenu} onClickLogout={signOut} />
          </S.Profile>
        </S.Nav>
      </S.NavHeader>
    </>
  );
};

export default Header;
