import styled, { css, DefaultTheme } from 'styled-components';

import { UploadProps } from '.';
import Button from '../Button';

export type WrapperProps = {
  $size: UploadProps['size'];
};

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    height: 3rem;
    font-size: ${theme.font.paragraphs.sizes.xsmall};
  `,
  medium: (theme: DefaultTheme) => css`
    height: 4rem;
    font-size: ${theme.font.paragraphs.sizes.small};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.medium};
  `,
  large: (theme: DefaultTheme) => css`
    height: 5rem;
    font-size: ${theme.font.paragraphs.sizes.medium};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xlarge};
  `,
};

export const Label = styled.label`
  ${({ theme }) => css`
    display: inline-block;
    font-size: ${theme.font.paragraphs.sizes.medium};
    font-weight: ${theme.font.paragraphs.bold};
    color: ${theme.colors.black};
    cursor: pointer;
    margin-bottom: ${theme.spacings.xxsmall};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const FileInput = styled.input.attrs({ type: 'file' })<WrapperProps>`
  ${({ theme, $size }) => css`
    display: block;
    cursor: pointer;
    border: 0;
    outline: 0;
    opacity: 0;

    ${!!$size && wrapperModifiers[$size](theme)}
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    background: ${theme.colors.lightWhite};
    border-radius: ${theme.border.radius};
    padding: 0 ${theme.spacings.xsmall};
    border: 0.1rem solid;
    border-color: ${theme.colors.black};
    color: ${theme.colors.black};
    font-family: ${theme.font.paragraphs.family};
    font-size: ${theme.font.paragraphs.sizes.medium};
    padding: calc(${theme.spacings.xsmall} - 0.15rem) 1.9rem;

    &:focus-within {
      --box-shadow-color: ${theme.colors.primary};
      box-shadow: 0 0 0.5rem var(--box-shadow-color);
    }
  `}
`;

export const ButtonUpload = styled(Button)`
  position: absolute;
  width: 100%;
`;

export const InputWrapperWithLabel = styled.div`
  position: absolute;
  width: 100%;
`;
