import { useEffect, useRef, RefObject } from 'react';

export type Response = {
  ref: RefObject<HTMLDivElement>;
};

export type Element = 'previousSibling';

const refContainsEvent = (ref: RefObject<HTMLElement>, event: Event): boolean =>
  ref.current?.contains(event.target as Node) || false;

const nodeContainsEvent = (
  node: ChildNode | null | undefined,
  event: Event,
): boolean => node?.contains(event.target as Node) || false;

const containsEvent = {
  previousSibling: (ref: RefObject<HTMLElement>, event: Event): boolean =>
    nodeContainsEvent(ref?.current?.previousSibling, event),
};

const ignoreEvent = (
  ref: RefObject<HTMLElement>,
  event: Event,
  elements: Element[],
): boolean => {
  if (refContainsEvent(ref, event)) {
    return true;
  }

  for (let index = 0; index < elements.length; index += 1) {
    if (containsEvent[elements[index]](ref, event)) {
      return true;
    }
  }

  return false;
};

export default function useOutsideClick(
  onOutsideClick: (event: Event) => void,
  ignore: Element[] = [],
): Response {
  const ref = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: Event): void => {
    const callable = !ignoreEvent(ref, event, ignore);

    if (callable) {
      onOutsideClick(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  return { ref };
}
