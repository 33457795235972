import { ReactNode, ReactPortal, useCallback, useEffect } from 'react';

import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
import { CloseOutline } from 'styled-icons/evaicons-outline';

import * as S from './styles';

export type ModalProps = {
  children: ReactNode;
  isShow: boolean;
  showCloseButton?: boolean;
  closeOutsideClick?: boolean;
  toggle: () => void;
};

const Modal = ({
  children,
  isShow,
  showCloseButton = true,
  closeOutsideClick = true,
  toggle,
  ...props
}: ModalProps): ReactPortal | null => {
  const handleClose = useCallback((): void => {
    closeOutsideClick && toggle();
  }, [closeOutsideClick, toggle]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'Escape' && isShow) {
        handleClose();
      }
    },
    [isShow, handleClose],
  );

  useEffect(() => {
    isShow
      ? (document.body.style.overflow = 'hidden')
      : document.body.removeAttribute('style');

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
      document.body.removeAttribute('style');
    };
  }, [isShow, onKeyDown]);

  const modal = (
    <>
      <S.Background onClick={handleClose} />
      <FocusLock>
        <S.ModalWrapper aria-modal tabIndex={-1} role="dialog" {...props}>
          <S.ModalHeader>
            {showCloseButton && (
              <S.CloseButton onClick={toggle} aria-label="Close" role="button">
                <CloseOutline />
              </S.CloseButton>
            )}
          </S.ModalHeader>

          <S.ModalContent>{children}</S.ModalContent>
        </S.ModalWrapper>
      </FocusLock>
    </>
  );

  return isShow ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
