import { memo } from 'react';

import * as S from './styles';

export type BellProps = {
  numberNotification?: number;
};

const BellNotify = ({ numberNotification = 0 }: BellProps): JSX.Element => {
  const isExceeded = numberNotification > 9;
  const notificationText =
    numberNotification <= 9 ? numberNotification.toString() : '9+';

  return (
    <S.ButtonBell>
      <S.BellCustomNotify />
      {!!numberNotification && (
        <S.NumberNotification exceeded={isExceeded}>
          {notificationText}
        </S.NumberNotification>
      )}
    </S.ButtonBell>
  );
};

export default memo(BellNotify);
