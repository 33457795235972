import { useCallback, useState } from 'react';

type UseToggle = {
  isShow: boolean;
  toggle: () => void;
};

const useToggle = (initialValue = false): UseToggle => {
  const [isShow, setIsShow] = useState<boolean>(initialValue);

  const toggle = useCallback(() => {
    setIsShow(show => !show);
  }, []);

  return {
    isShow,
    toggle,
  };
};

export default useToggle;
