import { useContext, useRef } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import moment from 'moment';
import * as Yup from 'yup';

import { EmittedReportsContext } from '.';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import Form from '../../components/Form';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import { ReportRequest } from '../../models/report-request';
import { statusEnum } from './Status';

import * as S from './styles';

export type FormData = {
  type?: 'NAME' | 'DOCUMENT';
  endDate?: string;
  startDate?: string;
  search?: string;
} & Partial<Pick<ReportRequest, 'status'>>;

const FormEmittedReports = (): JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  const { page, loading } = useContext(EmittedReportsContext);

  const handleSubmit: SubmitHandler<FormData> = async ({
    endDate,
    startDate,
    ...form
  }) => {
    const params = {
      endDate: endDate
        ? moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '',
      startDate: startDate
        ? moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '',
      ...form,
    };

    page && page.fetch(params);
  };

  const schema = Yup.object().shape({});

  const type = [
    { value: 'ID', text: 'ID' },
    { value: 'NAME', text: 'Nome' },
    { value: 'PREFERRED_NAME', text: 'Nome Social' },
    { value: 'DOCUMENT', text: 'Documento' },
  ];

  return (
    <>
      <Form formRef={formRef} onSubmit={handleSubmit} schema={schema}>
        <S.FormEmittedReports>
          <Select
            labelFor="type"
            label="Selecione:"
            defaultValue={['NAME']}
            initialValues={type}
          />
          <S.FieldSearch>
            <TextField
              label="Digite o que você quer buscar:"
              labelFor="search"
            />
          </S.FieldSearch>
          <Select
            labelFor="status"
            label="Status:"
            initialValues={statusEnum}
          />
          <DatePicker labelFor="startDate" label="De:" />
          <DatePicker labelFor="endDate" label="Até:" />
        </S.FormEmittedReports>

        <S.ButtonSearch>
          <Button
            type="submit"
            size="large"
            disabled={!!loading}
            loading={loading === 'submit'}
          >
            PESQUISAR
          </Button>
        </S.ButtonSearch>
      </Form>

      {page?.empty && (
        <p>Não encontramos nenhum registro para o filtro utilizado.</p>
      )}
    </>
  );
};

export default FormEmittedReports;
