import moment from 'moment';
import { AnySchema, ValidationError } from 'yup';
import * as Yup from 'yup';

import { MIN_DATE, MAX_DATE } from './date_utils';

export const Validations = {
  date: (
    message: string,
    value?: string,
    format = 'DD/MM/yyyy',
    optional = true,
  ): string => {
    if (optional && !value) {
      return '';
    }

    const date = moment(value, format);

    const dateIsValid =
      value?.length !== format.length ||
      !date.isValid() ||
      !date.toDate().getDate() ||
      !date.isSameOrAfter(MIN_DATE, 'month') ||
      !date.isSameOrBefore(MAX_DATE, 'month');

    if (dateIsValid) {
      return message;
    }

    return '';
  },
};

export const validateSchema = async (
  data: Record<string, unknown>,
  schema?: AnySchema,
): Promise<Record<string, string>> => {
  try {
    await schema?.validate({ ...data }, { abortEarly: false });
  } catch (err) {
    if (err instanceof ValidationError) {
      const errorMessages: Record<string, string> = {};

      err.inner.forEach(error => {
        if (error.path) {
          errorMessages[error.path] = error.message;
        }
      });

      return errorMessages;
    }
  }

  return {};
};

export const schemaDate = (
  nameFieldDate: string,
  validationMessage: string,
): AnySchema => {
  return Yup.object().shape({
    data: Yup.string().test(
      nameFieldDate,
      validationMessage,
      value => Validations.date(validationMessage, value) === '',
    ),
  });
};
