import { CloseCircle as CloseIcon } from 'styled-icons/ionicons-outline/';

import Button from '../../components/Button';
import Modal from '../../components/Modal';

import * as S from './styles';

type ErrorEmittedReportProps = {
  error?: string;
  isShow: boolean;
  toggle: () => void;
};

const ErrorEmittedReport = ({
  error,
  isShow,
  toggle,
}: ErrorEmittedReportProps): JSX.Element => (
  <Modal isShow={isShow} toggle={toggle}>
    <S.TitleModalError>
      <CloseIcon size={32} />
      <h2>Solicitação Falhou</h2>
    </S.TitleModalError>
    <S.ParagraphModalError>
      {error || 'Error não identificado'}
    </S.ParagraphModalError>
    <S.FooterModalError>
      <Button size="large" color="error" onClick={toggle}>
        OK
      </Button>
    </S.FooterModalError>
  </Modal>
);

export default ErrorEmittedReport;
