import { lighten } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

import { Input as StyleInputCheckbox } from '../Checkbox/styles';

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: ${theme.colors.lightWhite};
    border: 0.1rem solid ${theme.colors.black};
    border-radius: ${theme.border.radius};
    cursor: pointer;
    height: 5rem;
    padding-left: ${theme.spacings.xsmall};
    padding-right: calc(${theme.spacings.xsmall} - 0.2rem);

    span {
      padding: ${theme.spacings.xsmall} 0;
      text-align: start;
      border: none;
      background: none;
      color: ${theme.colors.black};
      font-family: ${theme.font.paragraphs.family};
      font-size: ${theme.font.paragraphs.sizes.medium};
      outline: none;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      min-height: 5rem;
    }
  `}
`;

export const SelectChevronDown = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};

    svg {
      width: 3.1rem;
    }
  `}
`;

type ItemsWrapperProps = {
  flex: boolean;
};

export const ItemsWrapper = styled.div<ItemsWrapperProps>`
  ${({ theme, flex }) => css`
    display: ${flex ? 'flex' : 'none'};
    flex-direction: column;
    background: ${theme.colors.lightWhite};
    border: 1px solid ${theme.colors.black};
    border-radius: ${theme.border.radius};
    overflow-x: auto;
    position: absolute;
    width: 100%;
    height: min-content;
    z-index: ${theme.layers.overlay};
    max-width: 100%;
    margin-top: 0.5rem;

    overflow: auto;
    max-height: 10rem;
  `}
`;

export const OptionSingle = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    width: 100%;
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
    color: ${theme.colors.black};

    &:hover {
      background: ${lighten(0.1, theme.colors.primary)};
      color: ${theme.colors.white};
    }
  `}
`;

export const LabelTitle = styled.label`
  ${({ theme }) => css`
    display: inline-block;
    font-size: ${theme.font.paragraphs.sizes.medium};
    font-weight: ${theme.font.paragraphs.bold};
    color: ${theme.colors.black};
    margin-bottom: ${theme.spacings.xxsmall};
  `}
`;

const wrapperModifiers = {
  error: (theme: DefaultTheme) => css`
    ${InputWrapper} {
      border-color: ${theme.colors.error};
      &:focus-within {
        --box-shadow-color: ${theme.colors.error};
      }
    }

    ${LabelTitle}, ${SelectChevronDown} svg, ${InputWrapper} span {
      color: ${theme.colors.error};
    }
  `,
};

type WrapperSelectProps = {
  error?: boolean;
};

export const Wrapper = styled.div<WrapperSelectProps>`
  ${({ theme, error }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    outline: none;
    user-select: none;
    width: 100%;

    ${error && wrapperModifiers.error(theme)};
  `}
`;

export const ContentWrapper = styled.div`
  outline: none;
`;

export const Error = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: ${theme.font.paragraphs.sizes.small};
    margin-top: ${theme.spacings.xxsmall};
  `}
`;

export const SelectInputCheckbox = styled(StyleInputCheckbox)``;

export const SelectInputCheckboxWrapper = styled.div`
  ${({ theme }) => css`
    height: 3rem;
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    color: ${theme.colors.black};
  `}
`;

export const SelectLabelCheckbox = styled.label`
  width: 100%;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none;
  padding: 0.5rem;
  font-weight: bold;
`;
