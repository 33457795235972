/* eslint-disable import/no-unresolved */
import { useEffect, useRef, useState } from 'react';

import DecoupledEditorBuild from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/pt-br';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled';
import { useField } from '@unform/core';

import Alert from '../Alert';

import * as S from './styles';

const config: EditorConfig = {
  removePlugins: [
    'Image',
    'ImageCaption',
    'ImageStyle',
    'ImageToolbar',
    'ImageUpload',
    'EasyImage',
    'CKFinder',
    'MediaEmbed',
  ],
  toolbar: { removeItems: ['mediaEmbed', 'uploadImage'] },
  language: 'pt-br',
};

export type CkEditorProps = {
  labelFor: string;
  label?: string;
  initialData?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onReady?: (editor: DecoupledEditor) => void;
};

const CkEditor = ({
  labelFor,
  label,
  error: customError,
  initialData,
  placeholder,
  disabled,
  onReady,
}: CkEditorProps): JSX.Element => {
  const [value, setValue] = useState(initialData);
  const [errorStack, setErrorStack] = useState<string | undefined>('');
  const editorContent = useRef<HTMLDivElement>(null);
  const toolbarContainer = useRef<HTMLDivElement>(null);

  const { registerField, error: unformError } = useField(labelFor);

  const error = customError || unformError;

  useEffect(() => {
    registerField({
      name: labelFor,
      getValue: () => value,
    });
  }, [labelFor, registerField, value]);

  const change = (editor: DecoupledEditor): void => {
    setValue(() => editor.getData());
  };

  const buildingToolbar = (editor: DecoupledEditor): void => {
    const toolbar = toolbarContainer.current as HTMLDivElement;
    toolbar.innerHTML = '';
    toolbar.appendChild(editor.ui.view.toolbar.element as HTMLDivElement);
  };

  useEffect(() => {
    const ac = new AbortController();
    let ckeditorInstance: DecoupledEditor;

    DecoupledEditorBuild.create(editorContent.current, {
      ...config,
      initialData,
      placeholder,
    })
      .then((editor: DecoupledEditor) => {
        ckeditorInstance = editor;
        if (disabled) ckeditorInstance.isReadOnly = disabled;

        buildingToolbar(ckeditorInstance);

        const modelDocument = ckeditorInstance.model.document;

        modelDocument.on('change:data', () => {
          change(ckeditorInstance);
        });

        onReady && onReady(ckeditorInstance);

        return ckeditorInstance;
      })
      .catch((err: Error) => {
        setErrorStack(err.stack);
        ac.abort();
      });

    return () => {
      ckeditorInstance?.destroy();
    };
  }, [onReady, initialData, disabled, placeholder]);

  return (
    <>
      <Alert severity="error">{errorStack}</Alert>
      {!!label && <S.Label htmlFor={labelFor}>{label}</S.Label>}
      <S.Wrapper>
        <div ref={toolbarContainer} role="toolbar" />
        <div ref={editorContent} role="textbox" aria-labelledby={labelFor} />
      </S.Wrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </>
  );
};

export default CkEditor;
