import { AxiosError } from 'axios';
import moment from 'moment';
import * as Yup from 'yup';

import { getPixeonId } from '../../hooks/auth';
import { Patient } from '../../models/patient';
import { ReportRequesting } from '../../models/report-requesting';
import { Error, Response } from '../../models/response';
import api from '../../services/api';
import paramsSerializer from '../../utils/params-serializer';
import { validateSchema } from '../../utils/validations';

export type Period =
  | 'allRecords'
  | 'oneYearAgo'
  | 'sixMonthsAgo'
  | 'oneMonthAgo'
  | 'customPeriod';

export type FormData = {
  period?: Period;
  from?: Date;
  to?: Date;
};

export async function validateForm(
  formData: FormData,
): Promise<Record<string, string>> {
  const schema = Yup.object().shape({
    period: Yup.string().required('Selecione um período'),
    from: Yup.date().when('period', {
      is: (period: string) => period === 'customPeriod',
      then: Yup.date().required('Data inválida'),
    }),
    to: Yup.date().when('period', {
      is: (period: string) => period === 'customPeriod',
      then: Yup.date()
        .required('Data inválida')
        .min(Yup.ref('from'), 'Deve ser maior que a data inicial')
        .max(new Date(), 'Data final não pode ser maior que hoje'),
    }),
  });

  return validateSchema(formData, schema);
}

const periods = {
  allRecords: (_: FormData) => ({ startDate: undefined, endDate: undefined }),
  oneYearAgo: (_: FormData) => ({
    startDate: moment().subtract(1, 'years').format('yyyy-MM-DD'),
    endDate: moment().format('yyyy-MM-DD'),
  }),
  sixMonthsAgo: (_: FormData) => ({
    startDate: moment().subtract(6, 'months').format('yyyy-MM-DD'),
    endDate: moment().format('yyyy-MM-DD'),
  }),
  oneMonthAgo: (_: FormData) => ({
    startDate: moment().subtract(1, 'months').format('yyyy-MM-DD'),
    endDate: moment().format('yyyy-MM-DD'),
  }),
  customPeriod: ({ from, to }: FormData) => ({
    startDate: moment(from).format('yyyy-MM-DD'),
    endDate: moment(to).format('yyyy-MM-DD'),
  }),
};

const toReportRequesting = (
  formData: FormData,
  selectedPatients: Patient[],
): ReportRequesting => ({
  selectedPatients: selectedPatients.map(patient => ({
    originalId: patient.originalId,
    application: patient.application,
    pixeonId: patient.pixeonId,
  })),
  ...periods[formData.period as Period](formData),
});

const errors = {
  default:
    'Ocorreu uma falha ao efetuar requisição. Tente novamente mais tarde.',
  connection:
    'Não foi possível estabelecer conexão. Por favor, verifique sua conexão e tente novamente.',
};

export async function requestReport(
  formData: FormData,
  selectedPatients: Patient[],
): Promise<Response> {
  const treatmentError = (
    ex: AxiosError<Record<string, unknown>>,
  ): { error: Error<Record<string, unknown>> } => {
    const error: Error = { message: errors.default };

    if (ex.response) {
      error.status = ex.response.status;
      error.payload = ex.response.data;
    } else if (ex.request) {
      error.message = errors.connection;
    }

    return { error };
  };

  try {
    const requestBody = toReportRequesting(formData, selectedPatients);
    const response = await api.post('/report-requests', requestBody, {
      params: {
        pixeonId: getPixeonId(),
      },
      paramsSerializer,
    });
    return { data: response.data };
  } catch (ex) {
    return treatmentError(ex as AxiosError<Record<string, unknown>>);
  }
}
