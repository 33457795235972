import Button from '../../components/Button';
import Modal from '../../components/Modal';

import * as S from './styles';

type ModalSuccessProps = {
  toggle: () => void;
  isShow: boolean;
};

const ModalSuccess = ({ toggle, isShow }: ModalSuccessProps): JSX.Element => {
  return (
    <Modal
      isShow={isShow}
      toggle={toggle}
      showCloseButton={false}
      closeOutsideClick={false}
    >
      <h3>Registro salvo com sucesso.</h3>

      <S.BodyModalConsent>
        <Button onClick={toggle} color="secondary">
          OK
        </Button>
      </S.BodyModalConsent>
    </Modal>
  );
};

export default ModalSuccess;
