import styled, { css } from 'styled-components';

import DefaultTable from '../../components/Table';

export const Table = styled(DefaultTable)`
  th {
    padding: 1rem 1.4rem;
  }

  td {
    padding: 1.4rem;
  }
`;

export const SelectedPatients = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacings.large} 0rem;

    table {
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
`;

export const ChoosePeriod = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
    margin-bottom: ${theme.spacings.xxlarge};
  `}
`;

export const Radios = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 2rem;
    margin-top: ${theme.spacings.small};
    flex-wrap: wrap;
  `}
`;

export const CustomPeriod = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 2rem;
    margin-top: ${theme.spacings.medium};
    width: 58.5rem;
  `}
`;
