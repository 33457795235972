import { FocusEvent, KeyboardEvent, useEffect, useState } from 'react';

import { Moment } from 'moment';
import { Calendar } from 'styled-icons/boxicons-regular';

import useOutsideClick from '../../hooks/use-outside-click';
import TextField, { TextFieldProps } from '../TextField';
import DayPicker from './DayPicker';
import MonthPicker from './MonthPicker';
import { DateFormat, Locale } from './utils';
import { validateInitialValue } from './utils';
import { buildDate, toMoment, toDateValue } from './utils';
import YearPicker from './YearPicker';

import * as S from './styles';

export type InputProps = Pick<
  TextFieldProps,
  'label' | 'labelFor' | 'disabled' | 'error'
>;

export type DatePickerProps = {
  locale?: Locale;
  initialValue?: Date;
  dateFormat?: DateFormat;
  onChange?: (date?: Date) => void;
} & InputProps;

export type Picker = 'DayPicker' | 'MonthPicker' | 'YearPicker' | 'None';

const DatePicker = ({
  locale = 'pt-br',
  initialValue,
  dateFormat = 'DD/MM/yyyy',
  onChange,
  ...props
}: DatePickerProps): JSX.Element => {
  validateInitialValue(initialValue);

  const [activePicker, setActivePicker] = useState<Picker>('None');
  const [selectedDate, setSelectedDate] = useState(toMoment(initialValue));

  const [dateValue, setDateValue] = useState(
    toDateValue(dateFormat, initialValue),
  );

  useEffect(() => {
    setDateValue(toDateValue(dateFormat, initialValue));
    setSelectedDate(toMoment(initialValue));
  }, [initialValue, dateFormat]);

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const newDate = buildDate(e.target.value, dateFormat);
    !!onChange && onChange(newDate?.toDate());
  };

  const changeDateValue = (date: string): void => {
    setDateValue(date);
    const newDate = buildDate(date, dateFormat);

    if (newDate) {
      setSelectedDate(newDate);
      !!onChange && onChange(newDate.toDate());
    }
  };

  const changeSelectedDate = (date: Moment): void => {
    setSelectedDate(date);
    setDateValue(date.format(dateFormat));
    !!onChange && onChange(date.toDate());
  };

  const handleTyping = (value: string): void => {
    changeDateValue(value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    event.key === 'Tab' && setActivePicker('None');
  };

  const handleInputClick = (): void => {
    setActivePicker(prev => (prev === 'None' ? 'DayPicker' : prev));
  };

  const handleIconClick = (): void => {
    setActivePicker(prev => (prev === 'None' ? 'DayPicker' : 'None'));
  };

  const { ref } = useOutsideClick(() => {
    setActivePicker('None');
  }, ['previousSibling']);

  return (
    <S.Wrapper>
      <TextField
        initialValue={dateValue}
        icon={<Calendar />}
        onTyping={handleTyping}
        onKeyDown={handleKeyDown}
        inputClick={handleInputClick}
        iconClick={handleIconClick}
        iconPosition="right"
        placeholder="__/__/____"
        mask="99/99/9999"
        onBlur={handleOnBlur}
        {...props}
      />
      <S.PickerGroup ref={ref}>
        <DayPicker
          selectedDate={selectedDate}
          setSelectedDate={changeSelectedDate}
          activePicker={activePicker}
          setActivePicker={setActivePicker}
          locale={locale}
        />
        <MonthPicker
          selectedDate={selectedDate}
          setSelectedDate={changeSelectedDate}
          activePicker={activePicker}
          setActivePicker={setActivePicker}
          locale={locale}
        />
        <YearPicker
          selectedDate={selectedDate}
          setSelectedDate={changeSelectedDate}
          activePicker={activePicker}
          setActivePicker={setActivePicker}
        />
      </S.PickerGroup>
    </S.Wrapper>
  );
};

export default DatePicker;
