import { HTMLAttributes } from 'react';

import * as S from './styles';

export type AvatarProps = {
  imgUrlAvatar?: string;
  name: string;
} & HTMLAttributes<HTMLElement>;

const Avatar = ({ imgUrlAvatar, name, ...props }: AvatarProps): JSX.Element => {
  if (imgUrlAvatar) {
    return <S.ImageWrapper src={imgUrlAvatar} alt={name} {...props} />;
  }

  const shortName =
    name &&
    name
      .split(/\s/g)
      .slice(0, 2)
      .map(v => v.charAt(0).toUpperCase())
      .join('');

  return <S.TextWrapper {...props}>{shortName}</S.TextWrapper>;
};

export default Avatar;
