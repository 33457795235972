type RequestParams<T = unknown> = Record<string, T>;

export default function paramsSerializer(params: RequestParams): string {
  let regularParams: RequestParams<string> = {};
  let arrayParams: RequestParams<string[]> = {};

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      arrayParams = { ...arrayParams, [key]: value };
    } else if (value) {
      regularParams = { ...regularParams, [key]: `${value}` };
    }
  }

  const url = new URLSearchParams(regularParams);

  const normalizingOnlyArray = Object.entries(arrayParams)
    .map(([key, value]) => value.map(v => `${key}=${v}`).join('&'))
    .join('&');

  return [url.toString(), normalizingOnlyArray].filter(v => !!v).join('&');
}
