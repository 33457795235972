import styled, { css } from 'styled-components';

import DefaultAlert from '../../../components/Alert';

export type Visibility = {
  visible?: boolean;
};

export const Content = styled.div<Visibility>`
  ${({ visible }) => css`
    visibility: ${visible ? 'visible' : 'hidden'};
  `}
`;

export const Alert = styled(DefaultAlert)`
  ${({ theme }) => css`
    margin: ${theme.spacings.small} 0rem;
  `}
`;

type TitleProps = {
  color: 'secondary' | 'error';
};

export const Title = styled.div<TitleProps>`
  ${({ theme, color }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xsmall};
    color: ${theme.colors[color]};
  `}
`;

export const Wrapper = styled.div`
  max-width: 50rem;
  position: relative;
`;

export const Paragraph = styled.p`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} 0rem;
    line-height: ${theme.spacings.small};
  `}
`;

export const Footer = styled.h2`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacings.xsmall};
  `}
`;

export const Overlay = styled.div<Visibility>`
  ${({ visible }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    align-items: center;
    justify-content: center;
    display: ${visible ? 'flex' : 'none'};
    background-color: transparent;
  `}
`;
