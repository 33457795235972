import { RefObject } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';

import { validateSchema } from '../../utils/validations';

import * as S from './styles';

export type FormError = Record<string, string>;

export type FormProps = {
  onSubmit: SubmitHandler;
  schema?: Yup.AnySchema;
  children: React.ReactNode;
  formRef?: RefObject<FormHandles>;
  cleanErrors?: boolean;
};

const Form = ({
  onSubmit,
  schema,
  children,
  formRef,
  cleanErrors = true,
  ...props
}: FormProps): JSX.Element => {
  const handleSubmit: SubmitHandler = async (data, helpers, event) => {
    const errors = await validateSchema(data, schema);
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      formRef?.current?.setErrors(errors);
    } else {
      cleanErrors && formRef?.current?.setErrors({});
      onSubmit(data, helpers, event);
    }
  };

  return (
    <S.Form ref={formRef} onSubmit={handleSubmit} {...props}>
      {children}
    </S.Form>
  );
};

export default Form;
