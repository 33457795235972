import { useContext, useState } from 'react';

import { EmittedReportsContext } from '.';
import DotLoading from '../../components/DotLoading';
import useToggle from '../../hooks/use-toggle';
import { ReportRequest } from '../../models/report-request';
import FullReport from '../../services/Report/Full';
import { errorMessagesEmittedReport } from './AlertEmittedReport';
import ErrorEmittedReport from './ErrorEmittedReport';

import theme from '../../styles/theme';
import * as S from './styles';

export const statusEnum = [
  { value: '', text: 'Todos' },
  { value: 'PENDING', text: 'Pendente' },
  { value: 'IN_PROGRESS', text: 'Em processo' },
  { value: 'DONE', text: 'Finalizado' },
  { value: 'ERROR', text: 'Erro' },
  { value: 'EXPIRED', text: 'Expirado' },
];

export type StatusProps = ReportRequest;

const Status = ({ status, id, errorMessage }: StatusProps): JSX.Element => {
  const { isShow, toggle } = useToggle();
  const [loading, setLoading] = useState(false);
  const { setAlertMessage: setError } = useContext(EmittedReportsContext);
  const findStatus = statusEnum.find(s => s.value === status);

  const handlerClick = async (): Promise<void> => {
    if (status === 'ERROR') {
      toggle();
    }

    if (status === 'DONE') {
      setLoading(true);

      FullReport.download(id)
        .then(() => {
          setLoading(false);
        })
        .catch(async e => {
          const data = JSON.parse(await e.response.data.text());

          const validateMessage = {
            fileWasNotFoundToID: /^Entity ReportRequest with id (.*) not found$/g.test(
              data.error.message,
            ),
            fileNotFound: /^Arquivo não encontrado$/g.test(data.error.message),
          };

          const notFound =
            (e.response.status === 400 &&
              validateMessage.fileWasNotFoundToID) ||
            (e.response.status === 500 && validateMessage.fileNotFound);

          if (notFound) {
            setError && setError(errorMessagesEmittedReport.notFound);
          } else {
            setError && setError(errorMessagesEmittedReport.failure);
          }

          setLoading(false);
        });
    }
  };

  const colors = (): string | undefined => {
    if (status === 'DONE') {
      return theme.colors.darkSecondary;
    }

    if (status === 'ERROR') {
      return theme.colors.error;
    }

    return undefined;
  };

  const validCursor = ['DONE', 'ERROR'].includes(status) ? 'pointer' : 'auto';

  return loading && status === 'DONE' ? (
    <S.StatusDot>
      <DotLoading />
    </S.StatusDot>
  ) : (
    <>
      {status === 'ERROR' && (
        <ErrorEmittedReport
          isShow={isShow}
          toggle={toggle}
          error={errorMessage}
        />
      )}
      <S.ColorStatus
        color={colors()}
        onClick={handlerClick}
        cursorType={validCursor}
        role="status"
        aria-label={findStatus && findStatus.text}
      >
        {findStatus && findStatus.text}
      </S.ColorStatus>
    </>
  );
};

export default Status;
