import { ComponentType, useState } from 'react';

import Pagination from '../../components/Pagination';
import { ColumnProps, MinTableItem } from '../../components/Table';
import { getNameByPixeonId } from '../../hooks/auth';
import usePagination from '../../hooks/use-pagination';
import { PatientConsent } from '../../models/patient-consent';
import { apiHubLGPD } from '../../services/api';
import PatientConsentAction from './PatientConsentAction';
import PatientConsentForm from './PatientConsentForm';

import * as S from './styles';

export type SearchPatientConsent = {
  nome?: string;
  documento?: string;
  data?: string;
};

type MinTableItemPatientConsent = PatientConsent & MinTableItem;

const PatientConsentIndex = (): JSX.Element => {
  const [message, setMessage] = useState<string>();
  const { loading, page } = usePagination<PatientConsent, SearchPatientConsent>(
    {
      endpoint: '/ConsentimentoPaciente',
      autoLoading: {},
      apiPage: apiHubLGPD,
    },
  );

  const onCloseAlert = (): void => {
    setMessage(undefined);
  };

  const cols: ColumnProps<MinTableItemPatientConsent>[] = [
    {
      title: 'Instituição',
      fieldName: 'pixeonId',
      width: '20rem',
      customRender: ({ termoConsentimento: { pixeonId } }) =>
        getNameByPixeonId(pixeonId) || pixeonId,
    },
    { title: 'Nome', fieldName: 'nome', width: '60rem' },
    { title: 'Documento', fieldName: 'documentoCompleto', width: '15rem' },
    {
      title: 'Termo Consentimento/Ciência',
      fieldName: 'termoConsentimento',
      width: '10rem',
      customRender: ({ termoConsentimento: { versao, tipoTermo } }) =>
        `${versao} - ${tipoTermo === 'science' ? 'ciência' : 'consentimento'}`,
    },
    {
      title: 'IP',
      fieldName: 'ipCriacao',
      width: '5rem',
      customRender: item =>
        item.ipCriacao === '::1' ? '127.0.0.1 localhost' : item.ipCriacao,
    },
    { title: 'Data', fieldName: 'dataCriacao', width: '15rem' },
    { title: 'Situação', fieldName: 'statusAtual', width: '5rem' },
    {
      title: '',
      fieldName: '',
      width: '10rem',
      customRender: item => (
        <PatientConsentAction item={item} setMessage={setMessage} page={page} />
      ),
    },
  ];

  return (
    <S.Wrapper>
      <h1>Pacientes</h1>

      <PatientConsentForm loading={loading} page={page} />

      {page?.empty && (
        <S.NoDataMessage>Não encontramos nenhum registro.</S.NoDataMessage>
      )}

      <S.PatientConsentAlert severity="error" onClose={onCloseAlert} closeable>
        {message}
      </S.PatientConsentAlert>

      {page?.empty === false && (
        <>
          <S.TablePatientConsent<ComponentType<MinTableItemPatientConsent>>
            cols={cols}
            items={page.content}
            loading={!!loading}
          />
          <Pagination
            currentPage={page.number}
            numPages={page.totalPages}
            isFirst={page.first}
            isLast={page.last}
            nextPage={page.next}
            prevPage={page.previous}
            pageItems={page.numberOfElements}
            totalItems={page.totalElements}
            loading={!!loading}
          />
        </>
      )}
    </S.Wrapper>
  );
};

export default PatientConsentIndex;
