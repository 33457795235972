import { useContext } from 'react';

import moment from 'moment';

import { EmittedReportsContext } from '.';
import Pagination from '../../components/Pagination';
import { MinTableItem } from '../../components/Table';
import { ReportRequest } from '../../models/report-request';
import Status from './Status';

import * as S from './styles';

const cols = [
  { title: 'ID', fieldName: 'patientOriginalId' },
  { title: 'Nome', fieldName: 'patientName' },
  {
    title: 'Nome Social',
    fieldName: 'patientPreferredName',
  },
  { title: 'Documento', fieldName: 'patientDocument' },
  {
    title: 'Período',
    fieldName: 'period',
    customRender: ({ startDate, endDate }: MinTableItem) => {
      const start = moment(startDate, 'yyyy-MM-DD');
      const end = moment(endDate, 'yyyy-MM-DD');

      switch (true) {
        case startDate === undefined || endDate === undefined:
          return 'Todos';

        case start.isValid() && end.isValid():
          return `${start.format('DD/MM/yyyy')} - ${end.format('DD/MM/yyyy')}`;

        default:
          return '-';
      }
    },
  },
  {
    title: 'Data Solicitação',
    fieldName: 'requestDate',
    customRender: ({ requestDate }: MinTableItem) => {
      const date = moment(new Date(requestDate));

      return date.isValid() && date.format('DD/MM/yyyy HH:mm');
    },
  },
  {
    title: 'Status',
    fieldName: 'status',
    width: '15rem',
    customRender: (item: MinTableItem) => (
      <Status {...(item as ReportRequest)} />
    ),
  },
];

const TableEmittedReports = (): JSX.Element => {
  const { page, loading } = useContext(EmittedReportsContext);

  return page?.empty === false ? (
    <>
      <S.H3>Últimos relatórios solicitados</S.H3>

      <S.TableEmittedReports
        cols={cols}
        items={page.content}
        loading={!!loading}
      />

      <Pagination
        currentPage={page.number + 1}
        numPages={page.totalPages}
        isFirst={page.first}
        isLast={page.last}
        nextPage={page.next}
        prevPage={page.previous}
        pageItems={page.numberOfElements}
        totalItems={page.totalElements}
        loading={!!loading}
      />
    </>
  ) : (
    <></>
  );
};

export default TableEmittedReports;
