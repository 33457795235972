import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';

import { MAX_DATE, MIN_DATE } from '../../../utils/date_utils';
import { capitalizeFirstChar } from '../../../utils/string-utils';
import { dateIsValid, Locale } from '../utils';

export type CalendarDay = {
  key: string;
  value: Moment;
  dayOfMonth: number;
  isOutOfMonth: boolean;
  isValid: boolean;
};

function addDaysFromPreviousMonth(
  firstDayOfMonth: Moment,
  days: CalendarDay[],
): void {
  if (firstDayOfMonth.day() !== 0) {
    let currentDayOfPreviousMonth = firstDayOfMonth
      .clone()
      .subtract(1, 'days')
      .startOf('week');

    while (currentDayOfPreviousMonth.day() !== firstDayOfMonth.day()) {
      const date = currentDayOfPreviousMonth.clone();

      days.push({
        key: date.format('yyyy-MM-DD'),
        value: date,
        dayOfMonth: Number(currentDayOfPreviousMonth.format('D')),
        isOutOfMonth: true,
        isValid: dateIsValid(date),
      });

      currentDayOfPreviousMonth = currentDayOfPreviousMonth.add(1, 'days');
    }
  }
}

function addDaysFromCurrentMonth(
  firstDayOfMonth: Moment,
  lastDayOfMonth: Moment,
  days: CalendarDay[],
): void {
  let currentDay = firstDayOfMonth.clone();

  while (currentDay.isSameOrBefore(lastDayOfMonth, 'day')) {
    const date = currentDay.clone();

    days.push({
      key: date.format('yyyy-MM-DD'),
      value: date,
      dayOfMonth: Number(currentDay.format('D')),
      isOutOfMonth: false,
      isValid: dateIsValid(date),
    });

    currentDay = currentDay.add(1, 'days');
  }
}

function addDaysFromNextMonth(
  lastDayOfMonth: Moment,
  days: CalendarDay[],
): void {
  const maxCalendarCells = 42;
  let currentDayOfNextMonth = lastDayOfMonth.clone().add(1, 'days');

  while (days.length < maxCalendarCells) {
    const date = currentDayOfNextMonth.clone();

    days.push({
      key: date.format('yyyy-MM-DD'),
      value: date,
      dayOfMonth: Number(currentDayOfNextMonth.format('D')),
      isOutOfMonth: true,
      isValid: dateIsValid(date),
    });

    currentDayOfNextMonth = currentDayOfNextMonth.add(1, 'days');
  }
}

function getCalendarDays(date: Moment): CalendarDay[] {
  const firstDayOfMonth = date.clone().startOf('month');
  const lastDayOfMonth = date.clone().endOf('month');
  const days: CalendarDay[] = [];

  addDaysFromPreviousMonth(firstDayOfMonth, days);
  addDaysFromCurrentMonth(firstDayOfMonth, lastDayOfMonth, days);
  addDaysFromNextMonth(lastDayOfMonth, days);

  return days;
}

function getWeekDays(locale: Locale): string[] {
  const weekDays: string[] = [];

  for (let index = 0; index <= 6; index += 1) {
    const currentWeekDay = moment().locale(locale).weekday(index);
    const weekDay = currentWeekDay.format('dddd').substring(0, 3);
    weekDays.push(capitalizeFirstChar(weekDay));
  }

  return weekDays;
}

function previousMonth(month: Moment): Moment {
  return month.clone().subtract(1, 'month');
}

function nextMonth(month: Moment): Moment {
  return month.clone().add(1, 'month');
}

function hasNextMonth(date: Moment): boolean {
  return date.isBefore(MAX_DATE, 'month');
}

function hasPreviousMonth(date: Moment): boolean {
  return date.isAfter(MIN_DATE, 'month');
}

export const Calendar = {
  next: nextMonth,
  previous: previousMonth,
  hasNext: hasNextMonth,
  hasPrevious: hasPreviousMonth,
  days: getCalendarDays,
  weekDays: getWeekDays,
};
