import { CloseCircle as CloseIcon } from 'styled-icons/ionicons-outline/';

import Button from '../../../components/Button';

import * as S from './styles';

type ErrorProps = {
  error?: boolean;
  reset: () => void;
};

const Error = ({ error, reset }: ErrorProps): JSX.Element =>
  error ? (
    <>
      <S.Title>
        <CloseIcon size={32} />
        <h2>Solicitação Falhou</h2>
      </S.Title>
      <S.Paragraph>
        Falha ao gerar relatório simplificado. Por favor, tente novamente mais
        tarde.
      </S.Paragraph>
      <S.Footer>
        <Button size="large" color="error" onClick={reset}>
          OK
        </Button>
      </S.Footer>
    </>
  ) : (
    <></>
  );

export default Error;
