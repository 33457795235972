import styled, { css } from 'styled-components';

import { SpinnerProps } from '.';

export type WrapperProps = Pick<SpinnerProps, 'color'>;

export const Wrapper = styled.svg<WrapperProps>`
  ${({ theme, color = 'primary' }) => css`
    stroke: ${theme.colors[color]};
    padding: 0.1rem;
    margin: 0.2rem;
  `}
`;
