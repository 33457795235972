import { Signature } from 'styled-icons/fluentui-system-regular';

import { Consent } from '../../models/consent';

import * as S from './styles';

export type TermProps = {
  item: Consent;
};

const BadgeTableItem = ({ item: { tipoTermo } }: TermProps): JSX.Element => {
  const valid = tipoTermo === undefined || tipoTermo === 'consent';

  return (
    <S.CustomBadge>
      <Signature alignmentBaseline="middle" size="16" />
      {valid ? 'CONSENTIMENTO' : 'CIÊNCIA'}
    </S.CustomBadge>
  );
};

export default BadgeTableItem;
