import styled, { css } from 'styled-components';

import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Form from '../../components/Form';

export const InstitutionAlert = styled(Alert)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const InstitutionForm = styled(Form)`
  ${({ theme }) => css`
    width: 100%;

    > div {
      margin-bottom: ${theme.spacings.small};
    }

    > button {
      margin-top: ${theme.spacings.xsmall};
      margin-right: ${theme.spacings.xsmall};
    }
  `}
`;

export const H1 = styled.h1`
  width: 100%;
`;

export const HalfScreen = styled.div`
  width: 49%;
  margin: 1.2rem 0;
`;

export const FullScreen = styled.div`
  width: 100%;
  margin: 1.2rem 0;
`;

export const Institution = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
`;

export const Contact = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
`;

export const Address = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
`;

export const ButtonSaveInstitution = styled(Button)`
  width: 15rem;
  margin: 4rem 0;
`;

export const BodyModalConsent = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.small};
    gap: 1rem;
    display: flex;
    justify-content: flex-end;
  `}
`;

export const Ul = styled.ul`
  margin-left: 2rem;
`;
