import { MinTableItemConsent } from '.';
import { ColumnProps } from '../../components/Table';
import ActionTableConsent from './ActionTableConsent';
import BadgeTableItem from './BadgeTableItem';
import BadgeTableRequiresSubscription from './BadgeTableRequiresSubscription';
import TableItemOrganizationDisplay from './TableItemOrganizationDisplay';

export const colsTableConsent: ColumnProps<MinTableItemConsent>[] = [
  {
    title: 'Instituição',
    fieldName: 'pixeonId',
    width: '15rem',
    customRender: item => <TableItemOrganizationDisplay item={item} />,
  },
  {
    title: 'Versão',
    fieldName: 'versao',
    width: '8rem',
  },
  {
    title: 'Tipo',
    fieldName: 'tipoTermo',
    width: '15rem',
    customRender: item => <BadgeTableItem item={item} />,
  },
  {
    title: 'Nome',
    fieldName: 'nome',
  },
  {
    title: 'Criado Por',
    fieldName: 'criadoPor',
    width: '25rem',
  },
  {
    title: 'Criado Em',
    fieldName: 'dataCriacao',
    width: '15rem',
  },
  {
    title: 'Exige Assinatura?',
    fieldName: 'exigeAssinatura',
    width: '5rem',
    customRender: item => <BadgeTableRequiresSubscription item={item} />,
  },
  {
    title: '',
    fieldName: '',
    width: '21rem',
    customRender: item => <ActionTableConsent item={item} />,
  },
];
