import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 500;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;

  background: white;
  margin: auto;
  padding: 16px;
  border-radius: 8px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0.3rem;
`;

export const CloseButton = styled.div`
  svg {
    width: 2rem;
  }

  &:hover {
    cursor: pointer;
  }
`;

export type ModalProps = {
  maxHeight?: string;
};

export const ModalContent = styled.div<ModalProps>`
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
`;
