import download from 'downloadjs';

import { getPixeonId } from '../../../hooks/auth';
import { Patient } from '../../../models/patient';
import { SearchPatient } from '../../../pages/EmitReport';
import paramsSerializer from '../../../utils/params-serializer';
import { regularString } from '../../../utils/string-utils';
import api from '../../api';

export type SelectedPatient = {
  originalId: string;
  application: string;
};

export type SearchPatientFilters = {
  searchValue?: string;
  patientMotherName?: string;
  patientBirthDate?: string;
  application?: string;
};

export type SimpleReportBody = {
  patientName: string;
  patientPreferredName?: string;
  searchPatientFilters?: SearchPatientFilters;
  selectedApplications: SelectedPatient[];
};

const toSimpleReportBody = (
  patients: Patient[],
  filters: SearchPatient,
): SimpleReportBody => {
  const searchPatientFilters = {
    searchValue: filters.q,
    patientBirthDate: filters.birthDate,
    patientMotherName: filters.motherName,
    application: filters.application,
  };

  if (!patients?.length) {
    return {
      patientName: filters.q,
      searchPatientFilters,
      selectedApplications: [],
    };
  }

  const selectedApplications = patients.reduce<SelectedPatient[]>(
    (noDuplicates, patient) => {
      const applicationExists = noDuplicates.find(
        p => p.application === patient.application,
      );

      if (!applicationExists) {
        noDuplicates.push({
          application: patient.application,
          originalId: patient.originalId,
        });
      }

      return noDuplicates;
    },
    [],
  );

  return {
    patientName: patients[0].name || 'Não Informado',
    patientPreferredName: patients[0].preferredName,
    searchPatientFilters,
    selectedApplications,
  };
};

const downloadSimpleReport = async (
  patients: Patient[],
  filters: SearchPatient,
): Promise<void> => {
  const body = toSimpleReportBody(patients, filters);

  const { data, headers } = await api.post('/report', body, {
    responseType: 'blob',
    params: { pixeonId: getPixeonId() },
    paramsSerializer,
  });

  const contentType = headers['content-type'];

  const patientName = regularString(body.patientName, { maxWords: 4 });
  const fileName = `relatorio_simplificado_${patientName}.pdf`;

  download(data, fileName, contentType);
};

const SimpleReport = {
  download: downloadSimpleReport,
};

export default SimpleReport;
