import { memo } from 'react';

import Avatar from '../Avatar';

import * as S from './styles';

export type ProfileMenuProps = {
  showNameInHeader?: boolean;
  avatarName: string;
  imgUrlAvatar?: string;
  textLogout?: string;
  onClickLogout?: () => void;
};

const ProfileMenu = ({
  showNameInHeader = false,
  avatarName,
  imgUrlAvatar,
  textLogout = 'Sair',
  onClickLogout,
}: ProfileMenuProps): JSX.Element => {
  const rootElement = (
    <>
      <Avatar name={avatarName} imgUrlAvatar={imgUrlAvatar} />
      {showNameInHeader && <S.FullName>{avatarName}</S.FullName>}
    </>
  );

  return (
    <S.Wrapper>
      <S.DropdownStyledCustom
        rootElement={rootElement}
        showShadowBackground={false}
        role="menu"
      >
        <S.UserDropDownAvatar role="menuitem">
          <Avatar name={avatarName} imgUrlAvatar={imgUrlAvatar} />
          <S.FullName>{avatarName}</S.FullName>
        </S.UserDropDownAvatar>
        <S.Logout role="menuitem" to="/" onClick={onClickLogout}>
          {textLogout}
        </S.Logout>
      </S.DropdownStyledCustom>
    </S.Wrapper>
  );
};

export default memo(ProfileMenu);
