import { Dispatch, SetStateAction, useRef, useState } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { getPixeonId } from 'hooks/auth';
import moment from 'moment';
import { FilterAlt } from 'styled-icons/boxicons-regular';

import { SearchHolderReport } from '.';
import DatePicker from '../../components/DatePicker';
import Form from '../../components/Form';
import Modal from '../../components/Modal';
import TextField from '../../components/TextField';
import { Loading, Pageable } from '../../hooks/use-pagination/types';
import { Holder, HOLDER_REPORT_TYPE } from '../../models/holder';
import { handleErrorApiHub, apiHubLGPD } from '../../services/api';
import HolderReport from '../../services/Report/Holder';
import { schemaDate } from '../../utils/validations';

import * as S from './styles';

type HolderReportFormProps = {
  page: Pageable<Holder, SearchHolderReport>;
  loading: Loading;
  setMessage: Dispatch<SetStateAction<string | undefined>>;
};

const HolderReportForm = ({
  page,
  loading,
  setMessage,
}: HolderReportFormProps): JSX.Element => {
  const [showFilters, setShowFilters] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [showRequestNewReport, setShowRequestNewReport] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [document, setDocument] = useState<string>();
  const [holder, setHolder] = useState<Holder | undefined>();
  const formRef = useRef<FormHandles>(null);

  const handleToggleRequestNewReport = (): void =>
    setShowRequestNewReport(!showRequestNewReport);

  const handleToggleSuccess = (): void => setShowSuccess(!showSuccess);

  const emitHolderReport = async (): Promise<void> => {
    setHolder(undefined);
    try {
      setLoadingDownload(true);
      const response = await apiHubLGPD.get<Holder>(
        `/Relatorio/${getPixeonId()}/titular/${document}`,
      );
      setHolder(response.data);
      handleToggleSuccess();
    } catch (error) {
      setMessage(handleErrorApiHub(error));
    } finally {
      handleToggleRequestNewReport();
      setLoadingDownload(false);
    }
  };

  const successNewReport = async (): Promise<void> => {
    try {
      if (holder) {
        await HolderReport.download(holder);
      } else {
        setMessage('Ocorreu erro no download do arquivo');
      }
      await page.fetch({
        documento: document,
        tipoRelatorio: HOLDER_REPORT_TYPE,
      });
    } catch (error) {
      setMessage(handleErrorApiHub(error));
    } finally {
      handleToggleSuccess();
      setHolder(undefined);
    }
  };

  const handleSubmit: SubmitHandler<SearchHolderReport> = filters => {
    setMessage(undefined);
    const data = filters.data
      ? moment(filters.data, 'DD/MM/yyyy').format('yyyy-MM-DD')
      : undefined;
    page.fetch({ ...filters, data, tipoRelatorio: HOLDER_REPORT_TYPE });
    if (document) handleToggleRequestNewReport();
  };

  return (
    <Form
      formRef={formRef}
      onSubmit={handleSubmit}
      schema={schemaDate('data', 'Data Inválida')}
      cleanErrors={false}
    >
      <S.SearchGroup>
        <TextField
          placeholder="Documento"
          labelFor="documento"
          maxLength={80}
          onBlur={e => setDocument(e.target.value)}
        />
        <S.Button
          size="large"
          icon={<FilterAlt size={50} />}
          outlined
          onClick={() => setShowFilters(prev => !prev)}
          aria-label="filtros"
        />
        <S.ButtonHolderReport
          size="large"
          type="submit"
          disabled={!!loading}
          loading={loading === 'submit'}
        >
          Buscar
        </S.ButtonHolderReport>
      </S.SearchGroup>
      <S.FilterGroup show={showFilters}>
        <DatePicker label="Data" labelFor="data" />
      </S.FilterGroup>

      <Modal
        isShow={showRequestNewReport}
        toggle={handleToggleRequestNewReport}
        showCloseButton={false}
        closeOutsideClick={false}
      >
        <h3>Deseja gerar um novo relatório do titular?</h3>

        <S.BodyModalHolderReport>
          <S.Button
            color="secondary"
            onClick={emitHolderReport}
            loading={loadingDownload}
          >
            Sim
          </S.Button>
          <S.Button color="error" onClick={handleToggleRequestNewReport}>
            Não
          </S.Button>
        </S.BodyModalHolderReport>
      </Modal>

      <Modal
        isShow={showSuccess}
        toggle={handleToggleSuccess}
        showCloseButton={false}
        closeOutsideClick={false}
      >
        <h3>Relatório gerado com sucesso.</h3>

        <S.BodyModalHolderReport>
          <S.Button
            color="secondary"
            onClick={successNewReport}
            loading={loadingDownload}
          >
            OK
          </S.Button>
        </S.BodyModalHolderReport>
      </Modal>
    </Form>
  );
};
export default HolderReportForm;
