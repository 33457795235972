import styled, { css } from 'styled-components';

import DefaultAlert from '../../../components/Alert';

export type Visibility = {
  visible?: boolean;
};

export const Container = styled.div<Visibility>`
  ${({ visible }) => css`
    max-width: 64rem;
    visibility: ${visible ? 'visible' : 'hidden'};
  `}
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Overlay = styled.div<Visibility>`
  ${({ visible }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    align-items: center;
    justify-content: center;
    display: ${visible ? 'flex' : 'none'};
    background-color: transparent;
  `}
`;

export const Alert = styled(DefaultAlert)`
  ${({ theme }) => css`
    margin: ${theme.spacings.small} 0rem;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.medium};
    margin-bottom: ${theme.spacings.large};
  `}
`;

export const Section = styled.section`
  ${({ theme }) => css`
    h2 {
      margin-bottom: ${theme.spacings.xsmall};
    }
  `}
`;

export const ButtonGroup = styled.h2`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacings.small};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xsmall};
    color: ${theme.colors.error};
  `}
`;

export const Paragraph = styled.p`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} 0rem;
    line-height: ${theme.spacings.small};
  `}
`;

export const Footer = styled.h2`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacings.xsmall};
  `}
`;
