import { AnchorHTMLAttributes, ButtonHTMLAttributes, useMemo } from 'react';

import { Link, LinkProps } from 'react-router-dom';

import Spinner from '../Spinner';

import * as S from './styles';

export type Colors = 'primary' | 'secondary' | 'error' | 'darkSecondary';

export type ButtonProps = {
  size?: 'small' | 'medium' | 'large';
  icon?: JSX.Element;
  fullWidth?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'darkSecondary';
  outlined?: boolean;
  boldFont?: boolean;
  to?: LinkProps['to'];
  disabled?: boolean;
  loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  Omit<LinkProps, 'to'>;

const Button = ({
  children,
  size = 'medium',
  icon,
  outlined = false,
  boldFont = true,
  color = 'primary',
  to,
  type = 'button',
  disabled = false,
  loading = false,
  ...props
}: ButtonProps): JSX.Element => {
  const behavior = { as: to ? Link : 'button' };

  const spinnerColor = useMemo(() => (outlined ? color : 'white'), [
    color,
    outlined,
  ]);

  return (
    <S.Wrapper
      {...behavior}
      to={to}
      size={size}
      $hasIcon={!!icon}
      color={color}
      disabled={disabled || !!loading}
      $outlined={outlined}
      $boldFont={boldFont}
      type={to ? undefined : type}
      {...props}
    >
      {loading ? <Spinner color={spinnerColor} size={22} /> : icon}
      {!loading && !!children && <span>{children}</span>}
    </S.Wrapper>
  );
};

export default Button;
