import { useEffect, useRef, useState } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { AxiosError } from 'axios';
import { Email } from 'styled-icons/material-outlined';

import Select, { SelectTypingItems } from '../../components/Select';
import TextField from '../../components/TextField';
import Upload from '../../components/Upload';
import { UserOrganization } from '../../hooks/auth';
import { getPixeonId } from '../../hooks/auth';
import useToggle from '../../hooks/use-toggle';
import { Institution } from '../../models/institution';
import { apiHubLGPD } from '../../services/api';
import ModalSuccess from './ModalSuccess';

import * as S from './styles';

type ErrorsList = {
  [key: string]: string[];
};

type ErrorResponse = {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors?: ErrorsList;
};

const InstitutionEdit = (): JSX.Element => {
  const formRef = useRef<FormHandles>(null);
  const { isShow, toggle } = useToggle();

  const [institutionMessage, setMessage] = useState<string[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [institution, setInstitution] = useState<Institution | null>(null);

  const GetInstitutionAsync = async (pixeonId: string): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await apiHubLGPD.get<Institution>(
        `/Instituicao/${String(pixeonId)}`,
      );
      setInstitution(data);
    } catch (err) {
      const { message } = JSON.parse(err.request.response);
      setMessage([message]);
    } finally {
      setLoading(false);
    }
  };

  const changeInstitutionHandle = async (
    item: SelectTypingItems | SelectTypingItems[],
  ): Promise<void> => {
    await GetInstitutionAsync(String((item as SelectTypingItems).value));
  };

  const fillOrganizations = (): SelectTypingItems[] => {
    const organizationFromLocalStorage = localStorage.getItem(
      '@PixeonLGPD:organization',
    );

    const institutions: UserOrganization[] = JSON.parse(
      String(organizationFromLocalStorage),
    );

    return institutions?.map(o => {
      return {
        value: o.pixeonId,
        text: o.organizationName,
      };
    });
  };

  useEffect(() => {
    const load = async (): Promise<void> => {
      const pixeonId =
        getPixeonId().length === 1 ? getPixeonId()[0] : institution?.pixeonId;
      await GetInstitutionAsync(String(pixeonId));
    };

    if (!institution?.id) load();
  }, [institution]);

  const onCloseAlert = (): void => {
    setMessage(undefined);
  };

  const handleError = (err: AxiosError<ErrorResponse>): void => {
    let errorsList: string[] = [];
    if (err.response) {
      const { data } = err.response;

      if (data.errors) {
        const list = Object.values(data.errors).map(listError =>
          listError.map(v => v),
        );

        errorsList = list.reduce((prev, next) => {
          return prev.concat(next);
        });
      }
    }

    setMessage(
      errorsList.length ? errorsList : ['Ocorreu um erro desconhecido.'],
    );
  };

  const handleSubmit: SubmitHandler<Institution & { file: FormData }> = async ({
    file,
    ...data
  }) => {
    setMessage(undefined);
    setLoading(true);
    try {
      let nameLogo = institution?.logo;
      if (file) {
        nameLogo = (file.get('file') as File).name;

        await apiHubLGPD.post(
          `/Instituicao/upload-logo/${institution?.id}`,
          file,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
      }

      await apiHubLGPD.put(`/Instituicao/${institution?.id}`, {
        ...data,
        logo: nameLogo,
        pixeonId: institution?.pixeonId,
      });

      toggle();
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <S.InstitutionAlert closeable severity="error" onClose={onCloseAlert}>
        {institutionMessage && (
          <S.Ul>
            {institutionMessage.map(message => (
              <li key={message}>{message}</li>
            ))}
          </S.Ul>
        )}
      </S.InstitutionAlert>

      <S.InstitutionForm formRef={formRef} onSubmit={handleSubmit}>
        <S.Institution>
          {getPixeonId().length > 1 && (
            <S.HalfScreen>
              <Select
                labelFor="pixeonId"
                label="Selecione a instituição"
                initialValues={fillOrganizations()}
                onChange={changeInstitutionHandle}
              />
            </S.HalfScreen>
          )}

          <S.FullScreen>
            <TextField
              label="Nome da Instituição"
              placeholder="Nome da Instituição"
              initialValue={institution?.nome}
              labelFor="nome"
            />
          </S.FullScreen>

          <S.HalfScreen>
            <TextField
              label="CNPJ da Instituição"
              placeholder="CNPJ da Instituição"
              initialValue={institution?.documento}
              labelFor="documento"
              mask="99.999.999/9999-99"
            />
          </S.HalfScreen>

          <S.HalfScreen>
            <TextField
              label="Nome do DPO"
              placeholder="Nome do DPO"
              initialValue={institution?.dpo}
              labelFor="dpo"
            />
          </S.HalfScreen>
        </S.Institution>

        <S.Contact>
          <S.H1>Contato</S.H1>

          <S.HalfScreen>
            <TextField
              icon={<Email />}
              label="E-mail"
              placeholder="E-mail"
              initialValue={institution?.email}
              labelFor="email"
            />
          </S.HalfScreen>

          <S.HalfScreen>
            <TextField
              label="Telefone"
              placeholder="Telefone"
              initialValue={institution?.telefone}
              labelFor="telefone"
              mask="(99) 9999-9999"
            />
          </S.HalfScreen>
        </S.Contact>

        <S.Address>
          <S.H1>Endereço</S.H1>

          <S.HalfScreen>
            <TextField
              label="Logradouro"
              placeholder="Logradouro"
              initialValue={institution?.endereco?.logradouro}
              labelFor="endereco.logradouro"
            />
          </S.HalfScreen>

          <S.HalfScreen>
            <TextField
              label="Cidade"
              placeholder="Cidade"
              initialValue={institution?.endereco?.cidade}
              labelFor="endereco.cidade"
            />
          </S.HalfScreen>

          <S.HalfScreen>
            <TextField
              label="Estado"
              placeholder="Estado"
              initialValue={institution?.endereco?.estado}
              labelFor="endereco.estado"
            />
          </S.HalfScreen>

          <S.HalfScreen>
            <TextField
              label="CEP"
              placeholder="CEP"
              initialValue={institution?.endereco?.cep}
              mask="99999-999"
              labelFor="endereco.cep"
            />
          </S.HalfScreen>
        </S.Address>

        <Upload
          name="file"
          title="file"
          labelFor="file"
          label="Logo"
          placeholder={institution?.logo}
          showNameFile
        />

        <S.FullScreen>
          <S.ButtonSaveInstitution
            loading={loading}
            type="submit"
            disabled={getPixeonId().length < 1}
          >
            Salvar
          </S.ButtonSaveInstitution>
        </S.FullScreen>

        <ModalSuccess isShow={isShow} toggle={toggle} />
      </S.InstitutionForm>
    </>
  );
};

export default InstitutionEdit;
