import styled, { css } from 'styled-components';

export const Dot = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: ${theme.colors.darkPrimary};
    color: ${theme.colors.darkPrimary};
    animation: dotFlashing 0.6s infinite linear alternate;
    animation-delay: 0.3s;

    &:before,
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    &:before {
      left: -1.5rem;
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      background-color: ${theme.colors.darkPrimary};
      color: ${theme.colors.darkPrimary};
      animation: dotFlashing 0.6s infinite alternate;
      animation-delay: 0s;
    }

    &:after {
      left: 1.5rem;
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      background-color: ${theme.colors.darkPrimary};
      color: ${theme.colors.darkPrimary};
      animation: dotFlashing 0.6s infinite alternate;
      animation-delay: 0.6s;
    }

    @keyframes dotFlashing {
      0% {
        background-color: ${theme.colors.darkPrimary};
      }
      50%,
      100% {
        background-color: ${theme.colors.white};
      }
    }
  `}
`;
