import { useHistory } from 'react-router-dom';
import { Check2Circle as CheckIcon } from 'styled-icons/bootstrap/';

import Button from '../../../../components/Button';
import { Response } from '../../../../models/response';

import * as S from '../styles';

type SuccessProps = {
  response?: Response;
};

const Success = ({ response }: SuccessProps): JSX.Element => {
  const history = useHistory();

  const onConfirm = (): void => {
    history.push('/emit-report');
  };

  return response && !response?.error ? (
    <S.Wrapper>
      <S.Title color="secondary">
        <CheckIcon size={30} />
        <h2>Solicitação Concluída</h2>
      </S.Title>

      <S.Paragraph>
        Agora você pode acompanhar o status da operação e realizar o download no
        menu <strong>Relatórios Emitidos.</strong>
      </S.Paragraph>

      <S.Paragraph>
        Após clicar em <strong>OK</strong> você será redirecionado para a página
        principal.
      </S.Paragraph>

      <S.Footer>
        <Button color="secondary" size="large" onClick={onConfirm}>
          OK
        </Button>
      </S.Footer>
    </S.Wrapper>
  ) : (
    <></>
  );
};

export default Success;
