const theme = {
  grid: {
    container: '130rem',
    gutter: '3.2rem',
  },
  border: {
    radius: '0.4rem',
  },
  font: {
    headings: {
      family:
        "Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      normal: 300,
      sizes: {
        xsmall: '2.1rem',
        small: '2.8rem',
        medium: '3.6rem',
        large: '5.0rem',
        xlarge: '6.7rem',
        xxlarge: '8.9rem',
      },
    },
    paragraphs: {
      family:
        "Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      normal: 400,
      bold: 600,
      sizes: {
        xsmall: '1.2rem',
        small: '1.4rem',
        medium: '1.6rem',
        large: '1.8rem',
        xlarge: '2.0rem',
      },
    },
  },
  colors: {
    lightPrimary: '#7A6BCD',
    primary: '#604ccd',
    darkPrimary: '#4735A6',
    lightSecondary: '#66D4BF',
    secondary: '#22BC9F',
    darkSecondary: '#14967E',
    lightWhite: '#FBFCFF',
    white: '#F4F7F9',
    darkWhite: '#EAEDF1',
    lightBlack: '#777C83',
    black: '#53575D',
    darkBlack: '#292B2F',
    darkestBlack: '#09090a',
    lightGray: '#C9C9CA',
    gray: '#A8A9AC',
    darkGray: '#94969A',
    success: '#B2F4A2',
    warning: '#FFF3B3',
    error: '#FF7788',
    info: '#9BEDFF',
    badge: '#E7ECF2',
  } as const,
  spacings: {
    xxxsmall: '0.4rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
    xxxlarge: '6.8rem',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out',
  },
};

export default theme;

export type ThemeColors = keyof typeof theme.colors;
