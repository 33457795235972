import { useHistory } from 'react-router-dom';
import { Search } from 'styled-icons/boxicons-regular';

import { ConsentFormRoute } from '.';
import Button from '../../components/Button';
import { Consent } from '../../models/consent';

import * as S from './styles';

export type ActionTableConsentProps = {
  item: Consent;
};

const ActionTableConsent = ({ item }: ActionTableConsentProps): JSX.Element => {
  const history = useHistory<ConsentFormRoute>();

  const edit = (data: Consent): void => {
    history.push('/terms-form/form', { data, operation: 'edit' });
  };

  const visualize = (data: Consent): void => {
    history.push('/terms-form/form', { data, operation: 'view' });
  };

  return (
    <>
      <S.Action>
        <Button
          onClick={() => visualize(item)}
          icon={<Search />}
          color="secondary"
        />
        <Button
          onClick={() => edit(item)}
          disabled={!item.ultimaVersao}
          color="primary"
        >
          Editar
        </Button>
      </S.Action>
    </>
  );
};

export default ActionTableConsent;
