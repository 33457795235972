import download from 'downloadjs';

import { Holder } from '../../../models/holder';
import { apiHubLGPD } from '../../api';

const downloadHolderReport = async (item: Holder): Promise<void> => {
  const { data, headers } = await apiHubLGPD.get(
    `/Relatorio/${item.id}/download`,
    {
      responseType: 'blob',
    },
  );
  const fileName = `relatorio_titular_${item.documentoTitular}.pdf`;
  !!data && download(data, fileName, headers['content-type']);
};

const HolderReport = {
  download: downloadHolderReport,
};

export default HolderReport;
