import { useMemo, useEffect, useReducer, useCallback } from 'react';

import reducer from './reducer';
import { Arguments, ActionType, DefaultItem, UseSelectedItems } from './types';

function useSelectedItems<Item extends DefaultItem>({
  initialItems,
  itemIdentifierKey,
  initialSelectedItems = [],
}: Arguments<Item>): UseSelectedItems<Item> {
  const [{ items }, dispatch] = useReducer(reducer<Item>(), {
    items: [],
    itemIdentifierKey,
  });

  const toggleSingleItem = useCallback(
    (item: Item) => {
      dispatch({
        type: ActionType.TOGGLE_SINGLE_ITEM,
        payload: {
          itemIdentifierValue: item[itemIdentifierKey],
        },
      });
    },
    [itemIdentifierKey],
  );

  const toggleAllItem = useCallback((selectAll?: boolean) => {
    dispatch({
      type: ActionType.TOGGLE_ALL_ITEMS,
      payload: { selectAll },
    });
  }, []);

  useEffect(() => {
    const shouldInitializeItems = initialItems.length > 0;
    const hasItems = items.length > 0;

    if (!shouldInitializeItems || hasItems) {
      return;
    }

    dispatch({
      type: ActionType.INITIALIZE_ITEMS,
      payload: {
        initialItems,
        initialSelectedItems,
      },
    });
  }, [items, initialItems, initialSelectedItems]);

  useEffect(() => {
    const hasValidIdentifier = initialItems.every(item =>
      Object.prototype.hasOwnProperty.call(item, itemIdentifierKey),
    );

    const hasInitialItems = initialItems.length > 0;

    if (hasInitialItems && !hasValidIdentifier) {
      throw new Error(
        'Please, make sure to provide a valid identifier key to all items',
      );
    }
  }, [initialItems, itemIdentifierKey]);

  const selectedItems = useMemo(
    () =>
      items
        .filter(item => item.isSelected)
        .map(({ isSelected: _isSelected, ...rest }) => ({
          ...rest,
        })),
    [items],
  ) as Array<Item>;

  const payload = useMemo(
    () => ({
      items,
      selectedItems,
      toggleSingleItem,
      toggleAllItem,
    }),
    [items, selectedItems, toggleSingleItem, toggleAllItem],
  );

  return payload;
}

export default useSelectedItems;
