/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { cleanStorage } from '../hooks/auth';
import env from './env';

export type DataErrorLgpd = {
  message: string;
};

export type ErrorHubLgpd = {
  status: number;
  data: DataErrorLgpd;
};
export type ErrorResponseLgpd = {
  response: ErrorHubLgpd;
  code: string;
};

export type Error = {
  status?: number;
  data?: Record<string, unknown>;
  message?: string;
};

export type HttpResponse<DataType> = {
  data?: DataType;
  error?: Error;
};

export type ErrorResponse = {
  response: Error;
  code: string;
};

export const handleErrorResponse = (err: ErrorResponse): string => {
  if (!err.response || err.code === 'ECONNABORTED') {
    return 'O servidor parece estar indisponível. Verifique sua conexão.';
  }

  const { status } = err.response;

  switch (status) {
    case 401:
      return 'E-mail ou senha inválidos.';

    default:
      return 'Ocorreu um erro no servidor.';
  }
};

const skip = ['/login'];

const requestHandler = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const newConfig = config;
  if (!skip.includes(config?.url || '') && newConfig.headers) {
    const token = localStorage.getItem('@PixeonLGPD:token');
    newConfig.headers.Authorization = `Bearer ${token}`;
  }

  return newConfig;
};

const forbidden = [401, 403];

const responseErrorHandler = (error: AxiosError): Promise<AxiosError> => {
  const isUnauthorized =
    error.response && forbidden.includes(error.response.status);

  if (window.location.pathname !== '/' && isUnauthorized) {
    cleanStorage();
    window.location.href = '/';
  }

  return Promise.reject(error);
};

const api = axios.create({
  baseURL: env.REACT_APP_TRACEABILITY_BFF_URL,
});

api.interceptors.request.use(requestHandler);

api.interceptors.response.use(
  response => response,
  error => responseErrorHandler(error),
);

export const apiHubLGPD = axios.create({
  baseURL: env.REACT_APP_HUB_LGPD_URL,
});

apiHubLGPD.interceptors.request.use(requestHandler);

apiHubLGPD.interceptors.response.use(
  response => response,
  error => responseErrorHandler(error),
);

export const handleErrorApiHub = (error: unknown): string => {
  let messageError = '';
  if (axios.isAxiosError(error)) {
    const { message } = JSON.parse(error.request.response);
    messageError = message;
  } else {
    messageError =
      'Ocorreu um erro desconhecido, favor entrar em contato com a equipe de suporte.';
  }
  return messageError;
};

export default api;
