import { Moment } from 'moment';
import 'moment/locale/pt-br';

import { MAX_DATE, MIN_DATE } from '../../../utils/date_utils';

export type YearRange = {
  value: number;
  isValid: boolean;
};

function buildYears(initialYear: number, lastYear: number): YearRange[] {
  const years = [];

  for (let year = initialYear; year <= lastYear; year += 1) {
    years.push({
      value: year,
      isValid: year >= MIN_DATE.year() && year <= MAX_DATE.year(),
    });
  }

  return years;
}

/**
 *  The YearPicker contains 12 cells, then was used 5 years before
 *  and 6 years after to keep the selected year centralized
 */
function getYearsRange(date: Moment): YearRange[] {
  const initialYear = date.year() - 5;
  const lastYear = date.year() + 6;

  return buildYears(initialYear, lastYear);
}

function nextYearsRange(years: YearRange[]): YearRange[] {
  const initialYear = years[years.length - 1].value + 1;
  const lastYear = initialYear + 11;

  return buildYears(initialYear, lastYear);
}

function previousYearsRange(years: YearRange[]): YearRange[] {
  const initialYear = years[0].value - 12;
  const lastYear = initialYear + 11;

  return buildYears(initialYear, lastYear);
}

function hasNextYearsRange(years: YearRange[]): boolean {
  const firstYearFromNextRange = years[years.length - 1].value + 1;
  return firstYearFromNextRange <= MAX_DATE.year();
}

function hasPreviousYearsRange(years: YearRange[]): boolean {
  const lastYearFromPreviousRange = years[0].value - 1;
  return lastYearFromPreviousRange >= MIN_DATE.year();
}

export const Years = {
  range: getYearsRange,
  next: nextYearsRange,
  previous: previousYearsRange,
  hasNext: hasNextYearsRange,
  hasPrevious: hasPreviousYearsRange,
};
