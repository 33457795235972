export function capitalizeFirstChar(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

const accentPatterns = {
  a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
  e: 'é|è|ê|ë|É|È|Ê|Ë',
  i: 'í|ì|î|ï|Í|Ì|Î|Ï',
  o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
  u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
  c: 'ç|Ç',
  n: 'ñ|Ñ',
};

export function stripAccents(value: string): string {
  let newText = value;

  for (const [char, pattern] of Object.entries(accentPatterns)) {
    newText = newText.replace(new RegExp(pattern, 'g'), char);
  }

  return newText;
}

export type CaseType = 'snake' | 'kebab';

const caseMap = {
  snake: '_',
  kebab: '-',
};

type RegularStringOptions = {
  caseType?: CaseType;
  maxWords?: number;
};

export function regularString(
  value: string,
  options: RegularStringOptions = {},
): string {
  return stripAccents(value)
    .split(/ /g)
    .slice(0, options.maxWords)
    .map(element => element.toLowerCase())
    .join(caseMap[options.caseType || 'snake']);
}
