import styled, { css } from 'styled-components';

export const ImageWrapper = styled.img`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    width: 6.3rem;
    border: 6px solid ${theme.colors.darkWhite};
    border-radius: 100%;
    max-width: 6.3rem;
  `}
`;

export const TextWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.darkPrimary};
    border: none;
    border-radius: 100%;
    font-size: ${theme.font.paragraphs.sizes.large};
    font-weight: bold;
    text-align: center;
    color: ${theme.colors.white};
    min-height: 5rem;
    line-height: 5rem;
    width: 5rem;
    height: 5rem;
    user-select: none;
    outline: none;
  `}
`;
