import { darken } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

import { ButtonProps } from '.';

export type WrapperProps = {
  $hasIcon: boolean;
  $boldFont: boolean;
  $outlined: boolean;
  disabled: boolean;
} & Pick<ButtonProps, 'size' | 'color' | 'outlined' | 'disabled'>;

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    height: 3rem;
    font-size: ${theme.font.paragraphs.sizes.xsmall};
  `,
  medium: (theme: DefaultTheme) => css`
    height: 4rem;
    font-size: ${theme.font.paragraphs.sizes.small};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.medium};
  `,
  large: (theme: DefaultTheme) => css`
    height: 5rem;
    font-size: ${theme.font.paragraphs.sizes.medium};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xlarge};
  `,

  primary: (theme: DefaultTheme) => css`
    background: ${theme.colors.primary};

    &:not(&:disabled):hover {
      background: ${theme.colors.darkPrimary};
    }
  `,
  secondary: (theme: DefaultTheme) => css`
    background: ${theme.colors.secondary};

    &:not(&:disabled):hover {
      background: ${theme.colors.darkSecondary};
    }
  `,
  error: (theme: DefaultTheme) => css`
    background: ${theme.colors.error};

    &:not(&:disabled):hover {
      background: ${darken(0.1, theme.colors.error)};
    }
  `,
  darkSecondary: (theme: DefaultTheme) => css`
    background: ${theme.colors.darkSecondary};

    &:not(&:disabled):hover {
      background: ${darken(0.1, theme.colors.darkSecondary)};
    }
  `,
  withIcon: (theme: DefaultTheme) => css`
    svg {
      width: 2rem;
      & + span {
        margin-left: ${theme.spacings.xxsmall};
      }
    }
  `,
  outlined: (
    theme: DefaultTheme,
    color: 'primary' | 'secondary' | 'error' | 'darkSecondary',
  ) => css`
    background: none;
    border: 2px solid ${theme.colors[color]};
    color: ${theme.colors[color]};
    box-shadow: none;

    &:not(&:disabled):hover {
      background: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  `,
  disabled: () => css`
    cursor: not-allowed;
    opacity: 0.8;
  `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({ theme, size, $hasIcon, color, $outlined, $boldFont, disabled }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.lightWhite};
    border: 0;
    cursor: pointer;
    border-radius: ${theme.border.radius};
    padding: ${theme.spacings.xxsmall};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    text-align: center;
    font-family: ${theme.font.paragraphs.family};
    font-weight: ${$boldFont === true ? '700' : '300'};

    ${!!size && wrapperModifiers[size](theme)}
    ${!!$hasIcon && wrapperModifiers.withIcon(theme)}
    ${!!color && wrapperModifiers[color](theme)}
    ${$outlined && !!color && wrapperModifiers.outlined(theme, color)}
    ${disabled && wrapperModifiers.disabled()}
  `}
`;
