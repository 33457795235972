import { ComponentType, useState } from 'react';

import Pagination from '../../components/Pagination';
import { ColumnProps, MinTableItem } from '../../components/Table';
import usePagination from '../../hooks/use-pagination';
import { Holder, HOLDER_REPORT_TYPE } from '../../models/holder';
import { apiHubLGPD } from '../../services/api';
import HolderReportAction from './HolderReportAction';
import HolderReportForm from './HolderReportForm';

import * as S from './styles';

export type SearchHolderReport = {
  documento?: string;
  data?: string;
  tipoRelatorio?: string;
};

type MinTableItemHolderReport = Holder & MinTableItem;

const HolderReportIndex = (): JSX.Element => {
  const [message, setMessage] = useState<string>();
  const { loading, page } = usePagination<Holder, SearchHolderReport>({
    endpoint: '/Relatorio',
    autoLoading: { tipoRelatorio: HOLDER_REPORT_TYPE },
    apiPage: apiHubLGPD,
  });

  const onCloseAlert = (): void => {
    setMessage(undefined);
  };

  const cols: ColumnProps<MinTableItemHolderReport>[] = [
    { title: 'Gerado em', fieldName: 'dataCriacao', width: '20rem' },
    { title: 'Gerado por', fieldName: 'usuarioCriacao', width: '20rem' },
    { title: 'Documento', fieldName: 'documentoTitular', width: '40rem' },
    {
      title: '',
      fieldName: '',
      width: '5rem',
      customRender: item => (
        <HolderReportAction item={item} setMessage={setMessage} />
      ),
    },
  ];

  return (
    <S.Wrapper>
      <h1>Relatório Titular</h1>

      <HolderReportForm loading={loading} page={page} setMessage={setMessage} />

      <S.HolderReportAlert severity="error" onClose={onCloseAlert} closeable>
        {message}
      </S.HolderReportAlert>

      {page?.empty && !message && (
        <S.NoDataMessage>Não encontramos nenhum registro.</S.NoDataMessage>
      )}

      {page?.empty === false && (
        <>
          <S.TableHolderReport<ComponentType<MinTableItemHolderReport>>
            cols={cols}
            items={page.content}
            loading={!!loading}
          />
          <Pagination
            currentPage={page.number}
            numPages={page.totalPages}
            isFirst={page.first}
            isLast={page.last}
            nextPage={page.next}
            prevPage={page.previous}
            pageItems={page.numberOfElements}
            totalItems={page.totalElements}
            loading={!!loading}
          />
        </>
      )}
    </S.Wrapper>
  );
};

export default HolderReportIndex;
