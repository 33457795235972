import { useState } from 'react';

import { SearchPatient } from '..';

import Modal from '../../../components/Modal';
import Spinner from '../../../components/Spinner';
import { Patient } from '../../../models/patient';
import SimpleReport from '../../../services/Report/Simple';
import Error from './Error';
import Options from './Options';

import * as S from './styles';

type ChooseReportProps = {
  selectedPatients: Patient[];
  params: SearchPatient;
  show: boolean;
  toggle: () => void;
};

type ChooseReportState = {
  loading: boolean;
  error?: boolean;
};

const ChooseReport = ({
  selectedPatients,
  params,
  show,
  toggle,
}: ChooseReportProps): JSX.Element => {
  const [state, setState] = useState<ChooseReportState>({
    loading: false,
  });

  const onComplete = ({ loading, error }: ChooseReportState): void => {
    setState({ loading, error });
    error !== true && toggle();
  };

  const downloadSimpleReport = (): void => {
    setState({ loading: true });

    SimpleReport.download(selectedPatients, params)
      .then(() => onComplete({ loading: false, error: false }))
      .catch(() => onComplete({ loading: false, error: true }));
  };

  const reset = (): void => {
    onComplete({ loading: false });
  };

  return (
    <Modal
      isShow={show}
      toggle={toggle}
      showCloseButton={!state.loading}
      closeOutsideClick={!state.loading}
    >
      <S.Wrapper>
        <S.Overlay
          visible={state.loading}
          role="status"
          aria-label="Carregando"
        >
          <Spinner />
        </S.Overlay>

        <S.Container visible={!state.loading}>
          <Error error={state.error} reset={reset} />

          <Options
            error={state.error}
            selectedPatients={selectedPatients}
            downloadSimpleReport={downloadSimpleReport}
          />
        </S.Container>
      </S.Wrapper>
    </Modal>
  );
};

export default ChooseReport;
