import * as S from './styles';

export type PaginationProps = {
  isFirst: boolean;
  isLast: boolean;
  pageItems: number;
  totalItems: number;
  currentPage: number;
  numPages: number;
  loading?: boolean;
  prevPage?: () => void;
  nextPage?: () => void;
};

const Pagination = ({
  isFirst,
  isLast,
  pageItems,
  totalItems,
  currentPage,
  numPages,
  prevPage,
  nextPage,
  loading = false,
}: PaginationProps): JSX.Element => {
  const handleNextPage = (): void => {
    !loading && !isLast && !!nextPage && nextPage();
  };

  const handlePrevPage = (): void => {
    !loading && !isFirst && !!prevPage && prevPage();
  };

  return (
    <S.Container>
      <S.Span>
        Exibindo {pageItems} de {totalItems} registros
      </S.Span>
      <S.Navigation>
        <S.Previous onClick={handlePrevPage} disabled={loading || isFirst} />
        <S.Content>
          página {currentPage} de {numPages}
        </S.Content>
        <S.Next onClick={handleNextPage} disabled={loading || isLast} />
      </S.Navigation>
    </S.Container>
  );
};

export default Pagination;
