import { HTMLAttributes, useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { MenuItem } from '.';

import * as S from './styles';

type MenuProps = {
  menuItem: MenuItem;
  level: number;
} & HTMLAttributes<HTMLDivElement>;

export const Menu = ({ menuItem, level }: MenuProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);

  return menuItem.items ? (
    <S.Container role="menuitem">
      <S.Label htmlFor={menuItem.label} level={level}>
        {!!menuItem.icon && menuItem.icon}
        {menuItem.label}
      </S.Label>
      <S.Switch id={menuItem.label} />
      <S.Arrow />

      <S.ItemGroup>
        {menuItem.items.map(item => (
          <S.Item key={item.label}>
            <Menu menuItem={item} level={level + 1} role="menu" />
          </S.Item>
        ))}
      </S.ItemGroup>
    </S.Container>
  ) : (
    <S.Link
      level={level}
      to={menuItem.linkTo || '/#'}
      role="menuitem"
      activeStyle={{
        background: themeContext.colors.lightPrimary,
        color: 'white',
      }}
    >
      {!!menuItem.icon && menuItem.icon}
      {menuItem.label}
    </S.Link>
  );
};
