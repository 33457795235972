import { PresenceAvailable } from 'styled-icons/fluentui-system-filled';
import { PresenceBlocked } from 'styled-icons/fluentui-system-regular';

import { Consent } from '../../models/consent';

import * as S from './styles';

export type TermProps = {
  item: Consent;
};

const BadgeTableRequiresSubscription = ({ item }: TermProps): JSX.Element => {
  return item.exigeAssinatura.toLocaleUpperCase() === 'SIM' ? (
    <S.CustomBadge>
      <PresenceAvailable alignmentBaseline="middle" size="16" />
      {item.exigeAssinatura}
    </S.CustomBadge>
  ) : (
    <S.CustomBadge>
      <PresenceBlocked alignmentBaseline="middle" size="16" />
      {item.exigeAssinatura}
    </S.CustomBadge>
  );
};

export default BadgeTableRequiresSubscription;
