import { Dispatch, SetStateAction, useState } from 'react';

import { useHistory } from 'react-router-dom';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import useToggle from '../../../hooks/use-toggle';
import { Consent } from '../../../models/consent';
import { apiHubLGPD } from '../../../services/api';

import * as S from './styles';

type ModalConfirmRemoveProps = {
  item: Consent;
  setMessage: Dispatch<SetStateAction<string | undefined>>;
  operation: 'new' | 'view' | 'edit' | 'newScienceTerm';
  visible: boolean;
  termType: string;
};

const ModalConfirmRemove = ({
  item,
  setMessage,
  operation,
  visible,
  termType,
}: ModalConfirmRemoveProps): JSX.Element => {
  const history = useHistory();

  const { isShow, toggle } = useToggle();
  const { isShow: isShowAfterRemove, toggle: toggleAfterRemove } = useToggle();

  const [loading, setLoading] = useState(false);

  const remove = async (): Promise<void> => {
    setLoading(true);
    try {
      await apiHubLGPD.delete(`/TermoConsentimento/${item?.id}`);
      toggleAfterRemove();
    } catch (error) {
      const { message } = JSON.parse(error.request.response);
      setMessage(message);
    } finally {
      setLoading(true);
      toggle();
    }
  };

  const handleClickButtonAfterRemove = (): void => {
    toggleAfterRemove();
    history.push('/terms-form');
  };

  return (
    <>
      {visible && operation !== 'new' && (
        <Button color="error" onClick={toggle}>
          Remover
        </Button>
      )}

      <Modal
        isShow={isShow}
        toggle={toggle}
        showCloseButton={false}
        closeOutsideClick={false}
      >
        <h3>Deseja remover o termo de {termType}?</h3>

        <S.BodyModalConsent>
          <Button onClick={remove} loading={loading}>
            OK
          </Button>
          <Button color="error" onClick={toggle}>
            Cancelar
          </Button>
        </S.BodyModalConsent>
      </Modal>

      <Modal
        isShow={isShowAfterRemove}
        toggle={toggleAfterRemove}
        showCloseButton={false}
        closeOutsideClick={false}
      >
        <h3>Termo de {termType} removido com sucesso!</h3>

        <S.BodyModalConsent>
          <Button onClick={handleClickButtonAfterRemove} color="secondary">
            OK
          </Button>
        </S.BodyModalConsent>
      </Modal>
    </>
  );
};

export default ModalConfirmRemove;
