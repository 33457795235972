import { lighten } from 'polished';
import { NavLink as RouteLink } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';
import { ArrowDown } from 'styled-icons/evaicons-solid';

const modifiers = {
  WIDTH_MENU: '30rem',
  normalize: (theme: DefaultTheme, level: number) => css`
    background: ${lighten(0.3, theme.colors.gray)};
    color: ${theme.colors.darkBlack};
    position: relative;
    left: -${level * 2}rem;
    width: var(--width-default, ${modifiers.WIDTH_MENU});
    padding-left: ${level * 2 + 2}rem;
  `,
};

type WrapperProps = {
  widthDefault?: string;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, widthDefault }) => css`
    --width-default: ${widthDefault};

    font-family: ${theme.font.paragraphs.family};
    font-size: ${theme.font.paragraphs.sizes.medium};
    font-weight: ${theme.font.paragraphs.bold};
    color: ${theme.colors.black};
    box-shadow: 0.4rem -2rem 4.4rem -2.5rem rgba(0, 0, 0, 0.73);
    width: var(--width-default, ${modifiers.WIDTH_MENU});
    user-select: none;
    z-index: ${theme.layers.menu};
  `}
`;

export const Logo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-bottom: 0.2rem solid ${theme.colors.gray};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7.7rem;
  `}
`;

type LabelProps = {
  level: number;
};

export const Label = styled.label<LabelProps>`
  width: 100%;
  height: 5.6rem;
  cursor: pointer;
  display: inline-block;
  line-height: 5.4rem;
  padding-left: 2rem;

  &:hover {
    ${({ theme, level }) => modifiers.normalize(theme, level)}
  }
`;

export const Link = styled(RouteLink)<LabelProps>`
  ${({ theme, level }) => css`
    text-decoration: none;
    color: ${theme.colors.black};
    width: 100%;
    height: auto;
    cursor: pointer;
    display: inline-block;
    line-height: 5.4rem;
    padding-left: 2rem;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      ${modifiers.normalize(theme, level)}
    }
  `}
`;

export const Arrow = styled(ArrowDown)`
  width: 2.5rem;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const ItemGroup = styled.ul`
  padding-left: 2rem;
  list-style: none;
  display: none;
`;

export const Item = styled.li`
  position: relative;
`;

export const Switch = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  ${Container} ${Switch}:checked ~ ${ItemGroup} {
    display: block;
  }

  ${Container} ${Switch} + ${Arrow} {
    transform: rotate(-1deg);
    transition: 0.5s;
  }

  ${Container} ${Switch}:checked + ${Arrow} {
    transform: rotate(180deg);
    transition: 0.5s;
  }

  ${Label} > svg,${Link} > svg {
    width: 1.9rem;
    margin-right: 0.6rem;
  }
`;
