import { lighten } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export type OverlayProps = {
  show?: boolean;
};

export const Overlay = styled.div<OverlayProps>`
  ${({ theme, show }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.5;
    align-items: center;
    justify-content: center;
    display: ${show ? 'flex' : 'none'};
    background-color: ${theme.colors.darkWhite};
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
    background: ${theme.colors.lightWhite};
    color: ${theme.colors.black};
    font-family: ${theme.font.paragraphs.family};
    font-size: ${theme.font.paragraphs.sizes.medium};
    border-collapse: collapse;
    border-radius: ${theme.border.radius};
    overflow: hidden;
    width: 100%;

    tr:first-child th {
      border-bottom: none;
    }

    tr:last-child td {
      border-bottom: none;
    }

    td,
    th {
      border-bottom: 1px solid ${theme.colors.lightGray};
      padding: ${theme.spacings.xxsmall};
      text-align: left;
    }

    tr:hover {
      background-color: ${lighten(0.15, theme.colors.lightGray)};
    }
  `}
`;

type ThProps = {
  width?: string;
};

export const Th = styled.th<ThProps>`
  ${({ width, theme }) => css`
    padding: 1.2rem ${theme.spacings.xxsmall};
    background-color: ${theme.colors.primary};
    font-weight: ${theme.font.paragraphs.bold};
    color: ${theme.colors.white};
    ${!!width &&
    css`
      width: ${width};
    `}
  `}
`;
