import Layout from '../pages/Layout';
import Route, { RouteProps } from './Route';

const LayoutRoute = ({ Component, ...props }: RouteProps): JSX.Element => {
  return (
    <Layout>
      <Route Component={Component} {...props} />
    </Layout>
  );
};

export default LayoutRoute;
