import moment from 'moment';
import 'moment/locale/pt-br';

import { MAX_DATE, MIN_DATE } from '../../../utils/date_utils';
import { capitalizeFirstChar } from '../../../utils/string-utils';
import { Locale } from '../utils';

export type Month = {
  value: number;
  text: string;
  formatted: string;
};

export function getAllMonths(locale: Locale): Month[] {
  const allMonths: Month[] = [];

  for (let index = 0; index < 12; index += 1) {
    const currentMonth = moment().locale(locale).month(index);

    allMonths.push({
      value: currentMonth.month(),
      text: currentMonth.format('MM'),
      formatted: capitalizeFirstChar(currentMonth.format('MMM')),
    });
  }

  return allMonths;
}

export const Year = {
  next: (year: number): number => year + 1,
  previous: (year: number): number => year - 1,
  hasNext: (year: number): boolean => year < MAX_DATE.year(),
  hasPrevious: (year: number): boolean => year > MIN_DATE.year(),
};
