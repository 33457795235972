import { useEffect } from 'react';

import { useField } from '@unform/core';
import { Upload as UploadIcon } from 'styled-icons/heroicons-solid';

import * as S from './styles';

type InputUploadProps = {
  handleClickButton: () => void;
  labelFor: string;
  file: File | null;
  placeholder?: string;
  label?: string;
  formState?: FormData;
};

const InputUpload = ({
  handleClickButton,
  labelFor,
  file,
  placeholder,
  label,
  formState,
}: InputUploadProps): JSX.Element => {
  const { registerField } = useField(labelFor);

  useEffect(() => {
    registerField({
      name: labelFor,
      getValue: () => formState,
    });
  }, [registerField, labelFor, formState]);

  return (
    <S.InputWrapperWithLabel>
      {!!label && <S.Label htmlFor={labelFor}>{label}</S.Label>}
      <S.InputWrapper onClick={handleClickButton}>
        <span>
          {file?.name || placeholder || 'Click para escolher o arquivo'}
        </span>
        <UploadIcon size={20} />
      </S.InputWrapper>
    </S.InputWrapperWithLabel>
  );
};

export default InputUpload;
