import { useEffect, useMemo, useState } from 'react';

import { Moment } from 'moment';

import { Picker } from '../index';
import { Locale } from '../utils';
import { Year, getAllMonths, Month } from './utils';

import * as S from '../styles';

export type MonthPickerProps = {
  selectedDate: Moment;
  setSelectedDate: (value: Moment) => void;
  activePicker: Picker;
  setActivePicker: (value: Picker) => void;
  locale: Locale;
};

const MonthPicker = ({
  selectedDate,
  setSelectedDate,
  activePicker,
  setActivePicker,
  locale,
}: MonthPickerProps): JSX.Element => {
  const [currentYear, setCurrentYear] = useState<number>(selectedDate.year());

  const months: Month[] = useMemo(() => getAllMonths(locale), [locale]);

  useEffect(() => setCurrentYear(selectedDate.year()), [selectedDate]);

  const handleNextYear = (): void => {
    if (Year.hasNext(currentYear)) {
      setCurrentYear(Year.next(currentYear));
    }
  };

  const handlePreviousYear = (): void => {
    if (Year.hasPrevious(currentYear)) {
      setCurrentYear(Year.previous(currentYear));
    }
  };

  const handleMonthClick = (month: Month): void => {
    setActivePicker('DayPicker');
    setSelectedDate(selectedDate.clone().month(month.value).year(currentYear));
  };

  const isSelected = (month: Month): boolean => {
    return (
      selectedDate.year() === currentYear &&
      selectedDate.month() === month.value
    );
  };

  const handleHeaderClick = (): void => {
    setActivePicker('YearPicker');
  };

  return (
    <S.Panel show={activePicker === 'MonthPicker'}>
      <S.Header>
        <S.Navigate
          enabled={Year.hasPrevious(currentYear)}
          onClick={handlePreviousYear}
        >
          ‹
        </S.Navigate>
        <S.HeaderInfo onClick={handleHeaderClick}>{currentYear}</S.HeaderInfo>
        <S.Navigate
          enabled={Year.hasNext(currentYear)}
          onClick={handleNextYear}
        >
          ›
        </S.Navigate>
      </S.Header>
      <S.CellGroup>
        {months.map(month => (
          <S.Cell
            key={month.value}
            selected={isSelected(month)}
            onClick={() => handleMonthClick(month)}
          >
            {month.formatted}
          </S.Cell>
        ))}
      </S.CellGroup>
    </S.Panel>
  );
};

export default MonthPicker;
