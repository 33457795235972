import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Dropdown from '../DropDown';

import { Content } from '../DropDown/styles';

export const Wrapper = styled.div`
  position: relative;
  outline: none;
`;

export const DropdownStyledCustom = styled(Dropdown)`
  ${({ theme }) => css`
    ${Content} {
      background: ${theme.colors.lightPrimary};
      border-radius: ${theme.border.radius};
      color: ${theme.colors.white};
      box-shadow: 0 0.2rem 1rem ${theme.colors.black};
      user-select: none;
      &::before {
        border-bottom-color: ${theme.colors.lightPrimary};
      }
    }
  `}
`;

export const FullName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 1rem;
  width: 18rem;
`;

export const Logout = styled(Link)`
  ${({ theme }) => css`
    line-height: 4.6rem;
    text-decoration: none;
    display: block;
    height: 4.7rem;
    font-size: ${theme.font.paragraphs.sizes.large};
    color: ${theme.colors.white};
    padding-left: ${theme.spacings.small};
    cursor: pointer;

    border-top: 0.19rem solid ${theme.colors.black};
  `}
`;

export const UserDropDownAvatar = styled.div`
  height: 8rem;
  margin: 0.4rem 0.5rem 0.4rem 0.9rem;
  display: flex;
  align-items: center;
`;
