import styled, { css } from 'styled-components';

import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Form from '../../components/Form';

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  align-items: stretch;
`;

export const Background = styled.div`
  flex: 1;
  background: linear-gradient(
    150deg,
    rgba(96, 76, 205, 1) 0%,
    rgba(79, 200, 235, 1) 40%,
    rgba(34, 188, 159, 1) 100%
  );

  justify-content: center;
  align-items: center;
  display: flex;

  img {
    width: 50%;
  }
`;

export const LoginAlert = styled(Alert)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 70rem;

  a {
    width: 80%;
    text-align: right;
  }
`;

export const Logo = styled.div`
  img {
    width: 35rem;
  }
`;

export const LoginForm = styled(Form)`
  ${({ theme }) => css`
    width: 80%;
    > div {
      margin-bottom: ${theme.spacings.small};
    }

    > button {
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`;

export const ForgotPasswordLink = styled.a`
  ${({ theme }) => css`
    margin-top: 4rem;
    color: ${theme.colors.lightPrimary};
    font-weight: ${theme.font.paragraphs.bold};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const ButtonLogin = styled(Button)`
  width: 100%;
`;
