import { Dispatch, SetStateAction } from 'react';

import Button from '../../components/Button';
import { Holder } from '../../models/holder';
import { handleErrorApiHub } from '../../services/api';
import HolderReport from '../../services/Report/Holder';

import * as S from './styles';

type HolderReportActionActionProps = {
  item: Holder;
  setMessage: Dispatch<SetStateAction<string | undefined>>;
};

const HolderReportAction = ({
  item,
  setMessage,
}: HolderReportActionActionProps): JSX.Element => {
  const downloadHolderReport = async (): Promise<void> => {
    try {
      await HolderReport.download(item);
    } catch (err) {
      setMessage(handleErrorApiHub(err));
    }
  };

  return (
    <S.ActionHolderReport>
      <Button size="medium" outlined onClick={downloadHolderReport}>
        Download
      </Button>
    </S.ActionHolderReport>
  );
};

export default HolderReportAction;
