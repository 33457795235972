import * as Yup from 'yup';

import { Validations } from '../../utils/validations';

const messageDate = 'Data inválida';

export const schema = Yup.object().shape({
  dataAceite: Yup.string().test(
    'dataAceite',
    messageDate,
    value => Validations.date(messageDate, value) === '',
  ),
});
