import { Action, State } from './types';

const reducer = <T, Params>(
  state: State<T, Params>,
  action: Action<T, Params>,
): State<T, Params> => {
  switch (action.type) {
    case 'loading': {
      return {
        ...state,
        loading: action.loading,
        params: action.params,
      };
    }
    case 'success': {
      return {
        ...state,
        loading: undefined,
        error: undefined,
        page: action.page || { content: [] },
      };
    }
    default: {
      return {
        ...state,
        loading: undefined,
        error: action.error,
      };
    }
  }
};

export default reducer;
