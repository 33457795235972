import { useEffect, useRef } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';

import { useAuth, UserOrganization } from '../../hooks/auth';
import Button from '../Button';
import Select, { SelectTypingItems } from '../Select';

import * as S from './styles';

const schema = Yup.object().shape({
  organization: Yup.array()
    .required()
    .min(1, 'Selecione ao menos uma instituição'),
});

const convertOrganizationSelectItems = (
  organizations: UserOrganization[],
): SelectTypingItems[] => {
  return organizations?.map(o => {
    return {
      value: o,
      text: o.organizationName,
    };
  });
};

export type OrganizationProps = {
  openModal: boolean;
  setOpenModal: () => void;
};

const OrganizationsModal = ({
  openModal,
  setOpenModal,
  ...props
}: OrganizationProps): JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  const { user, chosenOrganization, setOrganization } = useAuth();

  const organizationNotEmpty = !!chosenOrganization.length;

  useEffect(() => {
    organizationNotEmpty;
  }, [chosenOrganization, openModal, organizationNotEmpty]);

  const confirm: SubmitHandler<{ organization: UserOrganization[] }> = async ({
    organization,
  }): Promise<void> => {
    setOrganization(organization);
    setOpenModal();
    window.location.reload();
  };

  const selectDefaultOrganization = (item: SelectTypingItems): boolean =>
    chosenOrganization
      .map(c => c.organizationId)
      .includes((item.value as UserOrganization).organizationId);

  return (
    <S.OrganizationsModal
      isShow={openModal}
      showCloseButton={organizationNotEmpty}
      closeOutsideClick={organizationNotEmpty}
      toggle={setOpenModal}
      {...props}
    >
      <S.Wrapper onSubmit={confirm} formRef={formRef} schema={schema}>
        <S.OrganizationModalTitle>
          Selecione a sua Organização
        </S.OrganizationModalTitle>
        <Select
          multiple
          checkUncheckAll
          labelFor="organization"
          initialValues={convertOrganizationSelectItems(user?.organizations)}
          customDefaultValueFilter={selectDefaultOrganization}
        />
        <S.Button>
          <Button type="submit" size="large">
            Confirmar
          </Button>
        </S.Button>
      </S.Wrapper>
    </S.OrganizationsModal>
  );
};

export default OrganizationsModal;
