import { ComponentType } from 'react';

import { useHistory } from 'react-router-dom';

import Pagination from '../../components/Pagination';
import { MinTableItem } from '../../components/Table';
import usePagination from '../../hooks/use-pagination';
import { Consent } from '../../models/consent';
import { apiHubLGPD } from '../../services/api';
import { colsTableConsent } from './config-table';
import ConsentFormSearchForm from './ConsentFormSearchForm';

import * as S from './styles';

export type ConsentFormRoute = {
  data?: Consent;
  operation: 'new' | 'newScienceTerm' | 'view' | 'edit';
};

export type MinTableItemConsent = Consent & MinTableItem;

export type SearchConsentForm = {
  q?: string;
  criacao?: string;
  versao?: string;
  page?: number;
  size?: number;
  somenteAtivos?: boolean;
};

const ConsentFormMain = (): JSX.Element => {
  const history = useHistory<ConsentFormRoute>();

  const { loading, page } = usePagination<Consent, SearchConsentForm>({
    endpoint: '/TermoConsentimento',
    autoLoading: {},
    apiPage: apiHubLGPD,
  });

  const newConsent = (): void => {
    history.push('/terms-form/form', { operation: 'new' });
  };

  const newScience = (): void => {
    history.push('/terms-form/form', { operation: 'newScienceTerm' });
  };

  return (
    <S.Wrapper>
      <S.H1>Listagem de Termos</S.H1>

      <ConsentFormSearchForm loading={loading} page={page} />
      <S.Row>
        <S.Button
          onClick={newConsent}
          disabled={!!loading}
          loading={loading === 'submit'}
          color="primary"
        >
          Novo Termo de Consentimento
        </S.Button>

        <S.Button
          onClick={newScience}
          disabled={!!loading}
          loading={loading === 'submit'}
          color="secondary"
        >
          Novo Termo de Ciência
        </S.Button>
      </S.Row>

      {page?.empty && <p>Não encontramos nenhum registro</p>}

      {page?.empty === false && (
        <>
          <S.TableConsent<ComponentType<MinTableItemConsent>>
            cols={colsTableConsent}
            items={page.content}
            loading={!!loading}
          />
          <Pagination
            currentPage={page.number}
            numPages={page.totalPages}
            isFirst={page.first}
            isLast={page.last}
            nextPage={page.next}
            prevPage={page.previous}
            pageItems={page.numberOfElements}
            totalItems={page.totalElements}
            loading={!!loading}
          />
        </>
      )}
    </S.Wrapper>
  );
};

export default ConsentFormMain;
