import styled, { css } from 'styled-components';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Form from '../../../components/Form';

export const ConsentAlert = styled(Alert)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const ConsentForm = styled(Form)`
  ${({ theme }) => css`
    > div {
      margin-bottom: ${theme.spacings.small};
    }
  `}
`;

export const ContentButtonActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.xsmall};
  `}
`;

export const H1 = styled.h1`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
  `}
`;

export const BodyModalConsent = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.small};
    gap: 1rem;
    display: flex;
    justify-content: flex-end;
  `}
`;

export const ButtonSaveConsent = styled(Button)`
  min-width: 11rem;
`;

export const HalfScreen = styled.div`
  width: 49%;
  margin: 1.2rem 0;
`;
