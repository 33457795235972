import { HTMLAttributes, memo, ReactNode, useMemo } from 'react';

import { Menu } from './Menu';

import * as S from './styles';

export type MenuItem = {
  label: string;
  icon?: ReactNode;
  linkTo?: string;
  items?: MenuItem[];
};

export type SideBarProps = {
  menuItems: MenuItem[];
  width?: string;
} & HTMLAttributes<HTMLDivElement>;

const Sidebar = ({ menuItems, width, ...props }: SideBarProps): JSX.Element => {
  const menuItemsMemo = useMemo(() => menuItems, [menuItems]);

  return (
    <S.Wrapper {...props} widthDefault={width}>
      <S.Logo>
        <img src="/img/logo.svg" alt="logo" />
      </S.Logo>
      <S.Nav>
        {menuItemsMemo &&
          menuItemsMemo.map(item => (
            <Menu level={0} menuItem={item} key={item.label} role="menu" />
          ))}
      </S.Nav>
    </S.Wrapper>
  );
};

export default memo(Sidebar);
