import { rgba } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const PickerGroup = styled.div`
  ${({ theme }) => css`
    width: max-content;
    position: absolute;
    z-index: ${theme.layers.overlay};
  `}
`;

export type PanelProps = {
  show: boolean;
};

export const Panel = styled.div<PanelProps>`
  ${({ theme, show }) => css`
    margin-top: 0.5rem;
    user-select: none;
    display: ${show ? 'inline-block' : 'none'};

    background-color: ${theme.colors.lightWhite};
    border: 1px solid ${theme.colors.black};
    border-radius: ${theme.border.radius};
    padding: 0.8rem 1.4rem;

    font-family: ${theme.font.paragraphs.family};
    font-weight: 300;
    font-size: 1.38rem;
    box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.15),
      -2px -1px 3px 0px rgba(0, 0, 0, 0.1);
  `}
`;

export type HeaderInfoProps = {
  clickable?: boolean;
};

const headerInfoModifiers = {
  withHover: (theme: DefaultTheme) => css`
    &:hover {
      background-color: ${rgba(theme.colors.primary, 0.25)};
      cursor: pointer;
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    color: ${theme.colors.darkestBlack};
  `,
};

export const HeaderInfo = styled.span<HeaderInfoProps>`
  ${({ theme, clickable = true }) => css`
    font-size: 1.5rem;
    width: 100%;
    border-radius: ${theme.border.radius};

    ${clickable
      ? headerInfoModifiers.withHover(theme)
      : headerInfoModifiers.disabled(theme)}
  `}
`;

const navigateModifiers = {
  default: (theme: DefaultTheme) => css`
    &:hover {
      background-color: ${rgba(theme.colors.primary, 0.25)};
      cursor: pointer;
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    color: ${theme.colors.darkGray};
  `,
};

export type NavigateProps = {
  enabled?: boolean;
};

export const Navigate = styled.span<NavigateProps>`
  ${({ theme, enabled }) => css`
    width: 6rem;
    margin: 0rem 0.6rem 0rem 0.4rem;
    font-size: 2.4rem;
    border-radius: ${theme.border.radius};

    ${enabled
      ? navigateModifiers.default(theme)
      : navigateModifiers.disabled(theme)};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.darkestBlack};
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;

    ${HeaderInfo},
    ${Navigate} {
      height: 3.5rem;
      text-align: center;
      line-height: 3.5rem;
    }
  `}
`;

const cellModifiers = {
  selected: (theme: DefaultTheme) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
  `,
  default: (theme: DefaultTheme) => css`
    color: ${theme.colors.darkestBlack};
  `,
  lowOpacity: (theme: DefaultTheme) => css`
    color: ${theme.colors.darkGray};
  `,
  withHover: (theme: DefaultTheme, selected: boolean) => css`
    &:hover {
      cursor: pointer;

      ${!selected &&
      css`
        color: ${theme.colors.darkestBlack};
        background-color: ${rgba(theme.colors.primary, 0.25)};
      `}
    }
  `,
};

export type CellProps = {
  clickable?: boolean;
  selected?: boolean;
  lowOpacity?: boolean;
};

export const Cell = styled.span<CellProps>`
  ${({ theme, clickable = true, selected = false, lowOpacity = false }) => css`
    width: 100%;
    text-align: center;

    ${selected && cellModifiers.selected(theme)}
    ${lowOpacity && cellModifiers.lowOpacity(theme)};
    ${!selected && !lowOpacity && cellModifiers.default(theme)};
    ${clickable && cellModifiers.withHover(theme, selected)}
  `}
`;

const PRIMARY_CELL_SIZE = 3.45;
const SECONDARY_CELL_SIZE = 6.2;

const cellGroupModifiers = {
  primary: () => css`
    grid-template-columns: ${`repeat(7, ${PRIMARY_CELL_SIZE}rem)`};

    ${Cell} {
      line-height: ${`${PRIMARY_CELL_SIZE}rem`};
      border-radius: 50%;
    }
  `,
  secondary: (theme: DefaultTheme) => css`
    grid-template-columns: ${`repeat(4, ${SECONDARY_CELL_SIZE}rem)`};

    ${Cell} {
      line-height: ${`${SECONDARY_CELL_SIZE * 0.85}rem`};
      border-radius: ${theme.border.radius};
    }
  `,
};

export type CellGroupProps = {
  layout?: 'primary' | 'secondary';
};

export const CellGroup = styled.div<CellGroupProps>`
  ${({ theme, layout = 'secondary' }) => css`
    display: grid;
    grid-gap: 0.2rem;

    ${layout === 'primary'
      ? cellGroupModifiers.primary()
      : cellGroupModifiers.secondary(theme)}
  `}
`;
