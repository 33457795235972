import { InputHTMLAttributes, useEffect, useState } from 'react';

import { useField } from '@unform/core';

import * as S from './styles';

export type CheckboxProps = {
  onCheck?: (status: boolean) => void;
  isChecked?: boolean;
  label?: string;
  labelFor: string;
  value?: string | ReadonlyArray<string> | number;
  error?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Checkbox = ({
  onCheck,
  isChecked = false,
  label,
  labelFor,
  value,
  error: customError,
  ...props
}: CheckboxProps): JSX.Element => {
  const [checked, setChecked] = useState(isChecked);

  const { registerField, error: unformError } = useField(labelFor);

  const error = customError || unformError;

  useEffect(() => {
    registerField({
      name: labelFor,
      getValue: () => checked && (value === undefined ? true : value),
    });
  }, [labelFor, registerField, value, checked]);

  const onChange = (): void => {
    const status = !checked;
    setChecked(status);
    !!onCheck && onCheck(status);
  };

  return (
    <S.Wrapper>
      <S.Input
        id={labelFor}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        value={value}
        {...props}
      />
      {!!label && <S.Label htmlFor={labelFor}>{label}</S.Label>}
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  );
};

export default Checkbox;
