import styled, { css, DefaultTheme } from 'styled-components';

import { TextFieldProps } from '.';

type WrapperProps = Pick<TextFieldProps, 'disabled'> & {
  error?: boolean;
  visibled?: boolean;
};

export type IconPositionProps = {
  $iconPosition: TextFieldProps['iconPosition'];
};

export type IconProps = {
  clickable: boolean;
} & IconPositionProps;

export const Icon = styled.div<IconProps>`
  ${({ theme, $iconPosition, clickable }) => css`
    display: flex;
    color: ${theme.colors.black};
    order: ${$iconPosition === 'right' ? 1 : 0};
    cursor: ${clickable ? 'pointer' : 'default'};
    user-select: none;

    & > svg {
      width: 2.2rem;
      height: 100%;
    }
  `}
`;

export const InputWrapper = styled.div<WrapperProps>`
  ${({ theme, visibled }) => css`
    display: ${visibled ? 'flex' : 'none'};

    background: ${visibled ? theme.colors.lightWhite : 'transparent'};
    border-radius: ${theme.border.radius};
    padding: 0 ${theme.spacings.xsmall};
    border: ${visibled ? '0.1rem solid' : 'none'};
    border-color: ${visibled ? theme.colors.black : 'transparent'};

    &:focus-within {
      --box-shadow-color: ${theme.colors.primary};
      box-shadow: 0 0 0.5rem var(--box-shadow-color);
    }
  `}
`;

export const Input = styled.input<IconPositionProps>`
  ${({ theme, $iconPosition }) => css`
    width: 100%;
    color: ${theme.colors.black};
    font-family: ${theme.font.paragraphs.family};
    font-size: ${theme.font.paragraphs.sizes.medium};
    padding: ${`calc(${theme.spacings.xsmall} - 0.15rem) ${theme.spacings.xxxsmall}`};
    padding-${$iconPosition}: ${theme.spacings.xxxsmall};
    background: transparent;
    border: 0;
    outline: none;

    &::placeholder {
      color: ${theme.colors.darkGray};
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    display: inline-block;
    font-size: ${theme.font.paragraphs.sizes.medium};
    font-weight: ${theme.font.paragraphs.bold};
    color: ${theme.colors.black};
    cursor: pointer;
    margin-bottom: ${theme.spacings.xxsmall};
  `}
`;

export const Error = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: ${theme.font.paragraphs.sizes.small};
    margin-top: ${theme.spacings.xxsmall};
  `}
`;

const wrapperModifiers = {
  error: (theme: DefaultTheme) => css`
    ${InputWrapper} {
      border-color: ${theme.colors.error};
      &:focus-within {
        --box-shadow-color: ${theme.colors.error};
      }
    }

    ${Label}, ${Icon} {
      color: ${theme.colors.error};
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Label},
    ${Input},
    ${Icon} {
      cursor: not-allowed;
      color: ${theme.colors.gray};

      &::placeholder {
        color: currentColor;
      }
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, disabled, error }) => css`
    width: 100%;

    ${error && wrapperModifiers.error(theme)};
    ${disabled && wrapperModifiers.disabled(theme)};
  `}
`;
