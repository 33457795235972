import styled, { css } from 'styled-components';

export const Label = styled.label`
  ${({ theme }) => css`
    display: inline-block;
    font-size: ${theme.font.paragraphs.sizes.medium};
    font-weight: ${theme.font.paragraphs.bold};
    color: ${theme.colors.black};
    cursor: pointer;
    margin-bottom: ${theme.spacings.xxsmall};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    --ck-color-toolbar-border: ${theme.colors.black};
    --ck-font-face: ${theme.font.paragraphs.family};
    --ck-font-size-base: ${theme.font.paragraphs.sizes.medium};
    --ck-color-base-text: ${theme.colors.black};
    --ck-border-radius: ${theme.border.radius};

    .ck {
      &.ck-content {
        padding: 0 3rem;
        border: 1px solid var(--ck-color-toolbar-border);
        background: ${theme.colors.lightWhite};
      }

      &.ck-icon {
        color: ${theme.colors.black};
      }
    }
  `}
`;

export const Error = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: ${theme.font.paragraphs.sizes.small};
    margin-top: ${theme.spacings.xxsmall};
  `}
`;
