import { useEffect, useState } from 'react';

import { useField } from '@unform/core';
import { ChevronDown } from 'styled-icons/entypo';

import useUpdateEffect from '../../hooks/use-effect-only-update';
import useOutsideClick from '../../hooks/use-outside-click';
import useSelectedItems from '../../hooks/use-selected-items';
import { regularString } from '../../utils/string-utils';
import Checkbox from '../Checkbox';
import SelectCheckbox from './SelectCheckbox';

import * as S from './styles';

export type SelectTypingItems = {
  value: number | string | Record<string, unknown>;
  text: string;
  isSelected?: boolean;
};

export type SelectProps = {
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  initialValues: SelectTypingItems[];
  defaultValue?: (number | string | Record<string, unknown>)[];
  customDefaultValueFilter?: (item: SelectTypingItems) => boolean;
  className?: string;
  labelFor: string;
  checkUncheckAll?: boolean;

  onChange?: (item: SelectTypingItems[] | SelectTypingItems) => void;
};

const Select = ({
  label,
  labelFor,
  initialValues,
  onChange,
  placeholder = 'Selecione',
  multiple = false,
  checkUncheckAll = false,
  className,
  defaultValue,
  customDefaultValueFilter = () => false,
}: SelectProps): JSX.Element => {
  const [display, setDisplay] = useState(false);

  const chooseDefaultItem = (item: SelectTypingItems): boolean => {
    return defaultValue
      ? defaultValue.includes(item.value)
      : customDefaultValueFilter(item);
  };

  const [single, setSingle] = useState<SelectTypingItems | undefined>(
    initialValues.find(chooseDefaultItem),
  );

  const { registerField, error: unformError, clearError } = useField(labelFor);
  const {
    items,
    selectedItems,
    toggleSingleItem,
    toggleAllItem,
  } = useSelectedItems<SelectTypingItems>({
    itemIdentifierKey: 'value',
    initialItems: initialValues,
    initialSelectedItems: initialValues.filter(chooseDefaultItem),
  });

  const [isCheckedOrUnchecked, setIsCheckedOrUnchecked] = useState(false);

  useEffect(() => {
    registerField({
      name: labelFor,
      getValue: () =>
        !multiple ? single?.value || '' : selectedItems.map(item => item.value),
      setValue: (_, value) => {
        if (Array.isArray(value)) {
          const filter = initialValues.filter(item =>
            value.includes(item.value),
          );
          filter.forEach(item => toggleSingleItem(item));

          filter.length && !!onChange && onChange(filter);
        } else if (value) {
          const find = initialValues.find(item => item.value === value);
          find && setSingle(find);
          find && !!onChange && onChange(find);
        }
      },
      clearValue: () =>
        !multiple ? setSingle(undefined) : toggleAllItem(false),
    });
  }, [
    initialValues,
    labelFor,
    multiple,
    onChange,
    registerField,
    selectedItems,
    single?.value,
    toggleAllItem,
    toggleSingleItem,
  ]);

  useUpdateEffect(() => {
    if (multiple && display && selectedItems.length) {
      !!onChange && onChange(selectedItems);
    }
  }, [selectedItems]);

  const handlerClickInput = (): void => setDisplay(!display);

  const { ref } = useOutsideClick(() => {
    setDisplay(false);
  });

  const handlerClickItem = (item: SelectTypingItems): void => {
    if (!multiple) {
      clearError();
      setSingle(item);
      setDisplay(false);
      !!onChange && onChange(item);
    } else {
      ref.current?.focus();
      setDisplay(true);
      toggleSingleItem(item);
    }
  };

  const handlerClickCheckUncheckAll = (): void => {
    setIsCheckedOrUnchecked(!isCheckedOrUnchecked);
    toggleAllItem(!isCheckedOrUnchecked);
  };

  return (
    <S.Wrapper ref={ref} className={className} error={!!unformError}>
      {!!label && <S.LabelTitle>{label}</S.LabelTitle>}
      <S.ContentWrapper>
        <S.InputWrapper
          onClick={handlerClickInput}
          role="combobox"
          aria-labelledby={label}
        >
          <span>
            {(multiple
              ? selectedItems.map(item => item.text).join(', ')
              : single?.text) || placeholder}
          </span>
          <S.SelectChevronDown>
            <ChevronDown />
          </S.SelectChevronDown>
        </S.InputWrapper>

        <S.ItemsWrapper flex={display} role="dialog">
          {items.length && multiple && checkUncheckAll && (
            <S.CheckBoxWrapper>
              <Checkbox
                labelFor={`checkBoxcheckedUnchecked_${Math.floor(
                  Math.random() * 100,
                )}`}
                label="Selecionar todas"
                onChange={handlerClickCheckUncheckAll}
                checked={
                  isCheckedOrUnchecked ||
                  (items.length === 1 && items[0].isSelected) ||
                  items.length === selectedItems.length
                }
              />
            </S.CheckBoxWrapper>
          )}
          {multiple &&
            items.map(item => (
              <SelectCheckbox
                key={item.text}
                item={item}
                htmlFor={labelFor}
                handlerClickItem={handlerClickItem}
              />
            ))}

          {!multiple &&
            initialValues.map(item => (
              <S.OptionSingle
                onClick={() => handlerClickItem(item)}
                key={regularString(item.text)}
                role="option"
              >
                {item.text}
              </S.OptionSingle>
            ))}
        </S.ItemsWrapper>

        {!!unformError && <S.Error>{unformError}</S.Error>}
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default Select;
