import { useCallback, useEffect, useReducer } from 'react';

import { AxiosError, AxiosInstance } from 'axios';
// import AxiosURLSearchParams from 'axios/lib/helpers/AxiosURLSearchParams';

import { Page } from '../../models/page';
import { Error as ErrorResponseAxios } from '../../models/response';
import api from '../../services/api';
import paramsSerializer from '../../utils/params-serializer';
import { getPixeonId } from '../auth';
import reducer from './reducer';
import { Reducer, UsePagination, Error } from './types';

const messages = {
  nextPage: 'Não foi possível navegar para próxima página',
  previousPage: 'Não foi possível voltar para página anterior',
  submit: 'Falha ao realizar consulta',
};

type Loading = 'submit' | 'nextPage' | 'previousPage';

type OptionsPagination<Params> = {
  endpoint: string;
  autoLoading?: Params;
  apiPage?: AxiosInstance;
  serializationParams?: boolean;
};

function usePagination<T, Params extends Record<string, unknown>>({
  endpoint,
  autoLoading,
  apiPage = api,
  serializationParams = false,
}: OptionsPagination<Params>): UsePagination<T, Params> {
  const [state, dispatch] = useReducer<Reducer<T, Params>>(reducer, {
    params: {} as Params,
    loading: undefined,
    page: { content: [] as T[] } as Page<T>,
  });

  const errorHandling = (ex: unknown, loading: Loading): Error => {
    const error: Error = { message: messages[loading] };

    if ((ex as AxiosError & ErrorResponseAxios).response) {
      const castEx = ex as AxiosError<Record<string, unknown>> &
        ErrorResponseAxios;
      error.status = castEx.response?.status;
      error.payload = castEx.response?.data;
    } else if ((ex as AxiosError & ErrorResponseAxios).request) {
      error.message = 'Servidor inacessível';
    }

    return error;
  };

  const changePage = useCallback(
    async (loading: Loading, params: Params): Promise<void> => {
      try {
        dispatch({ type: 'loading', loading, params });

        const response = await apiPage.get<Page<T>>(endpoint, {
          params,
          ...(serializationParams ? { paramsSerializer } : {}),
        });

        dispatch({ type: 'success', page: response.data });
      } catch (ex) {
        const error: Error = errorHandling(ex, loading);

        dispatch({ type: 'error', error });
      }
    },
    [apiPage, endpoint, serializationParams],
  );

  const fetch = async (parameters: Params): Promise<void> => {
    const newParams = {
      ...parameters,
      pixeonId: getPixeonId(),
      page: parameters.page || 0,
      size: parameters.size || 10,
    };

    await changePage('submit', newParams);
  };

  useEffect(() => {
    autoLoading && fetch(autoLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextPage = async (): Promise<void> => {
    await changePage('nextPage', {
      ...state.params,
      page: state.page.number + 1,
    });
  };

  const previousPage = async (): Promise<void> => {
    await changePage('previousPage', {
      ...state.params,
      page: state.page.number - 1,
    });
  };

  return {
    params: state.params as Params,
    loading: state.loading,
    page: {
      ...state.page,
      fetch,
      next: nextPage,
      previous: previousPage,
    },
    error: state.error,
  };
}

export default usePagination;
