import { Switch } from 'react-router-dom';

import ConsentFormMain from '../pages/ConsentForm';
import ConsentFormEdit from '../pages/ConsentForm/Form';
import EmitReport from '../pages/EmitReport';
import EmittedReports from '../pages/EmittedReports';
import FullReport from '../pages/FullReport';
import HolderReport from '../pages/HolderReport';
import InstitutionEdit from '../pages/Institution';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import PatientConsentIndex from '../pages/PatientConsent';
import LayoutRoute from './LayoutRoute';
import Route from './Route';

const propsDefault = { exact: true, isPrivate: true };

const componentsWithLayout = [
  {
    key: 1,
    Component: EmitReport,
    path: ['/emit-report'],
    ...propsDefault,
  },
  {
    key: 2,
    Component: EmittedReports,
    path: '/emitted-reports',
    ...propsDefault,
  },
  {
    key: 3,
    Component: ConsentFormEdit,
    path: '/terms-form/form',
    ...propsDefault,
  },
  {
    key: 4,
    Component: ConsentFormMain,
    path: '/terms-form',
    ...propsDefault,
  },
  {
    key: 5,
    Component: FullReport,
    path: '/full-report',
    ...propsDefault,
  },
  {
    key: 6,
    Component: PatientConsentIndex,
    path: '/patient-consent-form',
    ...propsDefault,
  },
  {
    key: 7,
    Component: InstitutionEdit,
    path: '/institution-edit',
    ...propsDefault,
  },
  {
    key: 8,
    Component: HolderReport,
    path: '/holder-report',
    ...propsDefault,
  },
];

const Routes = (): JSX.Element => {
  return (
    <Switch>
      {componentsWithLayout.map(route => (
        <LayoutRoute {...route} />
      ))}

      <Route Component={Login} path="/" useLayout={false} exact />

      <LayoutRoute Component={NotFound} isPrivate />
    </Switch>
  );
};

export default Routes;
