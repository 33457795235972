import { Dispatch, SetStateAction, useState } from 'react';

import Modal from '../../../components/Modal';
import { Patient } from '../../../models/patient';
import { Response } from '../../../models/response';
import { FormData, requestReport } from '../utils';
import Confirmation from './Confirmation';
import ErrorDialog from './Error';
import SuccessDialog from './Success';

type DialogProps = {
  data: FormData;
  patients: Patient[];
  showDialog: boolean;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
};

type DialogState = {
  loading: boolean;
  response?: Response;
};

const Dialog = ({
  data,
  patients,
  showDialog,
  setShowDialog,
}: DialogProps): JSX.Element => {
  const [state, setState] = useState<DialogState>({ loading: false });

  const handleToggle = (): void => setShowDialog(prev => !prev);

  const onConfirm = async (): Promise<void> => {
    setState({ loading: true, response: undefined });

    const response = await requestReport(data, patients);
    setState({ loading: false, response });
  };

  const reset = (): void => {
    setState({ loading: false });
    setShowDialog(false);
  };

  return (
    <Modal
      isShow={showDialog}
      toggle={handleToggle}
      showCloseButton={false}
      closeOutsideClick={false}
    >
      <Confirmation state={state} toggle={handleToggle} onConfirm={onConfirm} />
      <SuccessDialog response={state.response} />
      <ErrorDialog response={state.response} reset={reset} />
    </Modal>
  );
};

export default Dialog;
