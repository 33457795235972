import { useEffect, useMemo, useState } from 'react';

import { Moment } from 'moment';

import { Picker } from '../index';
import { YearRange, Years } from './utils';

import * as S from '../styles';

export type YearPickerProps = {
  selectedDate: Moment;
  setSelectedDate: (value: Moment) => void;
  activePicker: Picker;
  setActivePicker: (value: Picker) => void;
};

const YearPicker = ({
  selectedDate,
  setSelectedDate,
  activePicker,
  setActivePicker,
}: YearPickerProps): JSX.Element => {
  const [currentYears, setCurrentYears] = useState<YearRange[]>(
    Years.range(selectedDate),
  );

  const headerInfo = useMemo(() => {
    const firstYearFromRange = currentYears[0].value;
    const lastYearFromRange = currentYears[currentYears.length - 1].value;

    return `${firstYearFromRange} - ${lastYearFromRange}`;
  }, [currentYears]);

  useEffect(() => {
    setCurrentYears(Years.range(selectedDate));
  }, [selectedDate]);

  const handleNextYear = (): void => {
    if (Years.hasNext(currentYears)) {
      setCurrentYears(Years.next(currentYears));
    }
  };

  const handlePreviousYear = (): void => {
    if (Years.hasPrevious(currentYears)) {
      setCurrentYears(Years.previous(currentYears));
    }
  };

  const isSelected = (year: number): boolean => {
    return year === selectedDate.year();
  };

  const handleYearClick = (year: YearRange): void => {
    if (year.isValid) {
      setActivePicker('MonthPicker');
      setSelectedDate(selectedDate.clone().year(year.value));
    }
  };

  return (
    <S.Panel show={activePicker === 'YearPicker'}>
      <S.Header>
        <S.Navigate
          enabled={Years.hasPrevious(currentYears)}
          onClick={handlePreviousYear}
        >
          ‹
        </S.Navigate>
        <S.HeaderInfo clickable={false}>{headerInfo}</S.HeaderInfo>
        <S.Navigate
          enabled={Years.hasNext(currentYears)}
          onClick={handleNextYear}
        >
          ›
        </S.Navigate>
      </S.Header>
      <S.CellGroup>
        {currentYears.map(year => (
          <S.Cell
            key={year.value}
            selected={isSelected(year.value)}
            onClick={() => handleYearClick(year)}
            clickable={year.isValid}
          >
            {year.isValid ? year.value : '-'}
          </S.Cell>
        ))}
      </S.CellGroup>
    </S.Panel>
  );
};

export default YearPicker;
