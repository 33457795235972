import { darken, rgba } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

import { Severity } from '.';

const colors = {
  info: (theme: DefaultTheme): string => rgba(theme.colors.info, 0.5),
  success: (theme: DefaultTheme): string => rgba(theme.colors.success, 0.65),
  warning: (theme: DefaultTheme): string => rgba(theme.colors.warning, 0.8),
  error: (theme: DefaultTheme): string => rgba(theme.colors.error, 0.45),
};

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacings.xxsmall};
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    background-color: transparent;
    border: none;

    font-weight: bold;
    font-size: ${theme.font.paragraphs.sizes.large};
  `}
`;

export type WrapperProps = {
  color: Severity;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, color }) => css`
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    border-radius: ${theme.border.radius};
    border: 1px solid ${darken(0.05, colors[color](theme))};
    background-color: ${colors[color](theme)};
    font-size: ${theme.font.paragraphs.sizes.medium};

    &,
    svg {
      color: ${darken(0.65, theme.colors[color])};
    }

    ${CloseButton} {
      color: ${darken(0.5, colors[color](theme))};

      &:hover {
        color: ${darken(0.65, colors[color](theme))};
      }
    }
  `}
`;
