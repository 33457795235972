import { Dispatch, SetStateAction, useState } from 'react';

import download from 'downloadjs';

import { SearchPatientConsent } from '.';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { Pageable } from '../../hooks/use-pagination/types';
import useToggle from '../../hooks/use-toggle';
import { PatientConsent } from '../../models/patient-consent';
import { apiHubLGPD, handleErrorApiHub } from '../../services/api';

import * as S from './styles';

type PatientConsentActionProps = {
  item: PatientConsent;
  setMessage: Dispatch<SetStateAction<string | undefined>>;
  page: Pageable<PatientConsent, SearchPatientConsent>;
};

const PatientConsentAction = ({
  item: {
    possuiConsentimentoAnexado: hasConsentAttached,
    possuiRevogacao: hasRevocation,
    nome: name,
    id,
    termoConsentimento,
  },
  setMessage,
  page,
}: PatientConsentActionProps): JSX.Element => {
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingRevoke, setLoadingRevoke] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const { isShow, toggle } = useToggle();

  const revoke = async (): Promise<void> => {
    setLoadingRevoke(true);
    try {
      await apiHubLGPD.post(`/ConsentimentoPaciente/revogacao/${id}`);
      await page.fetch({});
    } catch (err) {
      setMessage(handleErrorApiHub(err));
    } finally {
      setLoadingRevoke(false);
      toggle();
    }
  };

  const downloadConsent = async (): Promise<void> => {
    setLoadingDownload(true);
    try {
      const { data, headers } = await apiHubLGPD.get(
        `/ConsentimentoPaciente/${id}/download`,
        {
          responseType: 'blob',
        },
      );

      const contentType = headers['content-type'];
      const fileName = 'consentimento.pdf';

      !!data && download(data, fileName, contentType);
    } catch (err) {
      setMessage(handleErrorApiHub(err));
    } finally {
      setLoadingDownload(false);
    }
  };

  const handleClickUploadFile = async (formData: FormData): Promise<void> => {
    setLoadingUpload(true);
    try {
      await apiHubLGPD.post(
        `/ConsentimentoPaciente/upload-consentimento-assinado/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      await page.fetch({});
    } catch (err) {
      setMessage(handleErrorApiHub(err));
    } finally {
      setLoadingUpload(false);
    }
  };

  return (
    <S.ActionPatientConsent>
      <S.ButtonDownload
        loading={loadingDownload}
        onClick={downloadConsent}
        color="secondary"
      >
        Download
      </S.ButtonDownload>

      <S.UploadPatientConsent
        name="file"
        title="Upload"
        disabled={!!hasConsentAttached}
        accept="image/*, application/pdf"
        onChange={handleClickUploadFile}
        loading={loadingUpload}
      />
      {termoConsentimento.tipoTermo !== 'science' && (
        <S.ButtonPatientConsent
          disabled={!!hasRevocation}
          onClick={toggle}
          color="error"
        >
          Revogar
        </S.ButtonPatientConsent>
      )}
      <Modal
        isShow={isShow}
        toggle={toggle}
        showCloseButton={false}
        closeOutsideClick={false}
      >
        <h3>Deseja revogar o consentimento do paciente {name}?</h3>

        <S.BodyModalConsent>
          <Button onClick={revoke} loading={loadingRevoke}>
            OK
          </Button>
          <Button color="error" onClick={toggle}>
            Cancelar
          </Button>
        </S.BodyModalConsent>
      </Modal>
    </S.ActionPatientConsent>
  );
};

export default PatientConsentAction;
