import styled, { css } from 'styled-components';

import DefaultButton from '../../components/Button';

import { Form } from '../../components/Form/styles';
import { Table } from '../../components/Table/styles';

export const Link = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-weight: ${theme.font.paragraphs.bold};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const Error = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.error};
  `}
`;

export const ButtonEmitReport = styled(DefaultButton)`
  min-width: 15.85rem;
`;

export const Button = styled(DefaultButton)`
  ${({ theme }) => css`
    padding: ${`${theme.spacings.xxsmall} ${theme.spacings.small}`};

    & svg {
      width: 1.65rem;
    }
  `}
`;

export const SearchGroup = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export type FilterGroupProps = {
  show?: boolean;
};

export const FilterGroup = styled.div<FilterGroupProps>`
  ${({ show }) => css`
    display: ${show ? 'flex' : 'none'};
    gap: 1.5rem;
    margin: 1.5rem 0rem;
  `}
`;

export const EmitGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NoDataMessage = styled.p`
  ${({ theme }) => css`
    line-height: ${theme.spacings.small};
  `}
`;

export const SelectedPatients = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
    margin-top: ${theme.spacings.xsmall};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${SearchGroup}, ${EmitGroup} {
      margin-top: ${theme.spacings.medium};
    }

    ${Table} {
      margin-top: ${theme.spacings.small};
    }

    ${Form} {
      margin-bottom: ${theme.spacings.large};
    }
  `}
`;
