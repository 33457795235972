import styled from 'styled-components';

import Form from '../Form';
import Modal from '../Modal';

export const Wrapper = styled(Form)`
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const OrganizationsModal = styled(Modal)`
  width: 60rem;
`;

export const OrganizationModalTitle = styled.h2`
  text-align: center;
`;

export const Button = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;
