import styled, { css } from 'styled-components';

export const NavHeader = styled.header`
  position: relative;
  width: 100%;
  min-height: 7.5rem;
  padding-right: 4%;
  display: flex;
  justify-content: flex-end;
`;

export const Nav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  > * {
    margin: 1rem 1rem 1rem 2.5rem;
  }
`;

export const Profile = styled.nav`
  display: flex;
  align-items: center;
  > * {
    margin: 1rem 1rem 1rem 2.5rem;
  }
`;

type OrganizationProps = {
  disableClick: boolean;
};

export const Organization = styled.div<OrganizationProps>`
  ${({ theme, disableClick }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};
    margin-left: ${theme.spacings.xxxlarge};
    cursor: ${disableClick ? 'pointer' : 'initial'};
    user-select: none;
    z-index: ${theme.layers.base};

    &:hover {
      h2,
      svg {
        color: ${theme.colors.darkPrimary};
      }
    }

    h2 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 110rem;
    }
  `}

  svg {
    ${({ theme }) => css`
      height: 3rem;
      width: 3rem;
      margin-right: ${theme.spacings.xxsmall};
    `}
  }
`;
