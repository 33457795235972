import { useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Email, Lock } from 'styled-icons/material-outlined';
import * as Yup from 'yup';

import TextField from '../../components/TextField';
import { SignInCredentials, useAuth } from '../../hooks/auth';
import { ErrorResponse, handleErrorResponse } from '../../services/api';

import * as S from './styles';

const Login = (): JSX.Element => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Informe um e-mail válido')
      .required('O e-mail é obrigatório'),
    password: Yup.string().required('A senha é obrigatória'),
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleLogin = async (credentials: SignInCredentials): Promise<void> => {
    try {
      setLoading(true);
      await signIn(credentials);
    } catch (err) {
      setErrorMessage(handleErrorResponse(err as ErrorResponse));
      setLoading(false);
    }
  };

  const onCloseAlert = (): void => {
    setErrorMessage('');
  };

  return (
    <S.Wrapper>
      <S.Background>
        <img
          src="/img/login_background.png"
          alt="Segurança de dados e Inovação pela vida"
        />
      </S.Background>
      <S.Content>
        <S.Logo>
          <img src="/img/logo.svg" alt="Logomarca Pixeon" />
        </S.Logo>
        <S.LoginForm onSubmit={handleLogin} schema={schema} formRef={formRef}>
          {errorMessage && (
            <S.LoginAlert closeable severity="error" onClose={onCloseAlert}>
              {errorMessage}
            </S.LoginAlert>
          )}
          <TextField
            placeholder="pixeon@pixeon.com.br"
            label="E-mail"
            labelFor="email"
            icon={<Email />}
          />
          <TextField
            type="password"
            placeholder="senha"
            label="Senha"
            labelFor="password"
            icon={<Lock />}
          />
          <S.ButtonLogin type="submit" size="large" loading={loading}>
            Entrar
          </S.ButtonLogin>
        </S.LoginForm>
        <S.ForgotPasswordLink
          target="_blank"
          href="https://portalcliente.pixeon.com/servicedesk/customer/user/forgotpassword"
        >
          Esqueci a senha
        </S.ForgotPasswordLink>
      </S.Content>
    </S.Wrapper>
  );
};

export default Login;
