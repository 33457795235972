import styled, { css } from 'styled-components';

import Button from '../../components/Button';

export const Bot = styled.img`
  width: 70rem;
  margin-top: -15rem;
  margin-left: -13rem;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

export const TitleCode = styled.h1`
  margin-top: -13rem;
  margin-bottom: 1rem;
`;

export const DetailsCode = styled.p`
  margin-bottom: 1rem;
`;

export const BackButton = styled(Button)`
  ${({ theme }) => css`
    padding: 1.5rem 3rem;
    border: 0.2rem solid ${theme.colors.primary};
    border-radius: 1rem;
    font-size: ${theme.font.paragraphs.sizes.medium};
    margin-top: 1rem;
  `}
`;
