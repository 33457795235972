import {
  ChangeEvent,
  InputHTMLAttributes,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { useField } from '@unform/core';
import InputMask from 'react-input-mask';

import * as S from './styles';

export type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onClick' | 'id'
>;

export type TextFieldProps = {
  onTyping?: (value: string) => void;
  label?: string;
  labelFor: string;
  initialValue?: string;
  disabled?: boolean;
  error?: string;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  mask?: string;
  visibled?: boolean;
  inputClick?: () => void;
  iconClick?: () => void;
  onClick?: (event: MouseEvent) => void;
} & InputProps;

const TextField = ({
  label,
  labelFor,
  initialValue = '',
  error: customError,
  disabled = false,
  onTyping,
  icon,
  iconPosition = 'left',
  visibled = true,
  inputClick,
  iconClick,
  onClick,
  mask,
  ...props
}: TextFieldProps): JSX.Element => {
  const [value, setValue] = useState(initialValue);
  const { registerField, error: unformError } = useField(labelFor);

  useEffect(() => {
    registerField({
      name: labelFor,
      getValue: () => value,
      clearValue: () => {
        setValue('');
      },
    });
  }, [labelFor, registerField, value]);

  useEffect(() => setValue(initialValue), [initialValue]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.currentTarget.value;
    setValue(newValue);

    !!onTyping && onTyping(newValue);
  };

  const error = customError || unformError;

  let convertToMask = {};
  if (mask) {
    convertToMask = {
      as: InputMask,
      mask,
    };
  }

  return (
    <S.Wrapper disabled={disabled} error={!!error}>
      {!!label && <S.Label htmlFor={labelFor}>{label}</S.Label>}
      <S.InputWrapper
        visibled={visibled}
        onClick={event => !disabled && !!onClick && onClick(event)}
      >
        {!!icon && (
          <S.Icon
            role="button"
            clickable={!!iconClick}
            $iconPosition={iconPosition}
            onClick={() => !disabled && !!iconClick && iconClick()}
          >
            {icon}
          </S.Icon>
        )}
        <S.Input
          id={labelFor}
          type="text"
          onChange={onChange}
          value={value}
          disabled={disabled}
          $iconPosition={iconPosition}
          onClick={() => !disabled && !!inputClick && inputClick()}
          {...convertToMask}
          {...props}
        />
      </S.InputWrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  );
};

export default TextField;
