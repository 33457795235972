import { useEffect, useMemo, useRef, useState } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import moment from 'moment';
import { FilterAlt } from 'styled-icons/boxicons-regular';

import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import Form from '../../components/Form';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import TextField from '../../components/TextField';
import { getNameByPixeonId } from '../../hooks/auth';
import usePagination from '../../hooks/use-pagination';
import useToggle from '../../hooks/use-toggle';
import { Patient } from '../../models/patient';
import SimpleReport from '../../services/Report/Simple';
import ChooseReport from './ChooseReport';
import { schema } from './schema';

import { Alert } from './ChooseReport/styles';
import * as S from './styles';

const formatDate = (date?: string): string =>
  date ? moment(date, 'yyyy-MM-DD').format('DD/MM/yyyy') : '';

export type SearchPatient = {
  q: string;
  pixeonId: string[];
  application?: string;
  motherName?: string;
  birthDate?: string;
};

const EmitReport = (): JSX.Element => {
  const [selectedPatients, setSelectedPatients] = useState<Patient[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [downloadError, setDownloadError] = useState('');
  const { isShow: showModal, toggle: toggleModal } = useToggle();
  const formRef = useRef<FormHandles>(null);

  const { loading, params, page, error } = usePagination<
    Patient,
    SearchPatient
  >({ endpoint: '/patients', serializationParams: true });

  useEffect(() => {
    if (error?.message) {
      formRef.current?.setFieldError('q', error.message);
    } else {
      page.content.length === 0 && selectedPatients.length > 0
        ? formRef.current?.setFieldError('q', 'Nenhum paciente encontrado')
        : formRef.current?.setFieldError('q', '');
    }

    setDownloadError('');
  }, [page, selectedPatients, error]);

  const handleSubmit: SubmitHandler<SearchPatient> = async filters => {
    const birthDate = filters.birthDate
      ? moment(filters.birthDate, 'DD/MM/yyyy').format('yyyy-MM-DD')
      : undefined;

    page.fetch({ ...filters, birthDate });
  };

  const selectPatient = (patient: Patient): void => {
    setSelectedPatients(previousState => {
      const isSelected = previousState.some(
        selectedPatient => selectedPatient.id === patient.id,
      );

      return isSelected ? previousState : [...previousState, patient];
    });
  };

  const unselectPatient = (patient: Patient): void => {
    setSelectedPatients(previousState => {
      // clear errors when all patients have been removed
      previousState.length === 1 && formRef?.current?.setErrors({});

      return previousState.filter(
        selectedPatient => selectedPatient.id !== patient.id,
      );
    });
  };

  const handleNoDataLink = (): void => {
    SimpleReport.download([], params)
      .then(() => setDownloadError(''))
      .catch(() => setDownloadError('Falha ao baixar relatório simplificado'));
  };

  const cols = useMemo(
    () => [
      { title: 'ID', fieldName: 'originalId' },
      { title: 'Nome', fieldName: 'name' },
      { title: 'Nome Social', fieldName: 'preferredName' },
      {
        title: 'Data de Nascimento',
        fieldName: 'birthDate',
        customRender: (row: Patient) => formatDate(row.birthDate),
      },
      { title: 'Documento', fieldName: 'document' },
      { title: 'Sistema', fieldName: 'application' },
      { title: 'Nome da Mãe', fieldName: 'motherName' },
      {
        title: 'Nome da Instituição',
        fieldName: 'pixeonId',
        customRender: ({ pixeonId }: Patient) => getNameByPixeonId(pixeonId),
      },
    ],
    [],
  );

  const patientsCols = [
    ...cols,
    {
      title: '',
      fieldName: 'add',
      width: '10rem',
      customRender: (row: Patient) => (
        <Button onClick={() => selectPatient(row)}>Adicionar</Button>
      ),
    },
  ];

  const selectedPatientsCols = [
    ...cols,
    {
      title: '',
      fieldName: 'remove',
      width: '10rem',
      customRender: (row: Patient) => (
        <Button color="error" onClick={() => unselectPatient(row)}>
          Remover
        </Button>
      ),
    },
  ];

  return (
    <S.Wrapper>
      <h1>Emitir Relatório de Rastreabilidade</h1>
      <Form
        formRef={formRef}
        onSubmit={handleSubmit}
        schema={schema}
        cleanErrors={false}
      >
        <S.SearchGroup>
          <TextField
            placeholder="Busque por id, documento ou nome"
            labelFor="q"
            maxLength={80}
          />
          <S.Button
            size="large"
            icon={<FilterAlt size={50} />}
            outlined
            onClick={() => setShowFilters(prev => !prev)}
            aria-label="filtros"
          />
          <S.ButtonEmitReport
            size="large"
            type="submit"
            aria-label="buscar"
            disabled={!!loading}
            loading={loading === 'submit'}
          >
            Buscar
          </S.ButtonEmitReport>
        </S.SearchGroup>
        <S.FilterGroup show={showFilters}>
          <DatePicker label="Data de Nascimento" labelFor="birthDate" />
          <TextField
            placeholder="Nome da Mãe"
            label="Nome da Mãe"
            labelFor="motherName"
            maxLength={80}
          />
          <TextField
            placeholder="Sistema"
            label="Sistema"
            labelFor="application"
            maxLength={80}
          />
        </S.FilterGroup>
      </Form>

      {page?.empty && selectedPatients.length === 0 && (
        <S.NoDataMessage>
          Não encontramos nenhum registro para o termo &quot;
          <strong>{params?.q}</strong>
          &quot; em nossa base de dados. Realize a busca por id, documento ou
          nome.
          <br />
          <br />O termo buscado está correto? Então{' '}
          <S.Link onClick={handleNoDataLink} role="link">
            clique aqui
          </S.Link>
          &nbsp;para emitir um relatório.
        </S.NoDataMessage>
      )}

      {!!downloadError && <S.Error>{downloadError}</S.Error>}

      {page?.empty === false && (
        <>
          <p>
            Selecione no resultado abaixo todas as correspondências com seu
            paciente:
          </p>
          <Table cols={patientsCols} items={page.content} loading={!!loading} />
          <Pagination
            currentPage={page.number + 1}
            numPages={page.totalPages}
            isFirst={page.first}
            isLast={page.last}
            nextPage={page.next}
            prevPage={page.previous}
            pageItems={page.numberOfElements}
            totalItems={page.totalElements}
            loading={!!loading}
          />
        </>
      )}

      {selectedPatients.length > 0 && (
        <S.SelectedPatients>
          <Alert severity="warning">
            Antes de solicitar a geração do relatório, certifique-se de que os
            pacientes selecionados se referem à mesma pessoa.
          </Alert>
          <h3>Pacientes Selecionados</h3>
          <Table cols={selectedPatientsCols} items={selectedPatients} />
          <S.EmitGroup>
            <Button type="button" size="large" onClick={toggleModal}>
              Emitir Relatório
            </Button>
            <ChooseReport
              show={showModal}
              toggle={toggleModal}
              selectedPatients={selectedPatients}
              params={params}
            />
          </S.EmitGroup>
        </S.SelectedPatients>
      )}
    </S.Wrapper>
  );
};

export default EmitReport;
