import styled, { css } from 'styled-components';

import DefaultButton from '../../components/Button';
import Table from '../../components/Table';

export const Button = styled(DefaultButton)`
  min-width: 21.9rem;
`;

export const Row = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacings.small};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.small};
  `}
`;

export const H1 = styled.h1`
  width: 100%;
`;

export const TableConsent = styled(Table)`
  ${({ theme }) => css`
    th {
      padding: ${theme.spacings.xxsmall} 1rem;
    }
  `}
`;

export const Action = styled.div`
  display: flex;
  gap: 1rem;
`;

export const SearchGroup = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const ButtonConsentForm = styled(DefaultButton)`
  min-width: 10rem;
`;

export const CustomBadge = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 1rem;
    padding: 1rem;
    background: ${theme.colors.badge};
    color: gray;
    border-radius: 0.3rem;
    font-weight: bold;
    font-size: 1.2rem;
    justify-content: left;
    align-items: center;
  `}
`;
