import { ReactNode } from 'react';

import { Book, BookOpen } from 'styled-icons/boxicons-regular';
import {
  DocumentRibbon,
  PersonNote,
  Signature,
} from 'styled-icons/fluentui-system-regular';
import { Organization } from 'styled-icons/octicons';

import OrganizationsModal from '../../components/OrganizationsModal';
import { MenuItem } from '../../components/Sidebar';
import { useAuth } from '../../hooks/auth';
import useToggle from '../../hooks/use-toggle';

import * as S from './styles';

const data: MenuItem[] = [
  {
    label: 'Emitir Relatório',
    linkTo: '/emit-report',
    icon: <Book />,
  },
  {
    label: 'Relatórios Emitidos',
    linkTo: '/emitted-reports',
    icon: <BookOpen />,
  },
  {
    label: 'Relatório Titular',
    linkTo: '/holder-report',
    icon: <PersonNote />,
  },
  {
    label: 'Termos ( Consent. | Ciência )',
    linkTo: '/terms-form',
    icon: <Signature />,
  },
  {
    label: 'Pacientes ( Consent. | Ciência )',
    linkTo: '/patient-consent-form',
    icon: <DocumentRibbon />,
  },
  {
    label: 'Instituição',
    linkTo: '/institution-edit',
    icon: <Organization />,
  },
];

type LayoutProps = {
  children?: ReactNode;
};

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const { user, chosenOrganization } = useAuth();

  const { isShow, toggle } = useToggle(true);

  return (
    <>
      {user?.organizations.length > 1 &&
        Object.keys(chosenOrganization).length === 0 && (
          <OrganizationsModal openModal={isShow} setOpenModal={toggle} />
        )}
      <S.Grid>
        <S.LayoutSidebar menuItems={data} />
        <S.LayoutHeader
          profileMenu={{ imgUrlAvatar: user?.avatar, avatarName: user?.name }}
        />
        <S.Container>{children}</S.Container>
      </S.Grid>
    </>
  );
};

export default Layout;
