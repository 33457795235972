import { ReactNode } from 'react';

import { CheckCircle, InfoCircle } from 'styled-icons/boxicons-regular';
import { Warning } from 'styled-icons/ionicons-outline';
import { Dangerous } from 'styled-icons/material-outlined';

import * as S from './styles';

export type Severity = 'info' | 'success' | 'warning' | 'error';

export type AlertProps = {
  children: ReactNode;
  closeable?: boolean;
  severity?: Severity;
  show?: boolean;
  onClose?: () => void;
};

const size = 20;

const icons = {
  info: <InfoCircle size={size} />,
  success: <CheckCircle size={size} />,
  warning: <Warning size={size} />,
  error: <Dangerous size={size} />,
};

const Alert = ({
  children,
  closeable = false,
  severity = 'info',
  onClose,
  show = true,
  ...props
}: AlertProps): JSX.Element => {
  const handleClose = (): void => {
    !!onClose && onClose();
  };

  return (
    <>
      {!!children && show && (
        <S.Wrapper role="alert" color={severity} {...props}>
          <S.Content>
            <S.IconWrapper>{icons[severity]}</S.IconWrapper>
            <div>{children}</div>
          </S.Content>
          {closeable && (
            <S.CloseButton aria-label="Close" onClick={handleClose}>
              &times;
            </S.CloseButton>
          )}
        </S.Wrapper>
      )}
    </>
  );
};

export default Alert;
