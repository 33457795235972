import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import jwt from 'jsonwebtoken';

import api from '../../services/api';

export type User = {
  email: string;
  name: string;
  avatar?: string;
  organizations: UserOrganization[];
};

export type UserOrganization = {
  organizationId: string;
  organizationName: string;
  pixeonId: string;
};

export type AuthState = {
  token: string;
  user: User;
};

export type SignInCredentials = {
  username: string;
  password: string;
};

export type AuthContextData = {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  chosenOrganization: UserOrganization[];
  setOrganization(organization: UserOrganization[]): void;
};

export type JWTData = {
  sub: string;
  user: User;
  exp: number;
};

export type AuthProviderParams = {
  children: ReactNode;
};

export function getPixeonId(): string[] {
  const organizationFromLocalStorage = localStorage.getItem(
    '@PixeonLGPD:organization',
  );

  if (organizationFromLocalStorage) {
    const institution: UserOrganization[] = JSON.parse(
      organizationFromLocalStorage,
    );

    return institution.map(c => c.pixeonId);
  }

  return [];
}

export function getNameByPixeonId(id: string): string {
  const organizationFromLocalStorage = localStorage.getItem(
    '@PixeonLGPD:organization',
  );

  if (organizationFromLocalStorage) {
    const institution: UserOrganization[] = JSON.parse(
      organizationFromLocalStorage,
    );

    return institution.find(i => i.pixeonId === id)?.organizationName || '';
  }

  return '';
}

export const cleanStorage = (): void => {
  localStorage.removeItem('@PixeonLGPD:token');
  localStorage.removeItem('@PixeonLGPD:user');
  localStorage.removeItem('@PixeonLGPD:organization');
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: AuthProviderParams): JSX.Element => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@PixeonLGPD:token');
    const user = localStorage.getItem('@PixeonLGPD:user');

    if (token && user) {
      const tokenExpirationTime = jwt.decode(token, {
        complete: true,
      })?.payload.exp;

      const date = new Date().getTime() / 1000;

      if (tokenExpirationTime && tokenExpirationTime < date) {
        cleanStorage();

        return {} as AuthState;
      }

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const [institution, setInstitution] = useState<UserOrganization[]>(
    (): UserOrganization[] => {
      const organizationFromLocalStorage = localStorage.getItem(
        '@PixeonLGPD:organization',
      );

      if (organizationFromLocalStorage) {
        return JSON.parse(organizationFromLocalStorage);
      }
      return [];
    },
  );

  const setOrganization = useCallback((organization: UserOrganization[]) => {
    localStorage.setItem(
      '@PixeonLGPD:organization',
      JSON.stringify(organization),
    );
    setInstitution(organization);
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post(
      '/login',
      {
        username: email,
        password,
      },
      { timeout: Number(process.env.REACT_APP_LOGIN_TIMEOUT) || 4000 },
    );

    const { token } = response.data;
    const jwtData: JWTData = JSON.parse(JSON.stringify(jwt.decode(token)));
    const { user } = jwtData;

    localStorage.setItem('@PixeonLGPD:token', token);
    localStorage.setItem('@PixeonLGPD:user', JSON.stringify(user));

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    cleanStorage();

    setData({} as AuthState);
    setOrganization([]);
  }, [setOrganization]);

  useEffect(() => {
    data.user?.organizations.length === 1 &&
      setOrganization(data.user.organizations);
  }, [data, setOrganization]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        chosenOrganization: institution,
        setOrganization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextData => useContext(AuthContext);

export { AuthProvider, useAuth };
