import { useMemo, useState, useEffect } from 'react';

import { Moment } from 'moment';

import { Picker } from '../index';
import { formatDate, Locale } from '../utils';
import { Calendar, CalendarDay } from './utils';

import * as S from '../styles';

export type DayPickerProps = {
  selectedDate: Moment;
  setSelectedDate: (value: Moment) => void;
  activePicker: Picker;
  setActivePicker: (value: Picker) => void;
  locale: Locale;
};

const DayPicker = ({
  selectedDate,
  setSelectedDate,
  activePicker,
  setActivePicker,
  locale,
}: DayPickerProps): JSX.Element => {
  const [currentDate, setCurrentDate] = useState<Moment>(selectedDate);

  const calendarDays: CalendarDay[] = Calendar.days(currentDate);
  const weekDays: string[] = useMemo(() => Calendar.weekDays(locale), [locale]);

  useEffect(() => {
    setCurrentDate(selectedDate);
  }, [selectedDate]);

  const handleNextMonth = (): void => {
    if (Calendar.hasNext(currentDate)) {
      setCurrentDate(Calendar.next(currentDate));
    }
  };

  const handlePreviousMonth = (): void => {
    if (Calendar.hasPrevious(currentDate)) {
      setCurrentDate(Calendar.previous(currentDate));
    }
  };

  const handleDayClick = (calendarDay: CalendarDay): void => {
    if (calendarDay.isValid) {
      setActivePicker('None');
      setSelectedDate(calendarDay.value);
    }
  };

  const handleHeaderClick = (): void => {
    setActivePicker('MonthPicker');
  };

  return (
    <S.Panel show={activePicker === 'DayPicker'}>
      <S.Header>
        <S.Navigate
          enabled={Calendar.hasPrevious(currentDate)}
          onClick={handlePreviousMonth}
        >
          ‹
        </S.Navigate>
        <S.HeaderInfo onClick={handleHeaderClick}>
          {formatDate(currentDate, 'MMMM yyyy', locale)}
        </S.HeaderInfo>
        <S.Navigate
          enabled={Calendar.hasNext(currentDate)}
          onClick={handleNextMonth}
        >
          ›
        </S.Navigate>
      </S.Header>
      <S.CellGroup layout="primary">
        {weekDays.map(weekDay => (
          <S.Cell key={weekDay} clickable={false}>
            {weekDay}
          </S.Cell>
        ))}
        {calendarDays.map(calendarDay => (
          <S.Cell
            key={calendarDay.key}
            lowOpacity={calendarDay.isOutOfMonth}
            selected={selectedDate.isSame(calendarDay.value, 'day')}
            onClick={() => handleDayClick(calendarDay)}
            clickable={calendarDay.isValid}
          >
            {calendarDay.isValid ? calendarDay.dayOfMonth : '-'}
          </S.Cell>
        ))}
      </S.CellGroup>
    </S.Panel>
  );
};

export default DayPicker;
