import { Dispatch, SetStateAction, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import useToggle from '../../../hooks/use-toggle';

import * as S from './styles';

type ModalConfirmOutProps = {
  typeModal: 'save' | 'back' | undefined;
  operation: 'view' | 'new' | 'edit' | 'newScienceTerm';
  setTypeModal: Dispatch<SetStateAction<'save' | 'back' | undefined>>;
};

const ModalConfirmOut = ({
  typeModal,
  operation,
  setTypeModal,
}: ModalConfirmOutProps): JSX.Element => {
  const { isShow, toggle } = useToggle();
  const history = useHistory();

  useEffect(() => {
    if (typeModal !== undefined) {
      toggle();
    }
  }, [typeModal, toggle]);

  const handleClickCancel = (): void => {
    toggle();
    setTypeModal(undefined);
  };

  return (
    <>
      <Modal
        isShow={isShow}
        toggle={toggle}
        showCloseButton={false}
        closeOutsideClick={false}
      >
        <h3>
          {typeModal === 'back'
            ? 'Deseja voltar para a tela de listagem?'
            : `Registro ${
                ['new', 'newScienceTerm'].includes(operation)
                  ? 'cadastrado'
                  : 'atualizado'
              } com sucesso.`}
        </h3>

        <S.BodyModalConsent>
          {typeModal === 'back' ? (
            <>
              <Button onClick={() => history.push('/terms-form')}>OK</Button>
              <Button color="error" onClick={handleClickCancel}>
                Cancelar
              </Button>
            </>
          ) : (
            <Button
              onClick={() => history.push('/terms-form')}
              color="secondary"
            >
              OK
            </Button>
          )}
        </S.BodyModalConsent>
      </Modal>
    </>
  );
};

export default ModalConfirmOut;
