import styled, { css } from 'styled-components';

export const RootWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.black};
    position: relative;
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

type ContentProps = {
  backgroundColor?: string;
};

export const Content = styled.div<ContentProps>`
  ${({ theme, backgroundColor }) => css`
    background: ${backgroundColor || theme.colors.white};
    color: ${theme.colors.black};
    margin-top: ${theme.spacings.xsmall};
    position: absolute;
    right: 0;
    z-index: ${theme.layers.alwaysOnTop};

    &::before {
      content: '';
      position: absolute;
      border-right: 1.2rem solid transparent;
      border-left: 1.2rem solid transparent;
      border-bottom: 1.2rem solid ${backgroundColor || theme.colors.white};
      top: -1.2rem;
      right: 1.4rem;
    }
  `}
`;

type OverlayProps = {
  showShadowBackground: boolean;
};

export const Overlay = styled.div<OverlayProps>`
  ${({ theme, showShadowBackground }) => css`
    ${showShadowBackground &&
    css`
      background: rgba(0, 0, 0, 0.3);
    `}

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.layers.overlay};
  `}
`;

type WrapperProps = {
  isOpen?: boolean;
};

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2rem);
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isOpen }) => css`
    position: relative;
    width: max-content;

    ${Content},
    ${Overlay} {
      transition: transform 0.2s ease-in, opacity ${theme.transition.default};

      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close()}
    }
  `}
`;
