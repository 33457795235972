import { darken } from 'polished';
import styled, { css } from 'styled-components';

import Alert from '../../components/Alert';
import Table from '../../components/Table';

export const H1 = styled.h1`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.large};
  `}
`;

export const FieldSearch = styled.div`
  width: 37.8%;
`;

export const ButtonSearch = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.spacings.medium};

    > button {
      width: 17rem;
    }
  `}
`;

export const FormEmittedReports = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem 0;

  > *:not(${FieldSearch}) {
    width: 14.8%;
    min-width: 19rem;
  }
`;

export const H3 = styled.h3`
  font-weight: 500;
  margin: 3rem 0;
`;

export const PaginationEmittedReports = styled.div`
  width: 100%;
`;

export const TableEmittedReports = styled(Table)`
  td,
  th {
    padding: 1.4rem 1rem;
  }
`;

type ColorStatusProps = {
  color?: string;
  cursorType: string;
};

const olorStatusModifier = {
  color: (color?: string) =>
    color &&
    css`
      color: ${color};

      &:hover {
        color: ${darken(0.1, color)};
      }
    `,
};

export const ColorStatus = styled.div<ColorStatusProps>`
  ${({ color, cursorType }) => css`
    ${olorStatusModifier.color(color)}
    cursor: ${cursorType};
    user-select: none;
  `}
`;

export const StatusDot = styled.div`
  width: 100%;
  display: flex;
  margin-left: 1.5rem;
`;

export const AlertEmittedReport = styled(Alert)`
  margin-top: 1rem;
`;

export const TitleModalError = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xsmall};
    color: ${theme.colors.error};
  `}
`;

export const ParagraphModalError = styled.p`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} 0;
    line-height: ${theme.spacings.small};
    max-width: 46rem;
  `}
`;

export const FooterModalError = styled.h2`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacings.xsmall};
  `}
`;
