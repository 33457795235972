import { useRef } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Consent } from 'models/consent';

import { SearchConsentForm } from '.';
import Checkbox from '../../components/Checkbox';
import Form from '../../components/Form';
import TextField from '../../components/TextField';
import { Loading, Pageable } from '../../hooks/use-pagination/types';

import * as S from './styles';

type ConsentFormSearchFormProps = {
  page: Pageable<Consent, SearchConsentForm>;
  loading: Loading;
};

const TermsSearchForm = ({
  page,
  loading,
}: ConsentFormSearchFormProps): JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit: SubmitHandler<SearchConsentForm> = async filters => {
    page.fetch({ ...filters });
  };

  return (
    <Form formRef={formRef} onSubmit={handleSubmit} cleanErrors={false}>
      <S.SearchGroup>
        <TextField placeholder="Nome" labelFor="nome" maxLength={80} />
        <Checkbox
          width={30}
          label="Versões Recentes"
          labelFor="somenteAtivos"
        />
        <S.ButtonConsentForm
          size="large"
          type="submit"
          aria-label="buscar"
          disabled={!!loading}
          loading={loading === 'submit'}
        >
          Buscar
        </S.ButtonConsentForm>
      </S.SearchGroup>
    </Form>
  );
};

export default TermsSearchForm;
