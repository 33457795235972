export const HOLDER_REPORT_TYPE = 'TITULAR';

export type HolderWho = {
  ip: string;
  usuarioId: string;
  usuarioNome: string;
  data: string;
};

export type Holder = {
  id: string;
  documentoTitular: string;
  url: string;
  pixeonId: string;
  criacao: HolderWho;
  dataCriacao: string;
  usuarioCriacao: string;
  tipo: string;
};
