import {
  createGlobalStyle,
  css,
  DefaultTheme,
  GlobalStyleComponent,
} from 'styled-components';

type GlobalStylesProps = {
  removeBg?: boolean;
};

const GlobalStyles: GlobalStyleComponent<
  GlobalStylesProps,
  DefaultTheme
> = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ${({ theme, removeBg }) => css`
    h1 {
      font-size: ${theme.font.headings.sizes.medium};
      font-weight: 100;
    }

    h1,
    h2,
    h3,
    h4 {
      color: ${theme.colors.black};
      font-family: ${theme.font.headings.family};
    }

    p {
      color: ${theme.colors.black};
    }

    a {
      text-decoration: none;
      color: ${theme.colors.primary};
      font-weight: ${theme.font.paragraphs.bold};
    }

    html {
      font-size: 62.5%;
    }

    body {
      font-family: ${theme.font.paragraphs.family};
      font-size: ${theme.font.paragraphs.sizes.medium};

      ${!removeBg &&
      css`
        background-color: ${theme.colors.white};
      `}
    }
  `}
`;

export default GlobalStyles;
