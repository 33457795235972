import { useEffect, useState } from 'react';

import { Institution } from 'styled-icons/boxicons-solid';

import { UserOrganization } from '../../hooks/auth';

import * as S from './styles';

type ListOrganizationsTitleProps = {
  handleToggle: () => void;
  isOnlyOneOrganization: boolean;
  chosenOrganization: UserOrganization[];
};

export default function ListOrganizationsTitle({
  handleToggle,
  isOnlyOneOrganization,
  chosenOrganization: [first, ...chosenOrganization],
}: ListOrganizationsTitleProps): JSX.Element {
  const [institution, setInstitution] = useState(0);

  useEffect(() => {
    if (chosenOrganization.length > 0) {
      setInstitution(chosenOrganization.length);
    }
  }, [chosenOrganization]);

  return (
    <S.Organization
      onClick={handleToggle}
      disableClick={!isOnlyOneOrganization}
    >
      {(!!chosenOrganization.length || first) && <Institution />}
      {first && (
        <h2>
          {first.organizationName} {!!institution && `(+${institution})`}
        </h2>
      )}
    </S.Organization>
  );
}
